import { Component, OnDestroy } from '@angular/core';
import { TaskCardComponent } from '../../../../components/task-card/task-card.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../../services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { ContactsService } from '../../../../services/contacts.service';

@Component({
  selector: 'app-tasks-open',
  standalone: true,
  imports: [
    CommonModule,
    TaskCardComponent
  ],
  templateUrl: './tasks-open.component.html',
  styleUrl: './tasks-open.component.css'
})
export class TasksOpenComponent implements OnDestroy {
  private _subscription: Subscription;

  tasksData: Array<any> = [];

  public get contactId(): string | null {
    return this.contactsService.selectedSugarContactId;;
  }

  public get loading(): boolean {
    return this.loaderService.loading;
  }

  constructor (
    private readonly contactsService: ContactsService,
    private readonly loaderService: LoaderService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this._subscription = this.activatedRoute.data.subscribe(({data}) => {
        this.tasksData = data;
      });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
