import { Routes } from '@angular/router';
import { ReservationsListComponent } from './pages/single-context/reservations/reservations-list/reservations-list.component';
import { CasesListComponent } from './pages/single-context/cases/cases-list/cases-list.component';
import { TasksOpenComponent } from './pages/single-context/tasks/tasks-open/tasks-open.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { reservationsListResolver } from './resolvers/reservations-list.resolver';
import { casesResolver } from './resolvers/cases.resolver';
import { openTasksResolver } from './resolvers/open-tasks.resolver';
import { NoContextComponent } from './pages/no-context/no-context.component';
import { AuthFailureComponent } from './pages/error-pages/auth-failure/auth-failure.component';
import { DebugComponent } from './pages/single-context/debug/debug.component';
import { CasesCurrentComponent } from './pages/single-context/cases/cases-current/cases-current.component';
import { currentCaseResolver } from './resolvers/current-case.resolver';
import { ContactComponent } from './pages/single-context/contact/contact.component';
import { SettingsComponent } from './pages/single-context/settings/settings.component';
import { emailTemplateResolver } from './resolvers/email-template.resolver';
import { casesDataResolver } from './resolvers/cases-data.resolver';
import { SalesComponent } from './pages/single-context/sales/sales.component';
import { SalesDetailsComponent } from './pages/single-context/sales/sales-details/sales-details.component';
import { AccountEditComponent } from './pages/single-context/sales/components/account-edit/account-edit.component';
import { OpportunityEditComponent } from './pages/single-context/sales/components/opportunity-edit/opportunity-edit.component';
import { SalesAddToAccountComponent } from './pages/single-context/sales/sales-add-to-account/sales-add-to-account.component';
import { SalesAddToOpportunityComponent } from './pages/single-context/sales/sales-add-to-opportunity/sales-add-to-opportunity.component';
import { AccountCreateComponent } from './pages/single-context/sales/account-create/account-create.component';
import { OpportunityCreateComponent } from './pages/single-context/sales/opportunity-create/opportunity-create.component';
import { salesResolver } from './resolvers/sales.resolver';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/contact'
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'reservations',
        children: [
          {
            path: 'list',
            component: ReservationsListComponent,
            resolve: {data: reservationsListResolver}
          }
        ]
      },
      {
        path: 'cases',
        children: [
          {
            path: 'current',
            component: CasesCurrentComponent,
            resolve: {
              casesData: casesDataResolver,
              currentCase: currentCaseResolver,
            }
          },
          {
            path: 'list',
            component: CasesListComponent,
            resolve: {
              casesList: casesResolver
            }
          }
        ]
      },
      {
        path: 'tasks',
        children: [
          {
            path: 'open',
            component: TasksOpenComponent,
            resolve: { data: openTasksResolver }
          }
        ]
      },
      {
        path: 'sales',
        component: SalesComponent,
        children: [
          {
            path: '',
            redirectTo: 'details',
            pathMatch: 'prefix',
          },
          {
            path: 'details',
            component: SalesDetailsComponent,
            resolve: { data: salesResolver },
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'account-create',
            component: AccountCreateComponent,
            resolve: { data: salesResolver },
          },
          {
            path: 'opportunity-create',
            component: OpportunityCreateComponent,
            resolve: { data: salesResolver },
          },
          {
            path: 'account-edit',
            component: AccountEditComponent,
            resolve: { data: salesResolver },
          },
          {
            path: 'opportunity-edit',
            component: OpportunityEditComponent,
            resolve: { data: salesResolver },
          },
          {
            path: 'add-to-account/:account_id',
            component: SalesAddToAccountComponent,
          },
          {
            path: 'add-to-opportunity/:account_id',
            component: SalesAddToOpportunityComponent,
          }
        ],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        resolve: {
          emailTemplate: emailTemplateResolver
        }
      },
      {
        path: 'debug',
        component: DebugComponent
      }
    ]
  },
  {
    path: 'no-context',
    component: NoContextComponent
  },
  {
    path: 'auth-failure',
    component: AuthFailureComponent
  }
];
