<div class="absolute top-1 right-1 z-50 ">
  <ng-container *ngFor="let toast of toasts">
    <app-toast [id]="toast.id" [message]="toast.message" [type]="toast.type"></app-toast>
  </ng-container>
</div>

<p-toast />

<div class="h-full">
  <header class="flex flex-col antialiased">
    <shared-duplicate-warning />
    <shared-navbar></shared-navbar>
  </header>

  <div class="h-full">
    <ng-container *ngIf="loading">
      <app-loader></app-loader>
    </ng-container>
    <router-outlet />
  </div>
</div>

