import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Front from '@frontapp/plugin-sdk';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-debug',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './debug.component.html',
  styleUrl: './debug.component.css'
})
export class DebugComponent {
  _tags: Array<any> = [];
  _inboxes: Array<any> = [];

  public get loading(): boolean {
    return this.loaderService.loading;
  }

  public get tags(): Array<any> {
    return this._tags.map((tag: any) => tag)
  }

  public get inboxes(): Array<any> {
    return this._inboxes.map((inbox: any) => inbox);
  }

  constructor (
    private readonly loaderService: LoaderService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    let token = null;

    (async () => {
      let token;
      while (token !== null) {
        await Front.listTags((token ? token : undefined))
        .then((response) => {
          const { nextPageToken = null, results: tags = [] } = response;
          token = nextPageToken;
          this._tags = this._tags.concat(tags);
        });
      }

      this._tags.sort();
    })();

    (async () => {
      await Front.listInboxes()
        .then((response: any) => {
          const { nextPageToken = null, results: inboxes = [] } = response;
          console.log('inboxes: ', inboxes)
          this._inboxes = inboxes;
        })
    })();
  }
}
