<div *ngIf="duplicateConversations.length > 1" class="bg-[#fff3f3] border-b-[1px] p-5 border-[#f08786]">
  <div class="bg-[#ffdfdf] rounded-lg p-3 inline-flex text-sm mb-4 items-center w-full">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ee201f" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm37.66,130.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
    <div class="ms-2">Potential duplicate message</div>
    <div class="font-semibold ms-auto">See details of similar submissions below</div>
  </div>
  <div class="font-bold text-normal">Email / Phone number:</div>

  <div *ngFor="let conversation of duplicateConversations" class="flex text-normal">
    <div class="inline-flex w-full">
      <div class="me-auto">{{ (conversation?.phone_number ? conversation.phone_number : conversation?.email) }}</div>
      <div>{{ (timestampMidnight > conversation.timestamp ? 'yesterday' : 'today') }} &#64; {{ getTime(conversation.timestamp) }}</div>
      <div class="ms-2">
        <a
        type="button"
        [ngClass]="{
          'text-[#387fed] cursor-pointer': (conversation?.conversation_id !== currentConversationId),
          'text-gray-500 text-normal cursor-default': (conversation?.conversation_id === currentConversationId)
        }"
        class="font-bold  select-none"
        (click)="openConversation(conversation.conversation_id)">
          {{ (conversation?.conversation_id === currentConversationId ? '(selected)' : 'View message') }}
        </a>
      </div>
    </div>
  </div>
</div>
