<form class="w-full" [formGroup]="contactForm" (ngSubmit)="onSubmitHandler()">
  <div class="p-3">
    <div class="text-center text-normal text-red-500 pb-5">
      <div *ngIf="lastName?.dirty && !lastName?.valid">
        *Last name field is required
      </div>
      <div *ngIf="primaryEmail?.dirty && !primaryEmail?.valid">
        *Primary email field is required and must contain email address
      </div>
      <div *ngIf="secondaryEmail?.dirty && !secondaryEmail?.valid">
        *Secondary email field must contain email address
      </div>
      <div *ngIf="workPhoneNumber?.errors || mobilePhoneNumber?.errors || mobileSecondaryPhoneNumber?.errors">
        *Please enter a valid phone number prefixed with country code<br> for example: +44 7000 000 000
      </div>
    </div>
    <table class="w-full text-left">
      <tr>
        <th class="py-1">
          <label for="firstName" class="mt-auto mb-auto text-normal font-medium text-[#ababab] dark:text-white">
            First name:
          </label>
        </th>
        <td class="py-1">
          <input
            (input)="onInputChange()"
            [ngClass]="{'border-red-500': firstName.touched && !firstName.valid}"
            type="text" id="firstName" [formControl]="firstName"
            class="bg-[#f5f5f5] border ml-auto w-full border-gray-200 text-gray-900 text-normal rounded-lg block px-2 py-1">
        </td>
      </tr>

      <tr>
        <th class="py-1">
          <label for="lastName" class="mt-auto mb-auto text-normal font-medium text-[#ababab] dark:text-white">
            Last name:
          </label>
        </th>
        <td class="py-1">
          <input
            (input)="onInputChange()"
            [ngClass]="{'border-red-500': lastName.touched && !lastName.valid}"
            type="text" id="lastName" formControlName="lastName"
            class="bg-[#f5f5f5] border ml-auto w-full border-gray-200 text-gray-900 text-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2 py-1">
        </td>
      </tr>

      <tr>
        <th class="py-1">
          <label for="primaryEmail" class="mt-auto mb-auto text-normal font-medium text-[#ababab] dark:text-white">
            Primary email:
          </label>
        </th>
        <td class="py-1">
          <input
            (input)="onInputChange()"
            [ngClass]="{'border-red-500': primaryEmail.touched && !primaryEmail.valid}"
            type="email" id="primaryEmail" formControlName="primaryEmail"
            class="bg-[#f5f5f5] border ml-auto w-full border-gray-200 text-gray-900 text-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2 py-1">
        </td>
      </tr>

      <tr>
        <th class="py-1">
          <label for="secondaryEmail" class="mt-auto mb-auto text-normal font-medium text-[#ababab] dark:text-white">
            Secondary email:
          </label>
        </th>
        <td class="py-1">
          <input
            (input)="onInputChange()"
            [ngClass]="{'border-red-500': secondaryEmail.touched && !secondaryEmail.valid}"
            type="email" id="secondaryEmail" formControlName="secondaryEmail"
            class="bg-[#f5f5f5] border ml-auto w-full border-gray-200 text-gray-900 text-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2 py-1">
        </td>
      </tr>

      <tr>
        <th class="py-1">
          <label for="workPhoneNumber" class="mt-auto mb-auto text-normal font-medium text-[#ababab] dark:text-white">
            Best contact number:
          </label>
        </th>
        <td class="py-1">
          <div class="relative">
            <div *ngIf="(workPhoneNumber.value?.length || 0) > 0" class="absolute inset-y-0 end-1 flex items-center ps-3.5 pointer-events-none">
              <ng-icon *ngIf="workPhoneNumber?.valid" class="text-green-500" name="phosphorCheckCircleFill" size="18"></ng-icon>
              <ng-icon *ngIf="!workPhoneNumber?.valid" class="text-red-600" name="phosphorXCircleFill" size="18"></ng-icon>
            </div>
            <input
              type="text"
              id="workPhoneNumber"
              formControlName="workPhoneNumber"
              (input)="onInputChange()"
              [ngClass]="{'border-red-500': (workPhoneNumber.value?.length || 0) > 0 && !workPhoneNumber.valid}"
              class="bg-[#f5f5f5] border placeholder-gray-300 border-gray-200 text-gray-900 text-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1"
              placeholder="+44 7745 000000" />
          </div>
        </td>
      </tr>

      <tr>
        <th class="py-1">
          <label for="mobilePhoneNumber" class="mt-auto mb-auto text-normal font-medium text-[#ababab] dark:text-white">
            Alternative phone number:
          </label>
        </th>
        <td class="py-1">
          <div class="relative">
            <div *ngIf="(mobilePhoneNumber.value?.length || 0) > 0" class="absolute inset-y-0 end-1 flex items-center ps-3.5 pointer-events-none">
              <ng-icon *ngIf="mobilePhoneNumber?.valid" class="text-green-500" name="phosphorCheckCircleFill" size="18"></ng-icon>
              <ng-icon *ngIf="!mobilePhoneNumber?.valid" class="text-red-600" name="phosphorXCircleFill" size="18"></ng-icon>
            </div>
            <input
              type="text"
              id="mobilePhoneNumber"
              formControlName="mobilePhoneNumber"
              (input)="onInputChange()"
              [ngClass]="{'border-red-500': (mobilePhoneNumber.value?.length || 0) > 0 && !mobilePhoneNumber.valid}"
              class="bg-[#f5f5f5] border placeholder-gray-300 border-gray-200 text-gray-900 text-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1"
              placeholder="+44 7745 000000" />
          </div>
        </td>
      </tr>

      <tr>
        <th class="py-1">
          <label for="mobileSecondaryPhoneNumber" class="mt-auto mb-auto text-normal font-medium text-[#ababab] dark:text-white">
            Second alternative phone number:
          </label>
        </th>
        <td class="py-1">
          <div class="relative">
            <div *ngIf="(mobileSecondaryPhoneNumber.value?.length || 0) > 0" class="absolute inset-y-0 end-1 flex items-center ps-3.5 pointer-events-none">
              <ng-icon *ngIf="mobileSecondaryPhoneNumber?.valid" class="text-green-500" name="phosphorCheckCircleFill" size="18"></ng-icon>
              <ng-icon *ngIf="!mobilePhoneNumber?.valid" class="text-red-600" name="phosphorXCircleFill" size="18"></ng-icon>
            </div>
            <input
              type="text"
              id="mobileSecondaryPhoneNumber"
              formControlName="mobileSecondaryPhoneNumber"
              (input)="onInputChange()"
              [ngClass]="{'border-red-500': (mobileSecondaryPhoneNumber.value?.length || 0) > 0 && !mobileSecondaryPhoneNumber.valid}"
              class="bg-[#f5f5f5] border placeholder-gray-300 border-gray-200 text-gray-900 text-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1"
              placeholder="+44 7745 000000" />
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="flex items-start mt-8 pt-3 px-5 border-t-[1px] border-[#ababab]">
    <button type="button"
      (click)="returnButtonAction()"
      class="text-gray-900 mr-auto border-[1px] font-bold border-gray-200  shadow-md focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-normal sm:w-auto px-5 py-2 text-center">
      &larr;
    </button>

    <button type="submit"
      [ngClass]="{
        'bg-[#367fee] hover:bg-blue-700' : !processing && (!contactForm.touched || (contactForm.touched && contactForm.valid)),
        'bg-blue-300 pointer-events-none' : processing || (contactForm.touched && contactForm.invalid)
      }"
      class="text-white ml-auto border-[1px] font-bold border-gray-200  shadow-md focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-normal sm:w-auto px-5 py-2 text-center">
      Save
      <custom-spinner class="inline-flex ml-2" *ngIf="processing" />
    </button>
  </div>
</form>
