import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ApiService } from '../services/api.service';
import { of } from 'rxjs';
import { ContactsService } from '../services/contacts.service';

export const openTasksResolver: ResolveFn<boolean> = (route, state) => {
  const apiService = inject(ApiService);
  const contactsService = inject(ContactsService);
  const contactId = contactsService.selectedSugarContactId;

  if (contactId === null) {
    return of(null);
  }

  return apiService.getOpenTasks(contactId);
};
