import { Injectable } from '@angular/core';
import { Role } from '../common/enums/role';
import FrontTeammate from '../common/models/front-teammate';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';
import compareStringArrays from '../common/helpers/compare-arrays';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private _initialised: boolean = false;
  private _roles: Role[] = [];
  private _teammate: null|FrontTeammate = null;

  private rolesSubject = new Subject<Role[]>();
  private initialisedSubject = new Subject<boolean>();

  public roles$: Observable<Role[]> = this.rolesSubject.asObservable();
  public initialised$: Observable<boolean> = this.initialisedSubject.asObservable();

  public get roles(): Role[] {
    return this._roles;
  }

  public get isInitialised(): boolean {
    return this._initialised;
  }

  public get teammate(): null|FrontTeammate {
    return this._teammate;
  }

  private set roles(value: Role[]) {
    if (!compareStringArrays(this._roles, value)) {
      this._roles = value;
      this.rolesSubject.next(this.roles);
    }
  }

  private set initialised(value: boolean) {
    const firstInit = !this._initialised && value;
    this._initialised = value;

    if (firstInit) {
      this.initialisedSubject.next(value);
    }
  }

  private set teammate(value: null|FrontTeammate) {
    this._teammate = value;
  }

  constructor(private apiService: ApiService) { }

  public init(teammate: FrontTeammate) {
    this.teammate = teammate;
    this.apiService.sendAuthInfo(this.teammate).subscribe((data) => {
      const { roles } = data;
      this.roles = roles.map((roleName: string) => {
        switch (roleName) {
          case 'ADMIN': return Role.ADMIN;
          case 'CLIENT_RELATIONS': return Role.CLIENT_RELATIONS;
          case 'DEVELOPER': return Role.DEVELOPER;
          case 'FINANCE': return Role.FINANCE;
          case 'GUEST_RELATIONS': return Role.GUEST_RELATIONS;
          case 'HOUSEKEEPING': return Role.HOUSEKEEPING;
          case 'KNOWLEDGE_BASE_ADMIN': return Role.KNOWLEDGE_BASE_ADMIN;
          case 'PARTNER_RELATIONS': return Role.PARTNER_RELATIONS;
          case 'PROPERTY_MANAGEMENT': return Role.PROPERTY_MANAGEMENT;
          case 'RESERVATIONS': return Role.RESERVATIONS;
          case 'SALES': return Role.SALES;
          default: return null;
        }
      }).filter((item: any) => item != null);
      this.initialised = true;
    });
  }
}
