import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../../../components/spinner/spinner.component';
import SugarEmailTemplate from '../../../../../common/models/email-template';
import { ToastService } from '../../../../../services/toast.service';
import { htmlToText } from 'html-to-text';

import { EditorConfig } from '@ckeditor/ckeditor5-core';
import { CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';

import Editor from '@custom-ckeditor/ckeditor';

@Component({
  selector: 'settings-email-template',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CKEditorModule,
    SpinnerComponent,
  ],
  templateUrl: './email-template.component.html',
  styleUrl: './email-template.component.css'
})
export class EmailTemplateComponent implements OnInit, AfterViewChecked {
  public Editor = Editor.Editor;

  @Input()
  public isSaving: boolean = false;

  @Output()
  public isSavingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public subject: string = '';

  @ViewChild('editor') editorComponent!: CKEditorComponent;

  @Input()
  public data: SugarEmailTemplate | null = null;

  @Output()
  public save: EventEmitter<any> = new EventEmitter<any>();

  public get initialData(): string {
    return this.data?.body_html || '';
  }

  public get editor(): any {
    return this.editorComponent.editorInstance;
  }

  public get htmlData(): string {
    return (this.editor ? this.editor.getData() : '')
      .replaceAll('<p>', '<p style="margin:0; padding:0;">');
  }

  public get editorConfig(): EditorConfig {
    return {
      toolbar: {
        items: [
          '|', 'undo', 'redo',
          '|', 'heading',
          '|', 'bold', 'italic', 'strikethrough', 'underline', 'blockQuote',
          '|',  'link', 'insertImage',
          '-', 'fontfamily', 'fontsize',
          '|', 'indent', 'outdent', 'bulletedList', 'numberedList',
          '|', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:side', 'toggleImageCaption', 'imageTextAlternative',
          '|', 'insertTable',
        ],
        shouldNotGroupWhenFull: true
      },
      image: {
        insert: {
          integrations: ['url']
        }
      },
      fontSize: {
        options: [
          5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
        ]
      }
    }
  }

  public get textData(): string {
    return htmlToText(this.htmlData);
  }

  constructor (private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.subject = this.data?.subject || '';
  }

  ngAfterViewChecked(): void {
    const divs = document.getElementsByClassName('ck-powered-by');
    if (divs.length > 0) {
      Array.from(divs).forEach((div) => {
        if (div instanceof HTMLElement) {
          div.remove();
        }
      });
    }
  }

  onSave() {
    if (!this.editor) {
      this.toastService.addToast('Unexpected editor error', 'error');
      return;
    }

    const data = {
      subject: this.subject,
      body: this.textData,
      body_html: this.htmlData,
    };

    this.save.emit(data);
  }
}
