<div class="p-5">
  <div class="mb-5 font-sm font-semibold">
    Editing "{{sugarOpportunity.name}}" opportunity
  </div>
  <opportunity-form (invalidForm)="isFormValid = !$event" [data]="opportunityData" [submitForm]="save" (submit)="onSubmitHandler($event)" />
</div>

<div class="w-full border-b border-gray-400 my-3"></div>

<div class="inline-flex w-full my-3 px-5">
  <button type="button"
    [routerLink]="'/sales/details'"
    class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-semibold rounded-full text-normal px-4 py-1.5">
    &larr;
  </button>

  <button
    pButton
    pRipple
    [disabled]="!isFormValid"
    (click)="onSaveHandler()"
    class="ms-auto rounded-full shadow">
    <custom-spinner class="inline-flex me-2" *ngIf="processing" />
    {{ (processing ? 'Saving...' : 'Save') }}
  </button>
</div>
