<div class="navigation-bar" [style]="backgroundColorCSS" (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave()">
  <ng-container [ngSwitch]="navigationType">
    <ng-container *ngSwitchCase="'button-scroll'">
      <ng-container  *ngTemplateOutlet="buttonScroll"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="autoScroll"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #autoScroll>
  <ul class="navigation" (scrollend)="onScrollEnd()" cdkScrollable>
    <li [id]="item.id" *ngFor="let item of items"  #trackedLink>
      <div class="item-label">{{ item.label }}</div>
      <div class="item-underline" [ngClass]="{'active': item['activatedOnUrls'].includes(activeUrl)}"></div>
    </li>
  </ul>
</ng-template>

<ng-template #buttonScroll>
  <div class="scroll-button py-2">
    <p-button [text]="true" class="rounded-full" (onClick)="scrollLeft()">
      <ng-icon [name]="'phosphorCaretLeftFill'"></ng-icon>
    </p-button>

    <div class="side-cover left"></div>
  </div>

  <ul class="navigation" cdkScrollable>
    <ng-container *ngFor="let item of items">
      <li
        *ngIf="canAccess(item)"
        [id]="item.id"
        class="py-3"
        [ngClass]="{'cursor-pointer': !item.disabled, 'disabled': item.disabled}" 
        (click)="!item.disabled ? onItemClick(item.url!) : null"
      #trackedLink>
        <div class="item-label h-full">
          <span class="place-self-center">{{ item.label }}</span>
          <i *ngIf="item.activity" class="pi pi-circle-fill animate-pulse"></i>
        </div>
        
        <div class="item-underline" [ngClass]="{'active': item['urlTriggers'].includes(activeUrl)}"></div>
      </li>
    </ng-container>
  </ul>

  <div class="scroll-button py-2">
    <p-button [text]="true" class="rounded-full" (onClick)="scrollRight()">
      <ng-icon [name]="'phosphorCaretRightFill'"></ng-icon>
    </p-button>

    <div class="side-cover right"></div>
  </div>
</ng-template>