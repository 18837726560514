import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FrontService } from '../../services/front.service';
import DuplicateConversations, { DuplicateConversation } from '../../common/interfaces/api/duplicate-conversations';
import { ApiService } from '../../services/api.service';
import { CommonModule } from '@angular/common';
import Front from '@frontapp/plugin-sdk';

@Component({
  selector: 'shared-duplicate-warning',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './duplicate-warning.component.html',
  styleUrl: './duplicate-warning.component.css'
})
export class DuplicateWarningComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public currentConversation: DuplicateConversation | null = null;
  private _duplicateConversations: DuplicateConversation[] = [];
  public set duplicateConversations(duplicateConversations: DuplicateConversation[]) {
    this._duplicateConversations = duplicateConversations;
    this.changeDetectorRef.detectChanges();
  }

  public get currentConversationId(): string | null {
    return this.currentConversation?.conversation_id || null;
  }

  public get duplicateConversations(): DuplicateConversation[] {
    return this._duplicateConversations;
  }

  public get timestampMidnight(): number {
    return Math.floor((new Date()).setHours(0,0,0,0) / 1000);
  }

  constructor (
    private frontService: FrontService,
    private apiService: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.frontService.conversation$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reloadData();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData() {
    this.currentConversation = null;
    this.duplicateConversations = [];

    const conversation_id = this.frontService.conversation?.id || null;
    if (conversation_id) {
      this.apiService.getDuplicateData(conversation_id)
        .subscribe((data: DuplicateConversations) => {
          const { success, data: { selected_conversation = null, duplicate_conversations = [] } = {} } = data;

          if (success) {
            this.currentConversation = selected_conversation;
            this.duplicateConversations = duplicate_conversations;
          }
        });
    }
  }

  openConversation(conversation_id: string) {
    if (conversation_id !== this.currentConversationId) {
      Front.openUrl(`https://app.frontapp.com/open/${conversation_id}`);
    }
  }

  getTime(timestamp: number): string {
    const date = (new Date(timestamp * 1000));

    return `${date.getHours()}:${date.getMinutes()}`;
  }
}
