<div class="flex flex-col p-4">
  <div class="flex flex-row justify-between">
    <h1 class="font-semibold text-lg align-middle my-auto">Add to existing account</h1>
    <button
      type="button"
      [routerLink]="'/sales/details'"
      class="text-[#3e3e40] bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 font-semibold rounded-full text-sm px-4 py-1.5 shadow-sm"
    >
      Back
    </button>
  </div>

  <typeahead [(data)]="data" [(loading)]="loading" [searchFunction]="apiTypeahead" label="Search accounts" />
  <record-selector-table (addClicked)="onAddClickHandler($event)" (selected)="onRecordSelectHandler($event)" [processing]="processing" [headersData]="selectorHeaders" [data]="selectorData" [loading]="loading" />
</div>

<div class="w-full border-b border-gray-300 my-4"></div>