import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import Toast from '../../common/interfaces/toast';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css'
})
export class ToastComponent {
  @Input()
  id: string = '';

  @Input()
  message: string = '';

  @Input()
  type: string = 'default';

  public get toasts(): Array<Toast> {
    return this.toastService.toasts;
  }

  constructor(private readonly toastService: ToastService) { }

  closeButtonAction(id: string) {
    this.toastService.removeToast(id);
  }
}
