import { Exclude, Expose } from "class-transformer";

@Exclude()
export class FrontContactHandle {
  @Expose()
  source!: string;
  
  @Expose()
  handle!: string;

  isEmail() {
    return this.source === 'email';
  }
}