import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import TableData from '../../../../../common/interfaces/table-data';
import { transformForTable } from '../../../../../common/helpers/transform-for-table';
import SugarOpportunity from '../../../../../common/models/sugar/sugar-opportunity';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from '../../../../../components/spinner/spinner.component';
import { InlineEditNotesComponent } from '../../../../../components/inline-edit-notes/inline-edit-notes.component';
import { ApiService } from '../../../../../services/api.service';
import { ToastService } from '../../../../../services/toast.service';
import { map } from 'rxjs';

@Component({
  selector: 'sales-opportunity-details',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    InlineEditNotesComponent,
  ],
  templateUrl: './opportunity-details.component.html',
  styleUrl: './opportunity-details.component.css'
})
export class OpportunityDetailsComponent implements OnInit {
  @Input() sugarOpportunity!: SugarOpportunity;
  @Input() inSync: boolean = false;
  @Input() unassignInProgress: boolean = false;

  @Output() unassignClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() editClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() syncClick: EventEmitter<void> = new EventEmitter<void>();

  savingNotes: boolean = false;
  sugarOpportunityDescription: string = '';

  public opportunityDetailsTableData: Array<TableData> = [];

  public get sugarOpportunityId(): string | null {
    return this.sugarOpportunity?.id ?? null;
  }

  public get opportunityNoteTableDataField(): TableData {
    return {value: this.sugarOpportunityDescription, label: 'Description'};
  }

  constructor (
    private apiService: ApiService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.opportunityDetailsTableData = transformForTable(this.sugarOpportunity, [
      {field: 'name', label: 'Name'},
      {field: 'probability', label: 'Probability (%)'},
      {field: 'opportunity_type', label: 'Type'},
      {field: 'date_closed', label: 'Expected Close Dae'},
      {field: 'assigned_user_name', label: 'Assigned to'},
      {field: 'sales_stage', label: 'Sales Stage'},
      {field: 'amount', label: 'Portland Opportunity Value'},
      {field: 'globalopportunityvalue_c', label: 'Global Opportunity Value'},
      {field: 'decisionmaker_name', label: 'Decision Maker'},
    ]);

    this.sugarOpportunityDescription = this.sugarOpportunity?.description ?? '';
  }

  onOpportunityNotesUpdate(notes: string) {
    if (!this.sugarOpportunityId) {
      return;
    }
    
    
    this.savingNotes = true;
    this.apiService.updateOpportunityNotes(this.sugarOpportunityId, notes).pipe(
      map((data: any) => {
        const {
          success = false,
          info: {
            subject = 'Unexpected Error',
            message = 'Unexpected error occured',
          } = {},
          data: notes = '',
        } = data;

        this.toastService.add({
          severity: (success ? 'success' : 'error'),
          summary: subject,
          detail: message,
        });
        
        return notes;
      })
    ).subscribe((notes: string) => {
      this.savingNotes = false;
      this.sugarOpportunityDescription = notes ?? '';
    });
  }

  onUnassignClick() {
    this.unassignClick.emit();
  }

  public onSyncClick() {
    this.syncClick.emit();
  }

  public onEditClick() {
    this.editClick.emit();
  }
}
