import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import ContactMatchType from '../../common/enums/contact-match-type';

@Component({
  selector: 'contact-select-pill',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './contact-select-pill.component.html',
  styleUrl: './contact-select-pill.component.css'
})
export class ContactSelectPillComponent {
  @Input()
  public selected: boolean = false;

  @Input()
  public type: string = 'Other';

  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter<any>();

  public get PARTIAL_MATCH(): string {
    return ContactMatchType.PARTIAL_MATCH;
  }

  public get NOT_MATCHED(): string {
    return ContactMatchType.NOT_MATCHED;
  }

  onClickHandler() {
    if (!this.selected) {
      this.selected = !this.selected;
      this.onSelect.emit();
    }
  }
}
