<div class="text-normal text-left text-gray-400 mt-5 bg-gray-100 rounded p-3">
  <table *ngIf="!loading && data.length > 0" class="w-full" [cellPadding]="5">
    <thead>
      <th *ngFor="let headerName of headersData" class="font-medium text-left">{{headerName}}</th>
    </thead>
    <tbody class="text-gray-700" >
      <tr *ngFor="let dataRecord of data; trackBy: trackById"
        [ngClass]="{'selected': dataRecord.id === selectedRecordId}"
        (click)="selectedRecordId = dataRecord.id"
      >
        <td *ngFor="let value of dataRecord.values" class="">{{value}}</td>
        <td class="h-full" [ngClass]="{'flex justify-center': (selectedRecordId === dataRecord.id)}">
          <p-button
            *ngIf="selectedRecordId === dataRecord.id"
            styleClass="flex items-center justify-center text-sm py-1 px-3 m-0"
            severity="primary"
            [rounded]="true"
            [raised]="true"
            label="Add"
            (onClick)="onAddButtonClickHandler(dataRecord.id)"
          >
            <p-progressSpinner
              *ngIf="processing"
              styleClass="flex w-[1rem] h-[1rem] my-auto mr-2 text-white" 
              strokeWidth="3" 
              animationDuration=".5s" />
          </p-button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="text-center p-5" *ngIf="loading">
    <custom-spinner [size]="6" />
  </div>

  <div class="text-gray-300 text-center p-5" *ngIf="!loading && data.length === 0">
    (No records)
  </div>
</div>
