import { Exclude, Expose, Transform, Type } from "class-transformer";
import SugarEmail from "./sugar-email";

@Exclude()
export default class SugarAccount {
  @Expose()
  id!: string;

  @Expose()
  @Transform(({value}) => value || '-')
  name!: string;

  @Expose()
  description!: string;

  @Expose()
  deleted!: boolean;

  @Expose()
  @Transform(({value}) => value || '-')
  sales_stage!: string;

  @Expose({ name: 'email' })
  @Type(() => SugarEmail)
  emails: SugarEmail[] = [];

  @Expose()
  industry!: string;

  @Expose()
  phone_fax!: string;

  @Expose()
  rating!: string;

  @Expose()
  phone_office!: string;

  @Expose()
  phone_alternate!: string;

  @Expose()
  website!: string;

  @Expose()
  service_level!: string;

  @Expose()
  parent_name!: string;

  @Expose()
  assigned_user_id!: string;

  @Expose()
  assigned_user_name!: string;

  @Expose()
  is_escalated!: boolean;

  @Expose()
  airporttransfer_c!: string;

  @Expose()
  @Transform(({value}) => value || '')
  account_note!: string;

  @Expose()
  apartmentaccess_c!: string;

  @Expose()
  @Transform(({value}) => value || '-')
  account_type!: string;

  @Expose()
  type!: string;

  @Expose()
  @Transform(({value}) => value || '-')
  account_grade!: string;

  @Expose()
  @Transform(({value}) => value || '-')
  guest_contact_category!: string;

  @Expose()
  @Transform(({value}) => value || '-')
  account_support_team!: string;

  @Expose()
  inactivecategory_c!: string;

  @Expose()
  agencynotes_c!: string;

  @Expose()
  accounts_contacts_1_name!: string;

  @Expose()
  accounts_contacts_1contacts_idb!: string;

  @Expose()
  global_account_support_c!: string;

  @Expose()
  forecast_opportunity_value!: string;

  @Expose()
  notes_credit_c!: string;

  @Expose()
  account_id1_c!: string;
  

  @Expose()
  account_id_c!: string;

  @Expose()
  primary_contact_id!: string;

  @Expose()
  contact_id_c!: string;

  @Expose()
  public get primary_email(): string | null {
    return this.emails[0]?.email_address || null;
  }
}
