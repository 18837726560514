import { Injectable } from '@angular/core';
import TableData from '../common/interfaces/table-data';
import TableDataMapping from '../common/interfaces/table-data-mapping';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor() { }

  transformData(data: any, dataMapping: Array<TableDataMapping>): Array<TableData> {
    return dataMapping
      .reduce((acc: Array<TableData>, record: TableDataMapping) => {
        if (typeof data[record.field] !== "undefined") {
          acc.push({value: data[record.field] || '-', label: record.label});
        }

        return acc;
      }, []);
  }
}
