import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SpinnerComponent } from '../spinner/spinner.component';
import { map, Observable, take } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'fis-inline-edit-notes',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    SpinnerComponent,
    InputTextareaModule,
  ],
  templateUrl: './inline-edit-notes.component.html',
  styleUrl: './inline-edit-notes.component.css'
})
export class InlineEditNotesComponent implements OnInit {
  private _saving: boolean = false;

  @Input() label: string = 'Notes';
  @Input() notes: string = '';
  @Input()
  set saving(value: boolean) {
    this._saving = value;
    if (!value && this.editMode) {
      this.editMode = false;
    }
  }
  get saving(): boolean {
    return this._saving;
  }
  @Output() submit = new EventEmitter<string>();
  
  notesInput: string = '';
  editMode: boolean = false;
  
  ngOnInit(): void {
    this.notesInput = this.notes;
  }

  onEditClick() {
    this.editMode = true;
  }

  onCancelClick() {
    this.editMode = false;
    this.notesInput = this.notes;
  }

  onSaveClick() {
    this.submit.emit(this.notesInput);
  }
}
