import { Configuration } from "rollbar";

export const environment = {
  production: false,
  apiUrl: 'https://api.fis.staging.portlandbrown.com',
  sugarUrl: 'https://portlandbrown.enablecloud.co.uk',
  pbEmailDomains: ['portlandbrown.com', 'croftliving.co.uk', 'portlandbrown.co.uk', 'portlandbrown.zendesk.com']
};

export const rollbarConfig: Configuration = {
  accessToken: '62f611ceeeed4b4c95cf6f545d78f0ba',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'staging',
};