import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { HashPreserveQueryLocationStrategy } from './common/strategies/hash-location';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { authInterceptor } from './common/interceptors/auth.interceptor';
import { responseInfoInterceptor } from './common/interceptors/response-info.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload'
      })
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors(
        [
          authInterceptor,
          responseInfoInterceptor,
        ]
      )
    ),
    importProvidersFrom(BrowserAnimationsModule),
    { provide: LocationStrategy, useClass: HashPreserveQueryLocationStrategy },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    MessageService,
  ]
};
