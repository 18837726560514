import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { NavbarService } from '../../services/navbar.service';
import NavbarLink from '../../common/interfaces/navbar-link';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ContactsService } from '../../services/contacts.service';
import ContactPill from '../../common/interfaces/contact';
import ContactMatchType from '../../common/enums/contact-match-type';
import { ContactSelectorComponent } from '../../components/contact-selector/contact-selector.component';
import { Contact } from '../../common/models/fis/contact';
import { NavigationBarComponent } from "../../components/navigation-bar/navigation-bar.component";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'shared-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ContactSelectorComponent,
    NavigationBarComponent
],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  _subscription: Subscription;

  public isDown: boolean = false;
  public initSliderX: number = 0;
  public initMouseX: number = 0;
  public mouseX: number = 0;
  public dragging: boolean = false;

  public get offsetMouseX(): number {
    return this.mouseX - this.initMouseX;
  }

  @ViewChild('slider') _slider: any;
  public get slider() {
    return this._slider?.nativeElement;
  }

  public get links(): Array<NavbarLink> {
    return this.navbarService.navbarLinks;
  }

  public get activeRoute(): Array<string> {
    return this.navbarService.activeRoute;
  }

  public get currentUrl(): string {
    return this.router.url;
  }

  public get contactsLoading(): boolean {
    return this.contactsService.sugarContactsLoading
  }

  public get selectedContactId(): string | null {
    return this.contactsService.selectedContactId;
  }

  public get contactPills(): ContactPill[] {
    return this.contactsService.getSortedContacts()
      .map((contact: Contact) => ({
        id: contact.id,
        name: (contact.sugarLink?.contact ? contact.sugarLink.contact.name : contact?.name) || 'No name',
        type: (contact.sugarLink?.contact ? contact.sugarLink.contact.contact_type : (contact.partialMatch ? ContactMatchType.PARTIAL_MATCH : ContactMatchType.NOT_MATCHED)),
      }));
  }

  public navigationUrls = [
    {
      label: 'Contact',
      url: '/contact',
      icon: 'phosphorUserListBold',
      activatedOnUrls: [
        '/contact'
      ]
    },
    { label: 'Reservations',
      url: '/reservations/list',
      icon: 'phosphorUserListBold',
      activatedOnUrls: [
        '/reservations/list'
      ]
    },
    { label: 'Cases',
      url: '/cases/current',
      icon: 'phosphorUserListBold',
      activatedOnUrls: [
        '/cases/current',
        '/cases/list'
      ]
    },
    { label: 'Open Tasks',
      url: '/tasks/open',
      icon: 'phosphorUserListBold',
      activatedOnUrls: [
        '/tasks/open'
      ]
    },
    { label: 'Sales',
      url: '/sales',
      icon: 'phosphorUserListBold',
      activatedOnUrls: [
        '/sales',
        '/sales/details',
        '/sales/account-create',
        '/sales/opportunity-create',
        '/sales/account-edit',
        '/sales/opportunity-edit',
        '/sales/add-to-account',
        '/sales/add-to-opportunity'
      ]
    },
    { label: 'Mass Email',
      url: '/settings',
      icon: 'phosphorUserListBold',
      activatedOnUrls: [
        '/settings'
      ]
    },
  ];

  constructor (
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly contactsService: ContactsService,
    private readonly navbarService: NavbarService,
    private readonly router: Router,
  ) {
    this._subscription = this.navbarService.navigationChanged$
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });

    this.contactsService.sugarContactsLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });

    if (!environment.production) {
      this.navigationUrls.push({ label: 'Debug', url: '/debug', activatedOnUrls: ['/debug'],  icon: 'phosphorUserListBold' });
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  isRouteActive(url: string): boolean {
    const currentRoute = this.activeRoute[0] ?? null;
    if (!currentRoute) {
      return false;
    }

    return currentRoute.startsWith(url);
  }

  dragStart(event: any) {
    this.isDown = true;
    this.initMouseX = event.x;
    this.initSliderX = this.slider.scrollLeft;
  }

  dragEnd() {
    if (this.isDown) {
      this.isDown = false;

      setTimeout(() => {
        this.dragging = false;
      }, 50)
    }
  }

  dragMove(event: any) {
    if (!this.isDown) return;
    this.mouseX = event.x;

    const diffPosX = (this.mouseX - this.initMouseX);
    if (diffPosX > 25 || diffPosX > -25) {
      this.dragging = true;
    }

    this.slider.scrollLeft = (this.initSliderX - this.offsetMouseX);
  }

  setActive(url: string) {
    this.navbarService.setActive([url]);
  }

  navigate(url: string) {
    if (!this.dragging) {
      this.setActive(url);
      this.router.navigateByUrl(url);
    }
  }

  public onContactSelectHandler(id: string) {
    this.contactsService.selectedContactId = id;
  }
}
