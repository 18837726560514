import { Exclude, Expose } from "class-transformer";

@Exclude()
export default class FrontTeammate {
  
  @Expose()
  id!: string;
  
  @Expose()
  name!: string;
  
  @Expose()
  username!: string;
  
  @Expose()
  email!: string;
}