import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CardComponent } from '../card/card.component';
import { TableService } from '../../services/table.service';
import TableData from '../../common/interfaces/table-data';
import TableDataMapping from '../../common/interfaces/table-data-mapping';
import Front from '@frontapp/plugin-sdk';
import { TextExpanderComponent } from '../text-expander/text-expander.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-reservation-card',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    TextExpanderComponent
  ],
  templateUrl: './reservation-card.component.html',
  styleUrl: './reservation-card.component.css'
})
export class ReservationCardComponent {
  private sugarUrl: string;

  @Input()
  active: boolean = false;

  @Input()
  id: string = '';

  @Input()
  data: any = [];

  public get dataMapping(): Array<TableDataMapping> {
    return [
      {field: 'name', label: 'Reservation'},
      {field: 'type_label', label: 'Reservation type'},
      {field: 'oscar_source', label: 'Oscar source'},
      {field: 'guest_name', label: 'Guest Name'},
      {field: 'location', label: 'Location'},
      {field: 'apartment', label: 'Apartament'},
      {field: 'arrival_date', label: 'Arrival Date'},
      {field: 'departure_date', label: 'Departure Date'},
      {field: 'account_support_team', label: 'Account support team'}
    ];
  }

  public get tableData(): Array<TableData> {
    return this.tableService.transformData(this.data, this.dataMapping);
  }

  constructor (private readonly tableService: TableService) {
    this.sugarUrl = environment.sugarUrl;
  }

  viewInSugarCRM(reservation_id: string) {
    Front.openUrl(`${this.sugarUrl}/#RES_Reservations/${reservation_id}`);
  }
}
