import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, length: number): unknown {
    const arrayText = value.split(' ');
    if (arrayText.length < length) {
      return value;
    }

    const slicedText = arrayText.slice(0, length).join(' ');

    return `${slicedText}...`;
  }

}
