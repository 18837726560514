import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { OpportunityFormComponent } from "../components/opportunity-form/opportunity-form.component";
import { ApiService } from '../../../../services/api.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ToastService } from '../../../../services/toast.service';
import { finalize, map, of, Subject, switchMap, takeUntil } from 'rxjs';
import SugarAccount from '../../../../common/models/sugar/sugar-account';
import { SpinnerComponent } from '../../../../components/spinner/spinner.component';
import { ButtonModule } from 'primeng/button';
import { FrontService } from '../../../../services/front.service';

@Component({
  selector: 'app-opportunity-create',
  standalone: true,
  imports: [
    CommonModule,
    OpportunityFormComponent,
    RouterLink,
    SpinnerComponent,
    ButtonModule,
  ],
  templateUrl: './opportunity-create.component.html',
  styleUrl: './opportunity-create.component.css'
})
export class OpportunityCreateComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public processing: boolean = false;
  public isFormValid: boolean = true;
  public sugarAccount: SugarAccount | null = null;

  public submitForm = new EventEmitter<void>();

  public get data(): any {
    return {
      account_name: this.sugarAccount?.name,
      account_id: this.sugarAccount?.id,
    };
  }

  public get conversationId(): string {
    return this.frontService.conversation?.id ?? '';
  }

  constructor (
    private apiService: ApiService,
    private frontService: FrontService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(({data = {}}) => {
        const {
          sugarAccount = null,
        } = data;

        this.sugarAccount = sugarAccount;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public onFormSubmitHandler(data: any) {
    this.processing = true;

    this.apiService.createOpportunity({
      account_id: this.data.account_id,
      account_name: this.data.account_name,
      ...data,
    }).pipe(
      switchMap((response: any) => {
        const {
          success = false,
          info: {
            subject = 'Error',
            message = '(message missing)',
          } = {},
          data: { id: newOpportunityId = null } = {}
        } = response;

        if (success && newOpportunityId) {
          return this.apiService.addConversationAsEmails(this.conversationId, 'Opportunities', newOpportunityId).pipe(
            map((secondResponse: any) => ({ success, subject, message }))
          );
        }

        return of({ success: false, subject, message });
      }),
      finalize(() => this.processing = false)
    ).subscribe(({ success, subject, message }) => {
      this.toastService.add({
        severity: success ? 'success' : 'error',
        summary: subject,
        detail: message,
      })

      if (success) {
        this.router.navigateByUrl('/sales/details');
      }
    });
  }

  public createButtonClickHandler() {
    this.submitForm.emit();
  }
}
