import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { NavbarService } from '../../services/navbar.service';
import NavbarLink from '../../common/interfaces/navbar-link';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ContactsService } from '../../services/contacts.service';
import ContactPill from '../../common/interfaces/contact';
import ContactMatchType from '../../common/enums/contact-match-type';
import { ContactSelectorComponent } from '../../components/contact-selector/contact-selector.component';
import { Contact } from '../../common/models/fis/contact';
import { NavigationBarComponent } from "../../components/navigation-bar/navigation-bar.component";
import { PermissionsService } from '../../services/permissions.service';
import SugarContact from '../../common/models/sugar/sugar-contact';
import { Role } from '../../common/enums/role';
import NavigationItem from '../../common/interfaces/navigation-item';

@Component({
  selector: 'shared-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ContactSelectorComponent,
    NavigationBarComponent
],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public isDown: boolean = false;
  public initSliderX: number = 0;
  public initMouseX: number = 0;
  public mouseX: number = 0;
  public dragging: boolean = false;

  public get offsetMouseX(): number {
    return this.mouseX - this.initMouseX;
  }

  @ViewChild('slider') _slider: any;
  public get slider() {
    return this._slider?.nativeElement;
  }

  public get links(): Array<NavbarLink> {
    return this.navbarService.navbarLinks;
  }

  public get activeRoute(): Array<string> {
    return this.navbarService.activeRoute;
  }

  public get currentUrl(): string {
    return this.router.url;
  }

  public get contactsLoading(): boolean {
    return this.contactsService.sugarContactsLoading
  }

  public get selectedContactId(): string | null {
    return this.contactsService.selectedContactId;
  }

  public get contactPills(): ContactPill[] {
    return this.contactsService.getSortedContacts()
      .map((contact: Contact) => ({
        id: contact.id,
        name: (contact.sugarLink?.contact ? contact.sugarLink.contact.name : contact?.name) || 'No name',
        type: (contact.sugarLink?.contact ? contact.sugarLink.contact.contact_type : (contact.partialMatch ? ContactMatchType.PARTIAL_MATCH : ContactMatchType.NOT_MATCHED)),
      }));
  }

  public get navigationData(): NavigationItem[] {
    return this.navbarService.navigationData;
  }

  constructor (
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly permissionsService: PermissionsService,
    private readonly contactsService: ContactsService,
    private readonly navbarService: NavbarService,
    private readonly router: Router,
  ) {
    this.navbarService.navigationData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });

    this.contactsService.sugarContactsLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });

    this.permissionsService.initialised$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.buildNavbar();
      });

    this.contactsService.selectedSugarContact$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (sugarContact: SugarContact | null) => {
        if (!sugarContact) {
          return;
        }

        const { id, current_reservation_id: currentReservationId, contact_type: contactType } = sugarContact;

        const isBooker = ((contactType === 'Booker' || contactType === 'Influencer') ? true : false);
        if (isBooker) {
          this.navbarService.setNavigationItemDisabled('/cases/current', isBooker);
          this.navbarService.setNavigationItemDisabled('/tasks/open', isBooker);
        }

        const hasReservation = (typeof currentReservationId === 'string' && currentReservationId.length > 0);
        if(hasReservation) {
          this.navbarService.setNavigationItemActivity('/reservations/list', true);
        }

      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected buildNavbar() {
    this.navbarService.addNavigationItem('/contact', 'Contact', ['/contact'], []);
    this.navbarService.addNavigationItem('/sales', 'Sales', [
      '/sales',
      '/sales/details',
      '/sales/account-create',
      '/sales/opportunity-create',
      '/sales/account-edit',
      '/sales/add-to-account',
      '/sales/add-to-opportunity',
    ], [Role.ADMIN, Role.DEVELOPER, Role.SALES]);
    this.navbarService.addNavigationItem('/reservations/list', 'Reservations', ['/reservations/list']);
    this.navbarService.addNavigationItem('/cases/current', 'Cases', ['/cases/current', '/cases/list']);
    this.navbarService.addNavigationItem('/tasks/open', 'Open Tasks', ['/tasks/open']);
    this.navbarService.addNavigationItem('/settings', 'Mass Email', ['/settings'], [Role.ADMIN, Role.DEVELOPER, Role.GUEST_RELATIONS]);
    this.navbarService.addNavigationItem('/debug', 'Debug', ['/debug'], [Role.DEVELOPER]);
  }

  isRouteActive(url: string): boolean {
    const currentRoute = this.activeRoute[0] ?? null;
    if (!currentRoute) {
      return false;
    }

    return currentRoute.startsWith(url);
  }

  dragStart(event: any) {
    this.isDown = true;
    this.initMouseX = event.x;
    this.initSliderX = this.slider.scrollLeft;
  }

  dragEnd() {
    if (this.isDown) {
      this.isDown = false;

      setTimeout(() => {
        this.dragging = false;
      }, 50)
    }
  }

  dragMove(event: any) {
    if (!this.isDown) return;
    this.mouseX = event.x;

    const diffPosX = (this.mouseX - this.initMouseX);
    if (diffPosX > 25 || diffPosX > -25) {
      this.dragging = true;
    }

    this.slider.scrollLeft = (this.initSliderX - this.offsetMouseX);
  }

  navigate(url: string) {
    if (!this.dragging) {
      this.router.navigateByUrl(url);
    }
  }

  public onContactSelectHandler(id: string) {
    this.contactsService.selectedContactId = id;
  }
}
