<div class="w-full items-center" (clickOutside)="hideMenu()">
  <div class="relative">
    <typeahead [(data)]="data" [inputClass]="inputClass" [value]="selectedItem?.name ?? ''" [(loading)]="loading" [label]="label" [placeholder]="placeholder" [searchFunction]="searchFunction" />

    <aside *ngIf="data.length > 0 && showSearchItems"
      class="absolute z-10 x-auto text-sm flex flex-col items-start bg-white border rounded-md shadow-md mt-1">
      <ul class="flex flex-col w-full">
        <li
          *ngFor="let item of data; let index = index"
          class="px-2 py-3 space-x-2 hover:bg-blue-600 hover:text-white focus:bg-blue-600 focus:text-white focus:outline-none"
          (click)="selectSearchItem(item); showSearchItems = false;">
          {{ item.name }}
        </li>
      </ul>
    </aside>
  </div>
</div>
