import FrontContact from "../front-contact";
import { FrontRecipient } from "../front-recipient";
import { Contact } from "./contact";

export default class FrontLink {
  parent: Contact;

  recipient?: FrontRecipient;
  contact?: FrontContact;

  constructor (parent: Contact) {
    this.parent = parent;
  }
}