import SugarContact from "../sugar/sugar-contact";
import { Contact } from "./contact";

export class SugarLink {
  parent: Contact;

  contact?: SugarContact;

  constructor (parent: Contact) {
    this.parent = parent;
  }
}