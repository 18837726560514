<div *ngIf="!isPageLoading">
  <active-viewers />
  <div class="inline-flex w-full pt-5">
    <div class="ms-5 mt-auto text-gray-700 italic font-light">
      Email Template
    </div>
    <div class="me-5 mt-auto ms-auto text-gray-700 italic font-light text-normal">
      Last Modified: {{ emailTemplateLastModified }}
    </div>
  </div>
  <hr class="mx-3 mt-1 mb-5 border-gray-200 border-dashed border-[1px]">
  <settings-email-template  [(isSaving)]="isSavingEmailTemplate" (save)="onSugarEmailTemplateSave($event)" [data]="sugarEmailTemplate" />
  <hr class="mx-3 my-5 border-gray-200 border-dashed border-[1px]">
</div>

<ng-container *ngIf="isPageLoading">
  <app-loader />
</ng-container>
