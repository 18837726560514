import { Exclude, Expose } from "class-transformer";

@Exclude()
export default class SugarEmailTemplate {
  @Expose()
  id!: string;

  @Expose()
  date_entered!: string;

  @Expose()
  date_modified!: string;

  @Expose()
  modified_user_id!: string;

  @Expose()
  modified_by_name!: string;

  @Expose()
  created_by!: string;

  @Expose()
  created_by_name!: string;

  @Expose()
  published!: string;

  @Expose()
  name!: string;

  @Expose()
  description!: string;

  @Expose()
  subject!: string;

  @Expose()
  body: string = '';

  @Expose()
  body_html: string = '';

  @Expose()
  deleted!: boolean;

  @Expose()
  base_module!: string;

  @Expose()
  from_name!: string;

  @Expose()
  from_address!: string;

  @Expose()
  text_only!: boolean;

  @Expose()
  type!: string;

  @Expose()
  has_variables!: boolean;

  @Expose()
  assigned_user_id!: string;

  @Expose()
  assigned_user_name!: string;
}
