<div class="row w-full px-3">
  <div class="col my-5 w-full">
    <div>
      <label for="subject" class="text-normal me-auto mb-1 font-medium text-[#ababab]">
        Subject:
      </label>
    </div>
    <div class="inline-flex flex-row items-center w-full">
      <div class="flex flex-col items-center w-full me-3">
        <input
          id="subject"
          type="text"
          [(ngModel)]="subject"
          class="bg-[#f5f5f5] border w-full border-gray-200 text-gray-900 text-normal rounded-lg focus:ring-blue-500 focus:border-blue-500 l px-2 py-1">
      </div>
      <button
        (click)="onSave()"
        class="text-white flex border-[1px] font-bold border-gray-200 shadow-md focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full sm:w-auto px-3 py-2 text-sm text-center bg-[#367fee] hover:bg-blue-700">
        Save
        <custom-spinner *ngIf="isSaving" class="ml-1" />
      </button>
    </div>
  </div>

  <div class="col">
    <ckeditor #editor *ngIf="data" [config]="editorConfig" [editor]="Editor" data="{{ initialData }}"></ckeditor>
  </div>
</div>
