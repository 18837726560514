<custom-card>
  <div class="py-3 text-[#3e3e40] font-semibold">
    {{data.name}}
  </div>

  <table class="w-full text-left text-normal">
    <tr>
      <th class="text-[#ababab] align-text-top w-[30%] py-1 font-semibold">Case Number</th>
      <td class="text-[#3e3e40] py-1">{{data?.case_number}}</td>
    </tr>
    <tr>
      <th class="text-[#ababab] align-text-top w-[30%] py-1 font-semibold">
        Status:
      </th>
      <td class="py-1">
        <app-status-pill [status]="data.status"></app-status-pill>
        <span *ngIf="this.data?.is_zendesk_case ?? false" class="bg-gray-100 text-gray-800 text-[0.6rem] font-medium me-2 px-2.5 py-0.5 rounded-lg dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
          Zendesk Case
        </span>
      </td>
    </tr>
    <tr *ngFor="let rowData of tableData">
      <th class="text-[#ababab] align-text-top w-[30%] py-1 font-semibold">{{rowData.label}}:</th>
      <td class="text-[#3e3e40] py-1">{{rowData.value}}</td>
    </tr>
  </table>

  <div class="flex w-full pt-6">
    <div class="mr-auto">
      <custom-button extraClasses="font-semibold" (click)="viewInSugarCRM(data.id)">
        View in SugarCRM
      </custom-button>
    </div>
  </div>

  <div class="w-full mt-4" *ngIf="allowAssign">
    <custom-button
      (click)="onAssignClick()"
      bgClass="bg-[#367fee] hover:bg-[#2b6fd6]"
      textClass="text-white"
      paddingClass="py-2.5 px-5"
      extraClasses="w-full"
    >
    Assign Case
    <custom-spinner class="inline-flex ml-2" *ngIf="assignProcessing" />
  </custom-button>
  </div>
</custom-card>
