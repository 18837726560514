export enum HandleType {
  email = 'email',
  tweet = 'tweet',
  tweet_dm = 'tweet_dm',
  googleplay = 'googleplay',
  sms = 'sms',
  whatsapp = 'whatsapp',
  smooch = 'smooch',
  facebook = 'facebook',
  intercom = 'intercom',
  call = 'call',
  custom = 'custom',
  front_chat = 'front_chat',
}
