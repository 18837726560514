import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import SugarOpportunity from '../../../../../common/models/sugar/sugar-opportunity';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../../../components/spinner/spinner.component';
import { OpportunityFormComponent } from '../opportunity-form/opportunity-form.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import toClass from '../../../../../common/helpers/to-class';
import { ApiService } from '../../../../../services/api.service';
import { ToastService } from '../../../../../services/toast.service';
import SugarAccount from '../../../../../common/models/sugar/sugar-account';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'sales-opportunity-edit',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    OpportunityFormComponent,
    ButtonModule,
  ],
  templateUrl: './opportunity-edit.component.html',
  styleUrl: './opportunity-edit.component.css'
})
export class OpportunityEditComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public processing: boolean = false;
  public isFormValid: boolean = true;

  public sugarOpportunity!: SugarOpportunity;
  public assignedSugarUser: any | null = null;
  public sugarAccount: SugarAccount | null = null;

  @Output() return: EventEmitter<void> = new EventEmitter<void>();
  public save: EventEmitter<void> = new EventEmitter<void>();

  public get opportunityData(): any {
    const {
      name,
      id,
      opportunity_type,
      date_closed,
      sales_stage,
      amount,
      globalopportunityvalue,
      probability,
      decisionmaker_id,
      decisionmaker_name,
    } = this.sugarOpportunity

    return {
      id,
      name,
      opportunity_type,
      date_closed,
      sales_stage,
      amount,
      globalopportunityvalue,
      probability,
      assigned_user_id: this.sugarOpportunity?.assigned_user_id ?? '',
      assigned_user_name: this.sugarOpportunity?.assigned_user_name ?? '',
      decisionmaker_id,
      decisionmaker_name,
      account_id: this.sugarAccount?.id ?? '',
      account_name: this.sugarAccount?.name ?? '',
    };
  }

  constructor (
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(({data = {}}) => {
        const {
          sugarUser = null,
          sugarOpportunity = null,
          sugarAccount = null,
        } = data;

        this.sugarOpportunity = toClass(SugarOpportunity, sugarOpportunity);
        this.sugarAccount = sugarAccount;
        this.assignedSugarUser = sugarUser;

        if (!this.sugarOpportunity) {
          this.router.navigateByUrl('/sales/details');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onReturnHandler() {
    this.return.emit();
  }

  onSaveHandler() {
    this.save.emit();
  }

  onSubmitHandler(data: any) {
    this.processing = true;
    const sugarOpportunity = toClass(SugarOpportunity, data);

    const { id: sugarOpportunityId } = sugarOpportunity;
    if (!sugarOpportunityId) {
      this.processing = false;
      return;
    }

    this.apiService.updateOpportunity(sugarOpportunityId, data)
      .subscribe((response: any) => {
        const {
          success = false,
          info: { subject = 'Unexpected Error', message = 'Unexpected error occured' } = {},
        } = response;
        this.processing = false;

        if (!success) {
          this.toastService.add({
            severity: 'error',
            summary: subject,
            detail: message,
          });

          return;
        }

        this.toastService.add({
          severity: 'success',
          summary: subject,
          detail: message,
        });

        this.router.navigateByUrl('/sales/details');
      });
  }
}
