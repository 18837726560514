import { Exclude, Expose, Transform, Type } from "class-transformer";
import SugarEmail from "./sugar-email";
import SugarAccount from "./sugar-account";

@Exclude()
export default class SugarContact {
  @Expose()
  id!: string;

  @Expose()
  name!: string;

  @Expose()
  first_name!: string;

  @Expose()
  last_name!: string;

  @Expose()
  description: string = '-';

  @Expose()
  contact_type: string = '-';

  @Expose()
  job_title: string = '-';

  @Expose()
  guest_contact: string = '-';

  @Type(() => SugarEmail)
  @Expose()
  @Transform(({value, obj}: {value: SugarEmail[], obj: any}) => {
    value.forEach((email: SugarEmail) => {
      if (email.primary_address) {
        obj.primary_email = email.email_address;
        obj.email = email.email_address;
      }

      if (!email.primary_address && obj.secondary_email) {
        obj.secondary_email = email.email_address;
      }
    });

    return value;
  })
  emails: Array<SugarEmail> = [];

  @Expose()
  primary_email!: string;

  @Expose()
  secondary_email!: string;

  @Expose()
  phone_home!: string;

  @Expose()
  phone_mobile: string = '-';

  @Expose()
  phone_mobile_2: string = '-';

  @Expose()
  phone_work: string = '-';

  @Expose()
  phone_other!: string;

  @Expose()
  phone_fax!: string;

  @Expose()
  reservation_number!: string;

  @Expose()
  account_id: string | null = null;

  @Expose()
  @Type(() => SugarAccount)
  account: SugarAccount | null = null;

  @Expose()
  account_note!: string;

  @Expose()
  inhouse!: boolean;

  @Expose()
  @Transform(({value}) => value || '')
  inhouse_apartment_id!: string;

  @Transform(({value}) => (value.length > 0 ? value : '-'))
  @Expose()
  guest_note: string = '-';

  @Transform(({value}) => (value.length > 0 ? value : '-'))
  @Expose()
  apartment_name!: string;

  @Transform(({value}) => (value === 'Yes' ? 'Yes' : 'No'))
  @Expose()
  previous_complaint!: string;

  @Transform(({value}) => (value.length > 0 ? value : '-'))
  @Expose()
  company_name!: string;

  @Expose()
  @Transform(({value}) => value || null)
  current_reservation_id!: string | null;

  @Transform(({value}) => (value.length > 0 ? value : '-'))
  @Expose()
  guest_insight!: string;

  @Transform(({value}) => (value === 'Yes' ? 'Yes' : 'No'))
  @Expose()
  handle_with_care!: string;

  @Expose()
  guest_satisfaction!: string;

  @Transform(({value}) => (value === true ? 'Yes' : 'No'))
  @Expose()
  high_visibility!: string;

  @Transform(({value}) => (value === 'Yes' ? 'Yes' : 'No'))
  @Expose()
  account_high_visibility!: string;

  @Expose()
  guest_arrival_notes: string = '-';

  @Expose()
  @Transform(({value}) => {
    if (value.length === 0) {
      return '-';
    }

    switch (value) {
      case 'team1':
        return 'Team 1';

      case 'team2':
        return 'Team 2';

      case 'team3':
        return 'Team 3';

      case 'team4':
        return 'Team 4';

      case 'team5':
        return 'Team 5';

      default:
        return value;
    }
  })
  account_support_team!: string;

  get is_primary_guest(): boolean {
    return this.current_reservation_id !== null;
  }

  get is_guest(): boolean {
    return this.contact_type === 'Guest';
  }

  getContactEmails(): string[] {
    return this.emails.map((contactEmail: SugarEmail) => contactEmail.email_address);
  }

  getPillData(): {id: string, name: string, type: string} {
    return {id: this.id, name: this.name, type: this.contact_type};
  }
}
