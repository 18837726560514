import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SpinnerComponent } from '../../../../../components/spinner/spinner.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { CustomValidators } from '../../../../../common/validators/custom-validators';
import { phosphorCheckCircleFill, phosphorXCircleFill } from '@ng-icons/phosphor-icons/fill';

@Component({
  selector: 'form-contact-update',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    ReactiveFormsModule,
    NgIconComponent,
  ],
  viewProviders: [provideIcons({
    phosphorXCircleFill,
    phosphorCheckCircleFill,
  })],
  templateUrl: './form-contact-update.component.html',
  styleUrl: './form-contact-update.component.css'
})
export class FormContactUpdateComponent implements OnInit {
  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onReturnClick: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  processing: boolean = false;

  @Input()
  data: any;

  public contactType: FormControl = new FormControl('');
  public firstName = new FormControl('');
  public lastName = new FormControl('', [Validators.required]);
  public primaryEmail = new FormControl('', [Validators.required, Validators.email]);
  public secondaryEmail = new FormControl('', [Validators.email]);
  public workPhoneNumber = new FormControl('', [CustomValidators.phoneNumber]);
  public mobilePhoneNumber = new FormControl('', [CustomValidators.phoneNumber]);
  public mobileSecondaryPhoneNumber = new FormControl('', [CustomValidators.phoneNumber]);

  public contactForm: FormGroup = new FormGroup({
    firstName: this.firstName,
    lastName: this.lastName,
    primaryEmail: this.primaryEmail,
    secondaryEmail: this.secondaryEmail,
    workPhoneNumber: this.workPhoneNumber,
    mobilePhoneNumber: this.mobilePhoneNumber,
    mobileSecondaryPhoneNumber: this.mobileSecondaryPhoneNumber,
    type: this.contactType,
  });

  constructor (private cdr: ChangeDetectorRef) { }

  onInputChange() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.updateForm();
  }

  updateForm() {
    const {
      firstName = '',
      lastName = '',
      primaryEmail = '',
      secondaryEmail = '',
      workPhoneNumber = '',
      mobilePhoneNumber = '',
      mobileSecondaryPhoneNumber = '',
    } = this.data;

    this.firstName?.setValue(firstName);
    this.lastName?.setValue(lastName);
    this.primaryEmail?.setValue(primaryEmail);
    this.secondaryEmail?.setValue(secondaryEmail);
    this.workPhoneNumber.setValue(workPhoneNumber);
    this.mobilePhoneNumber.setValue(mobilePhoneNumber);
    this.mobileSecondaryPhoneNumber.setValue(mobileSecondaryPhoneNumber);
  }

  returnButtonAction() {
    this.onReturnClick.emit();
  }

  onSubmitHandler() {
    if (this.contactForm.valid) {
      this.onSubmit.emit(this.contactForm.value);
    }
  }
}
