import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'custom-button',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.css'
})
export class ButtonComponent {
  @Input()
  extraClasses: string = '';

  @Input()
  bgClass: string = 'bg-white hover:bg-gray-100';

  @Input()
  textClass: string = 'text-gray-900';

  @Input()
  paddingClass: string = 'py-1.5 px-5';
}
