import { Component, OnDestroy } from '@angular/core';
import { EmailTemplateComponent } from './partials/email-template/email-template.component';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from '../../../services/loader.service';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../../components/loader/loader.component';
import SugarEmailTemplate from '../../../common/models/email-template';
import toClass from '../../../common/helpers/to-class';
import { ApiService } from '../../../services/api.service';
import { ToastService } from '../../../services/toast.service';
import { ActiveViewersComponent } from '../../../components/active-viewers/active-viewers.component';

@Component({
  selector: 'page-settings',
  standalone: true,
  imports: [
    CommonModule,
    EmailTemplateComponent,
    LoaderComponent,
    ActiveViewersComponent,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public isSavingEmailTemplate = false;

  protected _sugarEmailTemplate: SugarEmailTemplate | null = null;
  public set sugarEmailTemplate(template: SugarEmailTemplate) {
    this._sugarEmailTemplate = template;
    this.sugarEmailTemplate$.next(template);
  }

  public get sugarEmailTemplate(): SugarEmailTemplate | null {
    return this._sugarEmailTemplate;
  }

  public get emailTemplateLastModified(): string {
    if (!this.sugarEmailTemplate?.date_modified) {
      return 'Unknown';
    }

    const lastModified = new Date(this.sugarEmailTemplate?.date_modified);

    return lastModified.toLocaleString();
  }

  public get isPageLoading(): boolean {
    return this.loaderService.loading;
  }

  public sugarEmailTemplate$: Subject<SugarEmailTemplate> = new Subject<SugarEmailTemplate>();

  constructor (
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private apiService: ApiService,
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        const { emailTemplate } = data;

        this.sugarEmailTemplate = toClass(SugarEmailTemplate, emailTemplate);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  onSugarEmailTemplateSave(data: any) {
    const { subject = '', body = '', body_html = '' } = data;

    this.isSavingEmailTemplate = true;
    this.apiService.updateEmailTemplate({subject, body, body_html})
      .subscribe((response: any) => {
        const { success, data } = response;
        this.isSavingEmailTemplate = false;
        this.scrollToTop();

        if (!success) {
          this.toastService.addToast('Failed to save template!', 'error', 6);
          return;
        }

        this.sugarEmailTemplate = toClass(SugarEmailTemplate, data);
        this.toastService.addToast('Email template has been saved.', 'success', 6);
      });
  }
}
