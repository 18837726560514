<div class="text-[#ababab] py-1 font-semibold flex justify-between">
  {{ label }}
  <ng-container *ngIf="!editMode; else editModeButtons">
    <p-button (onClick)="onEditClick()" styleClass="text-gray-900 ms-1 p-1 px-2 text-xs font-semibold border-gray-300 hover:bg-gray-100 border-[1px]" [text]="true" severity="secondary">
      Edit
    </p-button>
  </ng-container>
</div>
<div class="text-[#4a4a4c] py-1" *ngIf="!editMode; else editModeNotes">
  {{ notes || '-' }}
</div>


<ng-template #editModeButtons>
  <div>
    <p-button (onClick)="onCancelClick()" styleClass="text-gray-900 ms-1 p-1 px-2 text-xs bg-red-500 hover:bg-red-600 text-white font-semibold border-gray-300 border-[1px]" [text]="true" severity="danger">
      Cancel
    </p-button>
    <p-button (onClick)="onSaveClick()" styleClass="text-gray-900 ms-1 p-1 px-2 text-xs bg-green-500 hover:bg-green-600 text-white font-semibold border-gray-300 border-[1px]" [text]="true" severity="success">
      <custom-spinner class="inline-flex me-2" *ngIf="saving" />
      Save
    </p-button>
  </div>
</ng-template>

<ng-template #editModeNotes>
  <textarea
    class="mt-3"
    rows="5"
    cols="30"
    pInputTextarea
    [(ngModel)]="notesInput">
  </textarea>
</ng-template>
