<div class="flex flex-col p-4">
  <div class="flex flex-row justify-between">
    <h1 class="font-semibold text-lg align-middle my-auto">Create a new opportunity</h1>
    <button
      type="button"
      [routerLink]="'/sales/details'"
      class="text-[#3e3e40] bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 font-semibold rounded-full text-sm px-4 py-1.5 shadow-sm"
    >
      Back
    </button>
  </div>

  <div class="mt-5">
    <opportunity-form (invalidForm)="isFormValid = !$event" [data]="data" [submitForm]="submitForm" (submit)="onFormSubmitHandler($event)" />
  </div>
</div>

<div class="w-full border-b-[1px] border-[#ababab] my-3"></div>

<div class="flex flex-col p-4">
  <button
    pButton
    pRipple
    [disabled]="!isFormValid"
    (click)="createButtonClickHandler()"
    class="w-full rounded-full shadow justify-center">
    <custom-spinner [size]="4" class="inline-flex me-2" *ngIf="processing" />
    Create account and add email to SugarCRM
  </button>
</div>
