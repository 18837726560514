import TableData from "../interfaces/table-data";
import TableDataMapping from "../interfaces/table-data-mapping";

export const transformForTable = (data: any, dataMapping: Array<TableDataMapping>): Array<TableData> => {
  return dataMapping
    .reduce((acc: Array<TableData>, record: TableDataMapping) => {
      if (typeof data[record.field] !== "undefined") {
        let transformedRecord = {value: data[record.field] || '-', label: record.label};

        if (typeof record.transformer === "function") {
          transformedRecord = record.transformer(transformedRecord);
        }

        acc.push(transformedRecord);
      }

      return acc;
    }, []);
}
