import { Component } from '@angular/core';

@Component({
  selector: 'error-page-auth-failure',
  standalone: true,
  imports: [],
  templateUrl: './auth-failure.component.html',
  styleUrl: './auth-failure.component.css'
})
export class AuthFailureComponent {

}
