import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { CacheService } from './services/cache.service';
import { FrontService } from './services/front.service';
import { AuthService } from './services/auth.service';
import { NavbarService } from './services/navbar.service';
import { DataService } from './services/data.service';
import { ContactsService } from './services/contacts.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;
  private _caseSubscription: Subscription;
  private _contextSubscription: Subscription;
  private _messagesSubscription: Subscription;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  title = 'fis-plugin';
  indexPage = '/contact';
  casesListPage = '/cases/list';

  public get route(): any {
    return this.router.url;
  }

  constructor (
    private router: Router,
    private loaderService: LoaderService,
    private cacheService: CacheService,
    private frontService: FrontService,
    private ngZone: NgZone,
    private authService: AuthService,
    private navbarService: NavbarService,
    private dataService: DataService,
    private contactsService: ContactsService,
  ) {
    this._subscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loaderService.loadingStart();
        }

        if (event instanceof NavigationEnd) {
          this.navbarService.setActive([this.router.url]);
          this.loaderService.loadingEnd();

          if (this.authService.secret === null) {
            this.navigate('/auth-failure');
          }
        }
      });

    this.frontService.contextChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.dataService.dataLoaded && !this.dataService.dataLoading) {
          this.dataService.loadAllData();
        }
      });

    this.contactsService.selectedContactId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.router.url !== this.indexPage) {
          this.navigate(this.indexPage);
        }
      });

    this._contextSubscription = this.frontService.context$
      .subscribe(() => {
        if (this.frontService.contextType !== 'singleConversation') {
          this.navigate('/no-context');
        }

        if (this.frontService.contextType === 'singleConversation' && this.router.url === '/no-context') {
          this.navigate(this.indexPage);
        }
      });

    this._caseSubscription = this.cacheService.sugarCaseId$
      .subscribe(() => {
        if (this.frontService.contextType === 'singleConversation') {
          this.navigate(this.indexPage);
        }
      });

    this._messagesSubscription = this.frontService.messages$
      .subscribe(() => {
        if (this.frontService.contextType === 'singleConversation') {
          this.navigate(this.indexPage);
        }
      });
  }

  navigate(url: string) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  ngOnInit(): void {
    initFlowbite();
    this.dataService.loadAllData();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._caseSubscription.unsubscribe();
    this._contextSubscription.unsubscribe();
    this._messagesSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
