<form>
  <table class="w-full" [cellPadding]="4">
    <tr>
      <th>
        <label for="name" class="block w-40 text-sm font-medium text-[#ababab] text-left">Account Name:</label>
      </th>
      <td>
        <div class="text-red-600 text-sm">
          <div *ngIf="nameValue.errors?.['required']">
            This field is required.
          </div>
        </div>
        <input
          required
          pInputText
          type="text"
          name="name"
          [(ngModel)]="name"
          variant="filled"
          class="w-full"
          [ngClass]="{'border-[1px] border-red-500': nameValue.invalid}"
          #nameValue="ngModel"/>
      </td>
    </tr>

    <tr>
      <th class="w-40">
        <label for="primary_email" class="block w-40 text-sm font-medium text-[#ababab] text-left">Email:</label>
      </th>
      <td>
        <div class="text-red-600 text-sm">
          <div *ngIf="primaryEmailValue.errors?.['required']">
            This field is required.
          </div>
          <div *ngIf="primaryEmailValue.errors?.['email']">
            This must be a valid email.
          </div>
        </div>
        <input
          required
          email
          pInputText
          class="w-full"
          name="primary_email"
          [(ngModel)]="primary_email"
          name="primary_email"
          variant="filled"
          placeholder="Email address"
          [ngClass]="{'border-[1px] border-red-500': primaryEmailValue.invalid}"
          #primaryEmailValue="ngModel"/>
      </td>
    </tr>

    <tr>
      <th class="w-40">
        <label for="phoneOffice" class="block w-40 text-sm font-medium text-[#ababab] text-left">Office Phone:</label>
      </th>
      <td>
        <input class="w-full" name="phone_office" [(ngModel)]="phone_office"  variant="filled" placeholder="+44 117 000 0000" pInputText />
      </td>
    </tr>

    <tr>
      <th>
        <label for="annual_spend" class="block w-40 text-sm font-medium text-[#ababab] text-left">Value:</label>
      </th>
      <td>
        <input
          required
          pInputText
          type="text"
          name="annual_spend"
          placeholder="0"
          [(ngModel)]="annual_spend"
          variant="filled"
          class="w-full" />
      </td>
    </tr>

    <tr>
      <th>
        <label for="name" class="block w-40 text-sm font-medium text-[#ababab] text-left">Account support team:</label>
      </th>
      <td>
        <p-dropdown 
          [options]="supportTeamEnums" 
          [(ngModel)]="account_support_team"
          name="account_support_team"
          placeholder="Select Team"
          styleClass="w-full"
          variant="filled"
          [virtualScroll]="true"
          [virtualScrollItemSize]="38" />
      </td>
    </tr>

    <tr>
      <th class="w-40 text-left">
        <label class="block text-sm font-medium text-[#ababab] w-full">Notes:</label>
      </th>
      <td>
        <textarea
          required
          minlength="3"
          variant="filled"
          rows="5"
          cols="30" 
          pInputTextarea 
          [autoResize]="true"
          [(ngModel)]="description"
          name="description">
        </textarea>
      </td>
    </tr>
  </table>
</form>
