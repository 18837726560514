import NavbarLink from '../common/interfaces/navbar-link';
import NavigationItem from '../common/interfaces/navigation-item';
import { EventEmitter, Injectable } from '@angular/core';
import { CasesService } from './cases.service';
import { Subject } from 'rxjs';
import { nanoid } from 'nanoid'
import { Role } from '../common/enums/role';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private _navigationData: NavigationItem[] = [];

  private navigationSubject = new Subject<NavigationItem[]>();
  public navigationData$ = this.navigationSubject.asObservable();

  public get navigationData(): NavigationItem[] {
    return this._navigationData;
  }

  private set navigationData(value: NavigationItem[]) {
    this._navigationData = value;
    this.navigationSubject.next(value);
  }

  private _navbarLinks: Array<NavbarLink> = [];
  private _activeRoute: Array<string> = [];

  public navigationChanged$: EventEmitter<void> = new EventEmitter<void>();
  protected casesCount$: EventEmitter<number> = new EventEmitter<number>();

  public get casesCount(): number {
    return this.casesService.caseCountData?.zendeskCases || 0;
  }

  public get navbarLinks(): Array<NavbarLink> {
    return this._navbarLinks;
  }

  private set activeRoute(url: Array<string>) {
    this._activeRoute = url;
  }

  public get activeRoute(): Array<string> {
    return this._activeRoute;
  }

  constructor( private casesService: CasesService ) { }

  public addNavigationItem(url: string, label: string, urlTriggers: string[], roles: Role[] = []) {
    this.navigationData = [...this.navigationData, { id: nanoid(), url, label, urlTriggers, roles, activity: false, disabled: false }];
  }

  public setNavigationItemActivity(url: string, value: boolean) {
    this.navigationData = this.navigationData
      .map((item: NavigationItem) => {
        if (item.url !== url) {
          return item;
        }

        return {...item, activity: value};
      });
  }

  public setNavigationItemDisabled(url: string, value: boolean) {
    this.navigationData = this.navigationData
      .map((item: NavigationItem) => {
        if (item.url !== url) {
          return item;
        }

        return {...item, disabled: value};
      });
  }
}
