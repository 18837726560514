import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SpinnerComponent } from '../../../../../components/spinner/spinner.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import ContactFormData from '../../../../../common/interfaces/contact-form-schema';
import { phosphorCheckCircleFill, phosphorXCircleFill } from '@ng-icons/phosphor-icons/fill';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { CustomValidators } from '../../../../../common/validators/custom-validators';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AutocompleteEvent } from '../../../../../common/interfaces/autocomplete-event';
import { ApiService } from '../../../../../services/api.service';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'form-contact-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SpinnerComponent,
    ReactiveFormsModule,
    NgIconComponent,
    AutoCompleteModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    DividerModule,
  ],
  viewProviders: [provideIcons({
    phosphorCheckCircleFill,
    phosphorXCircleFill,
  })],
  templateUrl: './form-contact-create.component.html',
  styleUrl: './form-contact-create.component.css'
})
export class FormContactCreateComponent implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() processing: boolean = false;
  @Input() contactTypes: any = null;
  @Input() data!: ContactFormData;

  public contactTypeOptions: { value: string, label: string }[] = [
    { value: '', label: 'Choose contact type' },
    { value: 'Influencer', label: 'Influencer' },
    { value: 'Booker', label: 'Booker' },
    { value: 'Guest', label: 'Guest' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Unqualified', label: 'Unconfirmed' },
    { value: 'Notrelevent', label: 'Not Relevant' },
    { value: 'Nolongerworksthere', label: 'No longer works there' },
  ];

  public contactForm = new FormGroup({
    firstName:                  new FormControl('', [Validators.required]),
    lastName:                   new FormControl('', [Validators.required]),
    primaryEmail:               new FormControl('', [Validators.required, Validators.email]),
    secondaryEmail:             new FormControl('', [Validators.email]),
    workPhoneNumber:            new FormControl('', [CustomValidators.phoneNumber]),
    mobilePhoneNumber:          new FormControl('', [CustomValidators.phoneNumber]),
    mobileSecondaryPhoneNumber: new FormControl('', [CustomValidators.phoneNumber]),
    account:                    new FormControl('', [Validators.required]),
    contactType:                new FormControl('', [Validators.required, Validators.minLength(1)]),
  });

  get firstName() {
    return this.contactForm.get('firstName');
  }

  get lastName() {
    return this.contactForm.get('lastName');
  }

  get primaryEmail() {
    return this.contactForm.get('primaryEmail');
  }

  get secondaryEmail() {
    return this.contactForm.get('secondaryEmail');
  }

  get workPhoneNumber() {
    return this.contactForm.get('workPhoneNumber');
  }

  get mobilePhoneNumber() {
    return this.contactForm.get('mobilePhoneNumber');
  }

  get mobileSecondaryPhoneNumber() {
    return this.contactForm.get('mobileSecondaryPhoneNumber');
  }

  get account() {
    return this.contactForm.get('account');
  }

  get contactType() {
    return this.contactForm.get('contactType');
  }

  public inputAccount: any = {
    list: [],
    selected: null,
    hasMore: false,
    search: (event: AutocompleteEvent) => {
      const { query = '' } = event;

      this.apiService.typeaheadAccountByName(query)
        .subscribe((response: any) => {
          const { hasMore = false, results = [] } = response;
          this.inputAccount.list = results;
          this.inputAccount.hasMore = hasMore;
        });
    }
  };

  public get isAccountValid(): boolean {
    return (typeof this.account?.value === "object");
  }

  public get isContactTypeValid(): boolean {
    const currentValue = this.contactType?.value;
    if (currentValue && typeof currentValue === "object") {
      const innerValue = 'value' in currentValue ? currentValue['value'] : '';
      return (innerValue.length > 0);
    }

    return false;
  }
  
  public isFormValid(): boolean {
    return this.contactForm.valid && this.isAccountValid;
  }

  constructor (private apiService: ApiService) {}

  ngOnInit(): void {
    const {
      firstName = '',
      lastName = '',
      primaryEmail = '',
      secondaryEmail = '',
      workPhoneNumber = '',
      mobilePhoneNumber = '',
      mobileSecondaryPhoneNumber = '',
      account = '',
      contactType = '',
    } = this.data;

    this.firstName?.setValue(firstName);
    this.lastName?.setValue(lastName);
    this.primaryEmail?.setValue(primaryEmail);
    this.secondaryEmail?.setValue(secondaryEmail);
    this.workPhoneNumber?.setValue(workPhoneNumber);
    this.mobilePhoneNumber?.setValue(mobilePhoneNumber);
    this.mobileSecondaryPhoneNumber?.setValue(mobileSecondaryPhoneNumber);
    this.account?.setValue(account);
    this.contactType?.setValue(contactType);
  }

  onSubmitHandler() {
    if (!this.isFormValid && !this.contactForm.dirty) {
      this.contactForm.markAsDirty();

      Object.keys(this.contactForm.controls).forEach(key => {
        const control = this.contactForm.get(key);
        if (control instanceof FormControl) {
          control.markAsDirty();
        }
      });
    }

    if (this.isFormValid()) {
      this.onSubmit.emit(this.contactForm.value);
    }
  }
}
