<ng-container *ngIf="!loading">
  <ng-container *ngIf="!currentCase">

    <div class="p-4 text-sm text-center text-gray-600 bg-[#ffdfdf] flex flex-row items-center justify-center" role="alert">
      <span class="flex me-2">
        <ng-icon name="phosphorWarningDuotone" size="16"></ng-icon>
      </span>
      There is no case assigned to this conversation.
    </div>

    <div class="absolute w-full text-center text-gray-300 top-[50%] translate-y-[-50%]">
      <ng-content *ngIf="otherOpenCases === 0 && contactId">
        <ng-content *ngTemplateOutlet="create" />
      </ng-content>
      
      <ng-content *ngIf="otherOpenCases > 0 && contactId">
        <ng-content *ngTemplateOutlet="showOrCreate" />
      </ng-content>

      <ng-content *ngIf="!contactId">
        <div class="mb-5">
          (Contact not found)
        </div>

        <ng-content *ngTemplateOutlet="createCaseButton" />
      </ng-content>
    </div>

  </ng-container>

  <ng-container *ngIf="currentCase">
    <div class="p-5">
      <app-cases-card [data]="currentCase"></app-cases-card>
    </div>

    <ng-container *ngTemplateOutlet="openCasesCount" />

  </ng-container>
</ng-container>

<ng-template #create>
  <div class="mb-5">
    No other cases found.
  </div>
  <ng-content *ngTemplateOutlet="createCaseButton" />
</ng-template>

<ng-template #showOrCreate>
  <div class="absolute w-full text-center text-gray-500 top-[50%] translate-y-[-50%]">
    <p-button
      (onClick)="navigateToCasesList()"
      label="Show contact cases"
      [rounded]="true"
      severity="secondary" />
      
    <div class="mx-20 font-bold">
      <p-divider layout="horizontal" styleClass="flex md:hidden" [align]="'center'">
        OR
      </p-divider>
    </div>

    <ng-content *ngTemplateOutlet="createCaseButton" />
  </div>
</ng-template>

<ng-template #createCaseButton>
  <p-button
    pRipple
    (onClick)="createSugarCase()"
    [disabled]="caseCreationStatus >= 2"
    className="inline-flex items-center"
    label="Create new case"
    [rounded]="true">
    <p-progressSpinner *ngIf="caseCreationStatus === 2"
      class="mr-2 flex"
      styleClass="w-[1.2rem] h-[1.2rem]" 
      strokeWidth="8" 
      animationDuration=".5s" />
  </p-button>
</ng-template>

<ng-template #openCasesCount>
  <div class="inline-flex items-center w-full px-5">
    <div class="text-[#3e3e40] text-normal font-light">
      This contact has <span class="font-semibold">{{otherOpenCases}}</span> other open cases
    </div>
    <div class="ml-auto">
      <custom-button (click)="navigateToCasesList()">Reassign this conversation</custom-button>
    </div>
  </div>
</ng-template>