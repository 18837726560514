import { Component, EventEmitter } from '@angular/core';
import { AccountFormComponent } from "../components/account-form/account-form.component";
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { ToastService } from '../../../../services/toast.service';
import { ButtonModule } from 'primeng/button';
import { SpinnerComponent } from '../../../../components/spinner/spinner.component';
import { ContactsService } from '../../../../services/contacts.service';
import { concatMap, finalize, map, of, switchMap } from 'rxjs';
import { FrontService } from '../../../../services/front.service';

@Component({
  selector: 'app-account-create',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AccountFormComponent,
    ButtonModule,
    SpinnerComponent,
  ],
  templateUrl: './account-create.component.html',
  styleUrl: './account-create.component.css'
})
export class AccountCreateComponent {
  processing: boolean = false;
  isFormValid: boolean = true;

  public submitForm = new EventEmitter<void>();

  get contactId(): string | null {
    return this.contactsService.selectedSugarContactId;
  }

  get conversationId(): string {
    return this.frontService.conversation?.id ?? '';
  }

  constructor (
    private apiService: ApiService,
    private contactsService: ContactsService,
    private frontService: FrontService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  onFormSubmitHandler(data: any) {
    this.processing = true;

    this.apiService.createAccount(data).pipe(
      switchMap((response: any) => {
        const {
          success = false,
          info: {
            subject = 'Error',
            message = '(message missing)',
          } = {},
          data: { id: newAccountId = null } = {},
        } = response;

        if (this.contactId && newAccountId) {
          return this.apiService.setContactAccount(this.contactId, newAccountId).pipe(
            switchMap(() => this.apiService.addConversationAsEmails(this.conversationId, 'Accounts', newAccountId)),
            map(() => ({ success, subject, message }))
          );
        }

        return of(({ success: false, subject, message }))
      }),
      finalize(() => this.processing = false),
    ).subscribe(({ success, subject, message }) => {
      this.toastService.add({
        severity: success ? 'success' : 'error',
        summary: subject,
        detail: message,
      });

      if (success) {
        this.router.navigateByUrl('/sales/details');
      }
    });
  }

  createButtonClickHandler() {
    this.submitForm.emit();
  }
}
