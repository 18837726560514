<div *ngIf="!loading" class="p-5">
  <table class="w-full">
    <tr>
      <th class="align-text-top p-2">Tags:</th>
      <td class="p-2">
        <ng-container *ngFor="let tag of tags">
          <span
            class="text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded mr-1"
            [ngClass]="{'bg-blue-100': tag.ownerId !== 'company', 'bg-green-100': tag.ownerId === 'company'}">
            {{tag.name}} {{ (tag.ownerId === 'company' ? '(' + tag.ownerId + ')' : '') }}
          </span>
          <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded mr-5">
            {{tag.id}}
          </span>
        </ng-container>
      </td>
    </tr>
    <tr>
      <th class="align-text-top p-2">Inboxes:</th>
      <td class="p-2">
        <ng-container *ngFor="let inbox of inboxes">
          <span class="text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded mr-1 bg-blue-100">
            {{inbox.name}}
          </span>
          <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded mr-5">
            {{inbox.id}}
          </span>
        </ng-container>
      </td>
    </tr>
  </table>
</div>
