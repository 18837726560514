import { Exclude, Expose, Transform } from "class-transformer";
import { SugarCaseStatus } from "../../enums/sugar-case-status";

@Exclude()
export default class SugarCase {
  @Expose() 
  id!: string;

  @Expose()
  name!: string;

  @Expose()
  status!: string;

  @Expose()
  description!: string;

  @Expose()
  created_by!: string;
  
  @Expose()
  created_by_name!: string;
  
  @Expose()
  date_entered!: string;
  
  @Expose()
  date_modified!: string;

  @Expose()
  next_action_date!: string;
  
  @Expose()
  modified_user_id!: string;
  
  @Expose()
  modified_by_name!: string;
  
  @Expose()
  apartment!: string;

  @Expose()
  assigned_to!: string;

  @Expose()
  second_assaignee!: string;

  @Expose()
  teams: Array<any> = [];

  @Expose()
  deleted!: boolean;
  
  @Expose()
  case_number!: number;

  @Expose()
  category!: string;
  category_label!: string;

  @Expose()
  subcategory!: string;

  subcategory_label!: string;

  @Expose()
  is_zendesk_case!: boolean;

  @Expose()
  conversation_link!: string;

  @Expose()
  conversation_id!: string | null;

  @Expose()
  case_summary!: string;

  get is_open(): boolean {
    return (this.status === SugarCaseStatus.New || this.status === SugarCaseStatus.Pending);
  }
}