<div *ngIf="sugarContact" class="bg-[#fefefe] text-normal pt-3 px-5 pb-8 border-b-[1px] border-[#ababab]">
  <table class="w-full">
    <tr *ngFor="let rowData of contactDetailsTableData" class="border-b-[1px] border-[#e2e5e8]">
      <th class="text-left align-text-top w-[35%] font-semibold text-[#ababab] py-1">{{rowData.label}}:</th>
      <td class="text-left text-[#3e3e40] py-1">{{rowData.value}}</td>
    </tr>
    <tr class="border-b-[1px] border-[#e2e5e8]">
      <th class="text-left align-text-top w-[35%] font-semibold text-[#ababab] py-1">Account note:</th>
      <td class="text-left text-[#3e3e40] py-1">
        <app-text-expander [text]="basicContactData.account_note || '-'" />
      </td>
    </tr>
  </table>
</div>

<div class="bg-[#f5f5f5] text-normal pt-5 px-5 pb-8">
  <table class="w-full">
    <tr *ngFor="let rowData of extraDetailsTableData" class="border-b-[1px] border-[#e2e5e8]">
      <th class="text-left align-text-top w-[35%] font-semibold text-[#ababab] py-1">{{rowData.label}}:</th>
      <td class="text-left text-[#3e3e40] py-1">
        {{rowData.value}}
      </td>
    </tr>
    <tr class="border-b-[1px] border-[#e2e5e8]">
      <th class="text-left align-text-top w-[35%] font-semibold text-[#ababab] py-1">Guest contact:</th>
      <td class="text-left text-[#3e3e40] py-1">
        {{ (basicContactData.guest_contact ? getEnumLabel(basicContactData.guest_contact) : '-') }}
      </td>
    </tr>
    <tr class="border-b-[1px] border-[#e2e5e8]">
      <th class="text-left align-text-top w-[35%] font-semibold text-[#ababab] py-1">Guest note:</th>
      <td class="text-left text-[#3e3e40] py-1">
        <app-text-expander [text]="basicContactData.guest_note" />
      </td>
    </tr>
  </table>
</div>

<div class="w-full flex p-6">
  <button type="button"
    (click)="viewInSugar(basicContactData.id)"
    class="py-2.5 px-5 me-2 mb-2 text-normal font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 mr-auto">
    View in SugarCRM
  </button>

  <button type="button"
    (click)="enterEditMode()"
    class="py-2.5 px-5 me-2 mb-2 text-normal font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 ml-auto">
    Update Details
  </button>
</div>
