"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationDefaultError = exports.ApplicationError = void 0;
var ApplicationError = /** @class */function (_super) {
  __extends(ApplicationError, _super);
  function ApplicationError(message) {
    var _newTarget = this.constructor;
    // "Error" breaks the prototype chain here.
    var _this = _super.call(this, message) || this;
    // We need to restore it.
    var actualPrototype = _newTarget.prototype;
    Object.setPrototypeOf(_this, actualPrototype);
    return _this;
  }
  return ApplicationError;
}(Error);
exports.ApplicationError = ApplicationError;
var ApplicationDefaultError = /** @class */function (_super) {
  __extends(ApplicationDefaultError, _super);
  function ApplicationDefaultError(error) {
    var _this = _super.call(this, 'Something went wrong.') || this;
    _this.originalError = error;
    return _this;
  }
  return ApplicationDefaultError;
}(ApplicationError);
exports.ApplicationDefaultError = ApplicationDefaultError;