import { ResolveFn } from '@angular/router';
import { ApiService } from '../services/api.service';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { ContactsService } from '../services/contacts.service';
import SugarContact from '../common/models/sugar/sugar-contact';

export const casesResolver: ResolveFn<boolean> = (route, state) => {
  const apiService = inject(ApiService);
  const contactsService = inject(ContactsService);

  const sugarContactIds = contactsService.sugarContacts
    .map((sugarContact: SugarContact) => sugarContact.id);

  if (sugarContactIds.length === 0) {
    return of(null);
  }

  return apiService.getCases(sugarContactIds);
};
