<ng-container *ngIf="!isPageLoading && !contactsLoading">
  <div *ngIf="sugarContacts.length === 0 && !hasPartialMatches" class="flex p-4 text-normal text-[#ee1f1f] bg-[#ffdfdf]" role="alert">
    <svg class="flex-shrink-0 inline w-5 h-5 me-3 my-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm7.7-3.7a1 1 0 0 0-1.4 1.4l2.3 2.3-2.3 2.3a1 1 0 1 0 1.4 1.4l2.3-2.3 2.3 2.3a1 1 0 0 0 1.4-1.4L13.4 12l2.3-2.3a1 1 0 0 0-1.4-1.4L12 10.6 9.7 8.3Z" clip-rule="evenodd"/>
    </svg>

    <div class="text-[#3e3e40]">
      <span class="font-light">Contact not matched</span>
    </div>
  </div>

  <div *ngIf="sugarContacts.length === 0 && hasPartialMatches" class="flex p-4 text-normal text-[#ee9936] bg-[#fff2e3]" role="alert">
    <svg class="flex-shrink-0 inline w-5 h-5 me-3 my-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clip-rule="evenodd"/>
    </svg>

    <div class="text-[#3e3e40]">
      <span class="font-light">Name matches contact in SugarCRM</span>
    </div>
  </div>

  <app-contact-details *ngIf="selectedSugarContact && !editMode" [sugarContact]="selectedSugarContact" (editModeClick)="editMode = true" />
  <app-contact-update *ngIf="selectedSugarContact && editMode" [contact]="selectedSugarContact" (onReturn)="editMode = false" (onSave)="handleOnContactSave()" />
  <app-contact-create *ngIf="!selectedSugarContact" [contact]="selectedContact" (onSave)="handleOnContactSave()" />
</ng-container>

<ng-container *ngIf="isPageLoading || contactsLoading">
  <app-loader />
</ng-container>
