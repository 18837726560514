import { Injectable } from '@angular/core';
import { FrontService } from './front.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor (private frontService: FrontService) { }

  getName(): string {
    return this.frontService.context?.teammate.name || 'anonymous';
  }
}
