import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CacheService } from '../../../../services/cache.service';
import { TableService } from '../../../../services/table.service';
import TableData from '../../../../common/interfaces/table-data';
import { ContactCreateComponent } from '../contact-create/contact-create.component';
import { FrontService } from '../../../../services/front.service';
import { TextExpanderComponent } from '../../../../components/text-expander/text-expander.component';
import { environment } from '../../../../../environments/environment';
import { ContactUpdateComponent } from '../contact-update/contact-update.component';
import { ContactSelectorComponent } from '../../../../components/contact-selector/contact-selector.component';
import SugarContact from '../../../../common/models/sugar/sugar-contact';
import Front from '@frontapp/plugin-sdk';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-contact-details',
  standalone: true,
  imports: [
    CommonModule,
    ContactCreateComponent,
    ContactUpdateComponent,
    TextExpanderComponent,
    ContactSelectorComponent,
  ],
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.css'
})
export class ContactDetailsComponent {
  @Input()
  public sugarContact!: SugarContact;

  @Output()
  public editModeClick: EventEmitter<void> = new EventEmitter<void>();

  public get sugarUrl(): string {
    return environment.sugarUrl;
  }

  public get basicContactData() {
    let data = {
      id: this.sugarContact?.id,
      account_note: this.sugarContact?.account?.account_note || '',
      guest_note: this.sugarContact?.guest_note,
      guest_contact: this.sugarContact?.guest_contact,
    };

    return data;
  }

  public get contactDetailsTableData(): Array<TableData> {
    if (!this.sugarContact) return [];

    return this.tableService.transformData(this.sugarContact, [
      {field: 'first_name', label: 'First name'},
      {field: 'last_name', label: 'Last name'},
      {field: 'contact_type', label: 'Contact type'},
      {field: 'primary_email', label: 'Primary email'},
      {field: 'phone_work', label: 'Best contact number'},
      {field: 'phone_mobile', label: 'Alternative contact number'},
      {field: 'phone_mobile_2', label: 'Second alternative contact number'},
      {field: 'company_name', label: 'Company'},
      {field: 'account_support_team', label: 'Account Support Team'},
      {field: 'high_visibility', label: 'High Visibility'}
    ]);
  }

  public get extraDetailsTableData(): Array<TableData> {
    if (!this.sugarContact) return [];

    this.sugarContact['account_support_team'] = (this.sugarContact?.company_name?.toLowerCase() == 'individual' ?
      this.sugarContact?.account_support_team ?? '' :
      this.sugarContact?.account?.account_support_team ?? ''
    ).split(/(\d+)/).filter(Boolean).join(' ').replace(/^./, match => match.toUpperCase());

    return this.tableService.transformData(this.sugarContact, [
      {field: 'handle_with_care', label: 'Handle with care'},
      {field: 'guest_satisfaction', label: 'Guest satisfaction'},
      {field: 'previous_complaint', label: 'Make a complaint'},
    ]);
  }

  constructor (
    public cacheService: CacheService,
    public frontService: FrontService,
    private tableService: TableService,
    private dataService: DataService,
  ) {}

  getEnumLabel(value: string): string {
    const enumData = this.dataService.guestContactEnums[value];
    return (enumData ? this.dataService.guestContactEnums[value] : '-');
  }

  enterEditMode() {
    this.editModeClick.emit();
  }

  viewInSugar(id: string | null) {
    if (id) {
      Front.openUrl(`${this.sugarUrl}/#Contacts/${id}`);
    }
  }
}
