import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TruncatePipe } from "../../common/pipes/truncate.pipe";

@Component({
    selector: 'app-text-expander',
    standalone: true,
    templateUrl: './text-expander.component.html',
    styleUrl: './text-expander.component.css',
    imports: [
        CommonModule,
        TruncatePipe
    ]
})
export class TextExpanderComponent {
  private _expanded: boolean = false;

  @Input()
  public text: string = '';

  public get textLength(): number {
    return this.text.split(' ').length;
  }

  @Input()
  public truncateLength: number = 50;

  public get expanded(): boolean {
    return this._expanded;
  }

  readMore() {
    this._expanded = true;
  }

  readLess() {
    this._expanded = false;
  }
}
