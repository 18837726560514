import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _sercret: string | null = null;

  public get secret(): string | null {
    return this._sercret;
  }

  private get window(): (Window & typeof globalThis) | null {
    return document.defaultView;
  }

  constructor() {
    const url = this.window?.location?.href ?? null;
    if (url !== null) {
      this._sercret = new URL(url).searchParams.get('auth_secret');
    }
  }
}
