import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ContactSelectPillComponent } from '../contact-select-pill/contact-select-pill.component';
import { CommonModule } from '@angular/common';
import ContactPill from '../../common/interfaces/contact';

@Component({
  selector: 'contact-selector',
  standalone: true,
  imports: [
    CommonModule,
    ContactSelectPillComponent
  ],
  templateUrl: './contact-selector.component.html',
  styleUrl: './contact-selector.component.css'
})
export class ContactSelectorComponent {
  @Input()
  public selectedContactId: string | null = null;

  @Input()
  public contactPills: ContactPill[] = [];

  @Output()
  public onContactSelect: EventEmitter<string> = new EventEmitter<string>();

  public isDown: boolean = false;
  public initSliderX: number = 0;
  public initMouseX: number = 0;
  public mouseX: number = 0;
  public dragging: boolean = false;

  public get offsetMouseX(): number {
    return this.mouseX - this.initMouseX;
  }

  @ViewChild('pill_slider') _slider: any;
  public get slider() {
    return this._slider?.nativeElement;
  }

  public onSelectHandler(contactId: string) {
    this.onContactSelect.emit(contactId);
  }

  dragStart(event: any) {
    this.isDown = true;
    this.initMouseX = event.x;
    this.initSliderX = this.slider.scrollLeft;
  }

  dragEnd() {
    if (this.isDown) {
      this.isDown = false;

      setTimeout(() => {
        this.dragging = false;
      }, 50)
    }
  }

  dragMove(event: any) {
    if (!this.isDown) return;
    this.mouseX = event.x;

    const diffPosX = (this.mouseX - this.initMouseX);
    if (diffPosX > 25 || diffPosX > -25) {
      this.dragging = true;
    }

    this.slider.scrollLeft = (this.initSliderX - this.offsetMouseX);
  }
}
