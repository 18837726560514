import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormContactUpdateComponent } from '../forms/form-contact-update/form-contact-update.component';
import { CommonModule } from '@angular/common';
import { LoaderService } from '../../../../services/loader.service';
import { ApiService } from '../../../../services/api.service';
import { ToastService } from '../../../../services/toast.service';
import ContactFormSchema from '../../../../common/interfaces/contact-form-schema';
import SugarContact from '../../../../common/models/sugar/sugar-contact';
import { parsePhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'app-contact-update',
  standalone: true,
  imports: [
    CommonModule,
    FormContactUpdateComponent
  ],
  templateUrl: './contact-update.component.html',
  styleUrl: './contact-update.component.css'
})
export class ContactUpdateComponent {
  @Output()
  onReturn: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSave: EventEmitter<void> = new EventEmitter<void>();

  public processingData: boolean = false;

  @Input()
  public contactTypes: any = null;

  @Input()
  public contact: SugarContact | null = null;

  public get formData(): ContactFormSchema {
    if (!this.contact) return {};

    const {
      first_name: firstName,
      last_name: lastName,
      primary_email: primaryEmail,
      secondary_email: secondaryEmail,
      phone_work: workPhoneNumber,
      phone_mobile: mobilePhoneNumber,
      phone_mobile_2: mobileSecondaryPhoneNumber,
    } = this.contact;

    return { firstName, lastName, primaryEmail, secondaryEmail, workPhoneNumber, mobilePhoneNumber, mobileSecondaryPhoneNumber };
  }

  get loading(): boolean {
    return this.loaderService.loading;
  }

  constructor (
    private readonly apiService: ApiService,
    private readonly loaderService: LoaderService,
    private readonly toastService: ToastService,
  ) { }

  returnAction() {
    this.onReturn.emit();
  }

  reformatPhoneNumber(phoneNumber: string) {
    return (phoneNumber.length > 0 ? parsePhoneNumber(phoneNumber)?.formatInternational() : '');
  }

  onSubmit(data: any) {
    if (!this.contact) return;

    const formData = {
      first_name: data?.firstName || '',
      last_name: data?.lastName || '',
      primary_email: data?.primaryEmail || '',
      secondary_email: data?.secondaryEmail || '',
      phone_work: this.reformatPhoneNumber(data?.workPhoneNumber || ''),
      phone_mobile: this.reformatPhoneNumber(data?.mobilePhoneNumber || ''),
      phone_mobile_2: this.reformatPhoneNumber(data?.mobileSecondaryPhoneNumber || ''),
      type: data?.contactType || '',
    };

    this.processingData = true;
    this.apiService.updateContact(this.contact.id, formData)
      .subscribe((response: any) => {
        const { success } = response;

        if (success) {
          this.toastService.addToast('Contact has been updated.', 'success');
          this.processingData = false;
          this.onSave.emit();
        } else {
          console.error('[Contact Update] ', response);
          this.toastService.addToast('Unexpected error occured.', 'error');
          this.processingData = false;
        }
      });
  }
}
