import { Component, OnDestroy } from '@angular/core';
import SugarContact from '../../../common/models/sugar/sugar-contact';
import { ContactsService } from '../../../services/contacts.service';
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from '../../../services/loader.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import toClass from '../../../common/helpers/to-class';
import SugarOpportunity from '../../../common/models/sugar/sugar-opportunity';

@Component({
  selector: 'page-sales',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.css'
})
export class SalesComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public editingAccount: boolean = false;
  public editingOpportunity: boolean = false;

  public selectedElement: string | null = null;

  public get selectedSugarContact(): SugarContact | null {
    return this.contactsService.selectedSugarContact;
  }

  public get pageLoading(): boolean {
    return this.loaderService.loading;
  }

  private _sugarOpportunity: SugarOpportunity | null = null;
  public set sugarOpportunity(value: SugarOpportunity | null) {
    this._sugarOpportunity = value;
  }

  public get sugarOpportunity(): SugarOpportunity | null {
    return this._sugarOpportunity;
  }

  constructor (
    private contactsService: ContactsService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(({opportunityData}) => {
        this.sugarOpportunity = toClass(SugarOpportunity, opportunityData?.data ?? null);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  selectElement(name: string, value: boolean) {
    if (value) {
      this.selectedElement = name;
    }

    this.selectedElement = null;
  }
}
