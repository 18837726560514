<div class="text-left grid grid-cols-1 gap-3 pb-5">
  <div class="px-5 mt-5">
    <form class="w-full">
      <label for="primaryContact" class="block mb-2 text-lg font-medium text-gray-700">Change primary contact</label>

      <p-dropdown
        name="primary_contact"
        styleClass="w-full"
        [disabled]="!sugarAccount"
        [options]="sugarContacts"
        [(ngModel)]="primarySugarContact"
        [loading]="savingPrimaryContact"
        (onChange)="onPrimaryContactChangeHandler($event)"
        [checkmark]="true"
        dataKey="id"
        optionLabel="name"
        placeholder="Select a contact" />
    </form>
  </div>
  
  <div  class="w-full border-b border-gray-200 my-2"></div>

  <div class="flex flex-col px-5">
    <ng-container *ngIf="!sugarAccount; else accountDetails"></ng-container>
    <ng-container *ngIf="sugarAccount; else accountAdd"></ng-container>
  </div>

  <div class="w-full border-b border-gray-200 my-2"></div>

  <div class="flex flex-col px-5">
    <ng-container *ngIf="!sugarOpportunity; else opportunityDetails"></ng-container>
    <ng-container *ngIf="sugarOpportunity; else opportunityAdd"></ng-container>
  </div>

  <div *ngIf="!sugarOpportunity && sugarAccountId" class="w-full border-b border-gray-200 my-2"></div>
</div>

<!-- accountDetails Template  -->
<ng-template #accountDetails>
  <sales-account-details
    *ngIf="sugarAccount"
    (syncClick)="onAccountSyncHandler()"
    [inSync]="accountSyncing"
    (unassignClick)="onAccountUnassign()"
    [unassignInProgress]="unassignAccountInProgress"
    [sugarAccount]="sugarAccount"
  />
</ng-template>

<!-- accountAdd Template -->
<ng-template #accountAdd>
  <span class="mb-2 text-xl font-medium text-gray-700">Add email to Account</span>

  <button class="text-[#3e3e40] bg-white border border-[#e2e5e8] hover:bg-gray-100 text-sm font-semibold rounded-2xl py-2 mb-2.5 shadow-sm" [routerLink]="['/sales/add-to-account', sugarAccountId]">
    Add to existing account
  </button>
  <button class="text-white bg-[#367fee] hover:bg-blue-600 text-sm font-semibold rounded-2xl py-2 mb-2.5 shadow-sm" [routerLink]="'/sales/account-create'">
    Create new account and add email
  </button>
</ng-template>

<!-- opportunityDetails Template -->
<ng-template #opportunityDetails>
  <sales-opportunity-details
    *ngIf="sugarOpportunity"
    (syncClick)="onOpportunitySyncHandler()"
    [inSync]="opportunitySyncing"
    (unassignClick)="onOpportunityUnassign()"
    [unassignInProgress]="unassignOpportunityInProgress"
    [sugarOpportunity]="sugarOpportunity"
  />
</ng-template>

<!-- opportunityAdd Template -->
<ng-template #opportunityAdd>
  <span class="mb-2 text-lg font-medium text-gray-700">Add email to Opportunity</span>

  <button class="text-[#3e3e40] bg-white border border-[#e2e5e8] hover:bg-gray-100 text-sm font-semibold rounded-2xl py-2 mb-2.5 shadow-sm" [routerLink]="['/sales/add-to-opportunity', sugarAccountId]">
    Add to existing opportunity
  </button>
  <button class="text-white bg-[#367fee] hover:bg-blue-600 text-sm font-semibold rounded-2xl py-2 mb-2.5 shadow-sm" [routerLink]="'/sales/opportunity-create'">
    Create new opportunity and add email
  </button>
</ng-template>