import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';
import { CommonModule } from '@angular/common';
import { ContactsService } from '../../../services/contacts.service';
import { Subject, takeUntil } from 'rxjs';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { ContactUpdateComponent } from './contact-update/contact-update.component';
import { ActivatedRoute } from '@angular/router';
import { LoaderComponent } from '../../../components/loader/loader.component';
import { Contact } from '../../../common/models/fis/contact';
import SugarContact from '../../../common/models/sugar/sugar-contact';

@Component({
  selector: 'page-contact',
  standalone: true,
  imports: [
    CommonModule,
    ContactDetailsComponent,
    ContactCreateComponent,
    ContactUpdateComponent,
    LoaderComponent,
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public get isPageLoading(): boolean {
    return this.loaderService.loading;
  }

  public get sugarContactsLoading(): boolean {
    return this.contactsService.sugarContactsLoading;
  }
  
  public get contactsLoading(): boolean {
    return this.contactsService.sugarContactsLoading;
  }
  
  public get contactsLoaded(): boolean {
    return this.contactsService.sugarContactsLoaded;
  }

  public get selectedSugarContact(): SugarContact | null {
    return this.contactsService.selectedSugarContact;
  }
  
  public get selectedContactId(): string | null {
    return this.contactsService.selectedContactId;
  }

  public get selectedContact(): Contact | null {
    return this.contactsService.selectedContact;
  }
  
  private _editMode: boolean = false;
  public set editMode(value: boolean) {
    this._editMode = value;
    this.changeDetectorRef.detectChanges();
  }
  
  public get editMode(): boolean {
    return this._editMode;
  }

  public get contacts(): Contact[] {
    return this.contactsService.getContacts();
  }

  public get hasPartialMatches(): boolean {
    return this.contactsService.hasPartialMatches;
  }
  
  public get sugarContacts(): SugarContact[] {
    return this.contactsService.sugarContacts;
  }
  
  protected contactsData: any[] = [];
  public contactTypes: any = null;

  constructor (
    private loaderService: LoaderService,
    private contactsService: ContactsService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
  ) {
    //this.loaderService.loadingStart();
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const { contactTypes: { type: contactTypes = null } = {} } = data;
  
        if (contactTypes) {
          this.contactTypes = contactTypes;
        }
      });

    this.contactsService.sugarContactsLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading: boolean) => {
        this.changeDetectorRef.detectChanges();
      });

    this.contactsService.selectedContactId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.editMode = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public handleOnContactSave() {
    this.editMode = false;
    this.contactsService.reloadSugarContacts();
  }
}
