import { Exclude, Expose, Type } from "class-transformer";
import { FrontRecipient } from "./front-recipient";
import Content from "./content";
import { HandleType } from "../enums/handle-type";
import { isPBEmail } from "../helpers/is-pb-email";
@Exclude()
export default class Message {
  @Expose()
  id!: string;

  @Expose()
  @Type(() => FrontRecipient)
  from!: FrontRecipient;

  @Expose()
  @Type(() => FrontRecipient)
  to: Array<FrontRecipient> = [];

  @Expose()
  subject!: string;

  @Expose()
  date!: Date;

  @Expose()
  status!: string;

  @Expose()
  @Type(() => Content)
  content!: Content;

  getRecipients(): FrontRecipient[] {
    return [this.from, ...this.to];
  }

  /**
   * @deprecated
   */
  getParticipantEmails() {
    return this.getParticipants().map((participant: FrontRecipient) => participant.handle);
  }

  getParticipants(): Array<FrontRecipient> {
    const { from: addressee = null, to: recipients = [] } = this;

    const participants = [];
    if (addressee && addressee.type === HandleType.email  && !isPBEmail(addressee.handle)) {
      participants.push(addressee);
    }

    recipients.forEach((recipient: FrontRecipient) => {
      if (recipient.type === HandleType.email && !isPBEmail(recipient.handle)) {
        participants.push(recipient);
      }
    });

    return participants;
  }
}
