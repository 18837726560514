import { Exclude, Expose } from "class-transformer";

@Exclude()
export default class SugarEmail {
  @Expose({ name: 'email_address_id' })
  id!: string;

  @Expose()
  email_address!: string;

  @Expose()
  invalid_email!: boolean;

  @Expose()
  opt_out!: boolean;

  @Expose()
  primary_address!: boolean;

  @Expose()
  reply_to_address!: boolean;
}
