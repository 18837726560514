import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { DataService } from '../../../../../services/data.service';
import { ApiService } from '../../../../../services/api.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { AutocompleteEvent } from '../../../../../common/interfaces/autocomplete-event';
import FormDropdown from '../../../../../common/interfaces/form-dropdown';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'opportunity-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    AutoCompleteModule,
    InputTextareaModule,
  ],
  templateUrl: './opportunity-form.component.html',
  styleUrl: './opportunity-form.component.css'
})
export class OpportunityFormComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() data: any = {};
  @Input() submitForm!: EventEmitter<void>;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('nameValue') nameValue!: NgModel;
  @ViewChild('amountValue') amountValue!: NgModel;
  @ViewChild('dateClosedValue') dateClosedValue!: NgModel;
  @ViewChild('salesStageValue') salesStageValue!: NgModel;
  @ViewChild('nextActionDueValue') nextActionDueValue!: NgModel;
  @ViewChild('opportunityTypeValue') opportunityTypeValue!: NgModel;

  @Output() public invalidForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get isFormValid(): boolean {
    return (
      !!this.nameValue.valid &&
      !!this.amountValue.valid &&
      !!this.dateClosedValue.valid &&
      !!this.salesStageValue.valid &&
      !!this.nextActionDueValue.valid &&
      !!this.opportunityTypeValue.valid
    );
  }

  public inputAssignedUser: any = {
    list: [],
    selected: null,
    hasMore: false,
    search: (event: AutocompleteEvent) => {
      const { query = '' } = event;

      this.apiService.typeaheadUsersByName(query)
        .subscribe((response) => {
          const { hasMore = false, results = [] } = response;
          this.inputAssignedUser.list = results;
          this.inputAssignedUser.hasMore = hasMore;
        })
    }
  };

  public inputDecisionMaker: any = {
    list: [],
    selected: null,
    hasMore: false,
    search: (event: AutocompleteEvent) => {
      const { query = '' } = event;

      this.apiService.typeaheadContactsByName(query)
        .subscribe((response) => {
          const { hasMore = false, results = [] } = response;
          this.inputDecisionMaker.list = results;
          this.inputDecisionMaker.hasMore = hasMore;
        });
    }
  };

  public name: string | undefined;
  public opportunity_type: FormDropdown | undefined;
  public sales_stage: FormDropdown | undefined;
  public amount: number | undefined;
  public globalopportunityvalue: number | undefined;
  public date_closed: Date | undefined;
  public date_next_action_due: Date | undefined;
  public probability: number | undefined;
  public decisionmaker: string | undefined;

  public get date_closed_timestamp(): number {
    const date = (!this.date_closed ? new Date(this.todayDate) : new Date(this.date_closed));
    return (date.getTime() ?? 0) / 1000;
  }

  public get accountTypeEnums(): { value: string, label: string }[] {
    return this.dataService.getEnums('opportunity_type');
  }

  public get salesStageEnums(): { value: string, label: string }[] {
    return this.dataService.getEnums('opportunity_sales_stage');
  }

  public get formData(): any {
    return {
      id: this.data?.id || null,
      name: this.name || '',
      opportunity_type: this.opportunity_type?.value || '',
      date_closed: (this.date_closed instanceof Date ? this.formatDate(this.date_closed) : this.formatDate(this.todayDate)),
      date_closed_timestamp: this.date_closed_timestamp || 0,
      nextactiondue: (this.date_next_action_due instanceof Date ? this.formatDate(this.date_next_action_due) : this.formatDate(this.todayDate)),
      sales_stage: this.sales_stage?.value || '',
      amount: this.amount || '',
      globalopportunityvalue: this.globalopportunityvalue || 0,
      probability: this.probability || '',
      assigned_user_id: this.inputAssignedUser?.selected?.id || '',
      assigned_user_name: this.inputAssignedUser?.selected?.name || '',
      decisionmaker_id: this.inputDecisionMaker?.selected?.id || '',
      decisionmaker_name: this.inputDecisionMaker?.selected?.name || '',
      account_id: this.data.account_id,
      account_name: this.data.account_name,
    };
  }

  public get todayDate() {
    return new Date();
  }

  constructor (
    private dataService: DataService,
    private apiService: ApiService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    const {
      name = '',
      amount = 0,
      globalopportunityvalue = 0,
      date_closed = this.todayDate,
      date_next_action_due = this.todayDate,
      probability = 0,
      sales_stage = '',
      opportunity_type = '',
      assigned_user_id = '',
      assigned_user_name = '',
      decisionmaker_id = '',
      decisionmaker_name = '',
    } = this.data;

    this.name = name;
    this.amount = parseInt(`${amount}`);
    this.globalopportunityvalue = parseFloat(`${globalopportunityvalue}`) || 0;
    this.date_closed = date_closed;
    this.date_next_action_due = date_next_action_due,
    this.probability = probability;
    this.sales_stage = this.salesStageEnums.filter((record) => record.value === sales_stage)[0];
    this.opportunity_type = this.accountTypeEnums.filter((record) => record.value === opportunity_type)[0];
    this.inputAssignedUser.selected = { id: assigned_user_id, name: assigned_user_name };
    this.inputDecisionMaker.selected = { id: decisionmaker_id, name: decisionmaker_name };

    this.submitForm
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isFormValid ? this.submit.emit(this.formData) : this.invalidForm.emit(true);
      });
  }

  ngAfterViewInit(): void {
    [
      this.nameValue,
      this.amountValue,
      this.dateClosedValue,
      this.salesStageValue,
      this.nextActionDueValue,
      this.opportunityTypeValue
    ].forEach((control: NgModel) => {
      control.statusChanges?.pipe(takeUntil(this.destroy$)).subscribe(() => this.validateForm());
    });
  }

  protected formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  validateForm() {
    this.invalidForm.emit(!this.isFormValid);
  }

}
