import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { map, of, Subject, take } from 'rxjs';
import SugarOpportunity from '../../../../common/models/sugar/sugar-opportunity';
import { OpportunityDetailsComponent } from '../components/opportunity-details/opportunity-details.component';
import { AccountDetailsComponent } from '../components/account-details/account-details.component';
import SugarAccount from '../../../../common/models/sugar/sugar-account';
import { ContactsService } from '../../../../services/contacts.service';
import { ApiService } from '../../../../services/api.service';
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { SalesAddToAccountComponent } from '../sales-add-to-account/sales-add-to-account.component';
import { TypeaheadInputComponent } from '../../../../components/typeahead-input/typeahead-input.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import SugarContact from '../../../../common/models/sugar/sugar-contact';
import { ToastService } from '../../../../services/toast.service';
import { FrontService } from '../../../../services/front.service';

@Component({
  selector: 'app-sales-details',
  standalone: true,
  imports: [
    CommonModule,
    AccountDetailsComponent,
    OpportunityDetailsComponent,
    TypeaheadComponent,
    SalesAddToAccountComponent,
    RouterModule,
    TypeaheadInputComponent,
    DropdownModule,
    FormsModule,
  ],
  templateUrl: './sales-details.component.html',
  styleUrl: './sales-details.component.css'
})
export class SalesDetailsComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  // Actions
  public savingPrimaryContact: boolean = false;
  public accountSyncing: boolean = false;
  public opportunitySyncing: boolean = false;
  public unassignAccountInProgress: boolean = false;
  public unassignOpportunityInProgress: boolean = false;
  
  // Sugar Data
  public sugarContacts: SugarContact[] = [];
  public sugarAccounts: SugarAccount[] = [];
  public sugarOpportunity: SugarOpportunity | null = null;
  public selectedSugarAccount: SugarAccount | null = null;
  public sugarAccount: SugarAccount | null = null;
  public primarySugarContact: SugarContact | null = null;
  
  // Other
  public apiTypeahead = (value: string) => (value.length > 0 ? this.apiService.typeaheadAccountByName(value) : of([]));

  public get selectedSugarContactId(): string | null {
    return this.contactsService.selectedSugarContact?.id || null;
  }
  
  public get selectedSugarContact(): SugarContact | null {
    return this.sugarContacts
      .filter((sugarContact: SugarContact) => sugarContact.id === this.selectedSugarContactId)[0] ?? null;
  }

  public get sugarAccountId(): string | null {
    return this.selectedSugarContact?.account_id ?? null;
  }

  public get frontConversationId(): string | null {
    return this.frontService.conversation?.id ?? null;
  }

  public get primaryAccountSugarContactId(): string | null {
    return this.sugarAccount?.primary_contact_id ?? null;
  }

  public get primaryAccountSugarContact(): SugarContact | null {
    if (!this.primaryAccountSugarContactId) {
      return null;
    }

    return this.sugarContacts
      .filter((sugarContact: SugarContact) => sugarContact.id === this.primaryAccountSugarContactId)[0] ?? null;
  }

  constructor (
    private activatedRoute: ActivatedRoute,
    private contactsService: ContactsService,
    private frontService: FrontService,
    private apiService: ApiService,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.activatedRoute.data
      .pipe(take(1))
      .subscribe(({ data = {}}) => {
        const {
          sugarContacts = [],
          sugarAccount = null,
          sugarOpportunity = null,
        } = data || {};

        this.sugarContacts = sugarContacts;
        this.sugarAccount = sugarAccount;
        this.sugarOpportunity = sugarOpportunity;
        this.primarySugarContact = this.primaryAccountSugarContact;
      });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected syncMessages(frontConversationId: string, module: 'Accounts' | 'Opportunities', sugarAccountId: string) {
    return this.apiService.addConversationAsEmails(frontConversationId, module, sugarAccountId)
      .pipe(map((data: any) => {
        const {
          info: { subject = 'Unexpected Error', message = 'Server internal error' } = {},
          success = false,
        } = data;
        
        this.toastService.add({
          severity: success ? 'success' : 'error',
          summary: subject,
          detail: message,
        });
      }));
  }

  public async onAccountSyncHandler() {
    if (this.sugarAccountId && this.frontConversationId) {
      this.accountSyncing = true;
      this.syncMessages(this.frontConversationId, 'Accounts', this.sugarAccountId).pipe(take(1))
        .subscribe(() => this.accountSyncing = false);
    }
  }

  public onOpportunitySyncHandler() {
    if (this.sugarAccountId && this.frontConversationId) {
      this.opportunitySyncing = true;
      this.syncMessages(this.frontConversationId, 'Opportunities', this.sugarAccountId).pipe(take(1))
        .subscribe(() => this.opportunitySyncing = false);
    }
  }

  public async onAccountUnassign() {
    if (this.selectedSugarContactId) {
      this.unassignAccountInProgress = true;
      this.apiService.unassignAccount(this.selectedSugarContactId).pipe(
        map((data: any) => {
          const {
            info: { subject = 'Unexpected Error', message = 'Server internal error' } = {},
            success = false,
          } = data;
          
          this.toastService.add({
            severity: success ? 'success' : 'error',
            summary: subject,
            detail: message,
          });
        })
      ).subscribe(() => {
        this.unassignAccountInProgress = false;
        this.router.navigateByUrl('/sales/details');
      });
    }
  }

  public onOpportunityUnassign() {
    if (this.sugarOpportunity?.id) {
      this.unassignOpportunityInProgress = true;
      this.apiService.unassignOpportunity(this.sugarOpportunity.id).pipe(
        map((data: any) => {
          const {
            info: { subject = 'Unexpected Error', message = 'Server internal error' } = {},
            success = false,
          } = data;
          
          this.toastService.add({
            severity: success ? 'success' : 'error',
            summary: subject,
            detail: message,
          });
        })
      ).subscribe(() => {
        this.unassignOpportunityInProgress = false;
        this.router.navigateByUrl('/sales/details');
      });
    }
  }

  protected async refreshContactsData() {
    const sugarContactIds = this.sugarContacts
      .map((sugarContact: SugarContact) => sugarContact?.id ?? null)
      .filter((sugarContactId: string) => (sugarContactId || '').length > 0);

    if (sugarContactIds.length > 0) {
      this.apiService.getSugarContacts(sugarContactIds)
        .subscribe(({data: sugarContacts = []}: any) => {
          this.sugarContacts = sugarContacts;
        });
    }
  }

  protected async refreshAccountsData() {
    const sugarAccountIds = this.sugarAccounts
      .map((sugarAccount: SugarAccount) => sugarAccount?.id ?? null)
      .filter((sugarAccountId: string) => (sugarAccountId || '').length > 0);

    if (sugarAccountIds.length > 0) {
      this.apiService.getSugarAccounts(sugarAccountIds)
        .subscribe(({data: sugarAccounts = []}: any) => {
          this.sugarAccounts = sugarAccounts;
        });
    }
  }

  protected async refreshOpportunityData() {
    const sugarOpportunityId = this.sugarOpportunity?.id ?? null;

    if (!!sugarOpportunityId) {
      this.apiService.getSugarOpportunity(sugarOpportunityId)
        .subscribe(({data: sugarOpportunity = null}: any) => {
          this.sugarOpportunity = sugarOpportunity;
        });
    }
  }

  onPrimaryContactChangeHandler($event: any) {
    const { value: primaryContact = null } = $event;
    if (!this.sugarAccountId || !primaryContact) {
      return;
    }

    this.apiService.changeAccountPrimaryContact(this.sugarAccountId, primaryContact.id)
      .subscribe((response: any) => {
        const {
          success = false,
          info: { title = '', message = '' } = {},
        } = response;

        this.toastService.add({
          severity: (success ? 'success' : 'error'),
          summary: title,
          detail: message
        });

        if (success) {
          this.router.navigateByUrl('/sales/details');
        }
      });
  }

  protected async refreshData() {
    await this.refreshContactsData();
    await this.refreshAccountsData();
    await this.refreshOpportunityData();
  }
}
