<div class="relative">
  {{(expanded ? text : text | truncate:truncateLength)}}
  <div *ngIf="textLength >= truncateLength && !expanded" class="absolute bottom-0 expander-gradient w-full h-5"></div>
</div>

<div *ngIf="textLength >= truncateLength" class="flex">
  <button *ngIf="!expanded" class="font-bold mt-2" (click)="readMore()">
    Read More
  </button>

  <button *ngIf="expanded" class="font-bold mt-2" (click)="readLess()">
    Read Less
  </button>
</div>
