import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  const secret = authService.secret;

  if (secret === null) {
    return next(req);
  }

  const authReq = req.clone({
    headers: req.headers.set('x-plugin-secret', secret)
  });

  return next(authReq);
};
