<form class="w-full form-contact-create" [formGroup]="contactForm" (submit)="onSubmitHandler()">
  <div class="p-5">
    <div class="grid grid-cols-2 gap-1.5">
      <label>First name:</label>
      <div>
        <div class="error-message" *ngIf="firstName?.touched && firstName?.invalid">*First name field is required</div>
        <input id="firstName"
          required
          type="text"
          formControlName="firstName"
          [ngClass]="{'border-red-500': firstName?.touched && firstName?.invalid}"
          pInputText />
      </div>

      <label>Last name*:</label>
      <div>
        <div class="error-message" *ngIf="lastName?.touched && lastName?.invalid">*Last name field is required</div>
        <input id="lastName"
          type="text"
          formControlName="lastName"
          [ngClass]="{'border-red-500': lastName?.touched && lastName?.invalid}"
          pInputText />
      </div>

      <label>Primary email*:</label>
      <div>
        <div class="error-message" *ngIf="primaryEmail?.touched && primaryEmail?.invalid">*Primary email field is required and must contain an email address</div>
        <input id="primaryEmail"
          type="text"
          formControlName="primaryEmail"
          [ngClass]="{'border-red-500': primaryEmail?.touched && primaryEmail?.invalid}"
          pInputText />
      </div>

      <label>Secondary email:</label>
      <div>
        <div class="error-message" *ngIf="secondaryEmail?.touched && secondaryEmail?.invalid">*Secondary email field must contain email address</div>
        <input id="secondaryEmail"
          type="text"
          formControlName="secondaryEmail"
          [ngClass]="{'border-red-500': secondaryEmail?.touched && secondaryEmail?.invalid}"
          pInputText />
      </div>

      <label>Best contact number:</label>
      <div>
        <div class="error-message" *ngIf="workPhoneNumber?.touched && workPhoneNumber?.invalid">
          *Please enter a valid phone number prefixed with country code<br> for example: +44 7000 000 000
        </div>
        <input id="workPhoneNumber"
          type="text"
          formControlName="workPhoneNumber"
          [ngClass]="{'border-red-500': workPhoneNumber?.touched && workPhoneNumber?.invalid}"
          pInputText />
      </div>

      <label>Alternative contact number:</label>
      <div>
        <div class="error-message" *ngIf="mobilePhoneNumber?.touched && mobilePhoneNumber?.invalid">
          *Please enter a valid phone number prefixed with country code<br> for example: +44 7000 000 000
        </div>
        <input id="mobilePhoneNumber"
          type="text"
          formControlName="mobilePhoneNumber"
          [ngClass]="{'border-red-500': mobilePhoneNumber?.touched && mobilePhoneNumber?.invalid}"
          pInputText />
      </div>

      <label>Second alternative contact number:</label>
      <div>
        <div class="error-message" *ngIf="mobileSecondaryPhoneNumber?.touched && mobileSecondaryPhoneNumber?.invalid">
          *Please enter a valid phone number prefixed with country code<br> for example: +44 7000 000 000
        </div>
        <input id="mobileSecondaryPhoneNumber"
          type="text"
          formControlName="mobileSecondaryPhoneNumber"
          pInputText />
      </div>

      <label>Account*:</label>
      <div>
        <div class="error-message" *ngIf="account?.touched && !isAccountValid">*Account field must not be empty</div>
        <p-autoComplete
          [styleClass]="'w-full test ' + (account?.touched && !isAccountValid ? 'autocomplete-input-error' : '')"
          formControlName="account"
          [suggestions]="inputAccount.list" 
          (completeMethod)="inputAccount.search($event)"
          variant="filled"
          field="name">
          <ng-template let-user pTemplate="item">
            <div class="flex align-items-center gap-2">
              <span>{{ user.name }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <div *ngIf="inputAccount.hasMore" class="flex flex-col align-items-center px-3 pb-4 pt-2 text-center text-xs text-gray-400">
              <div>There is more records than could be displayed...</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <label>Contact type*:</label>
      <div>
        <div class="error-message" *ngIf="contactType?.touched && contactType?.invalid">*Please select contact type</div>
        <p-dropdown
          [styleClass]="(contactType?.touched && !isContactTypeValid ? 'border-[1px] border-red-500' : '')"
          [options]="contactTypeOptions" 
          formControlName="contactType" 
          optionLabel="label"
          dataKey="value" />
      </div>
    </div>

  </div>
  
  <div class="w-full">
    <p-divider />
    <div class="p-5">
      <p-button
        styleClass="w-full rounded-full font-semibold shadow justify-center"
        label="Create new contact in Sugar"
        (onClick)="onSubmitHandler()"
        [disabled]="contactForm.invalid"
        [loading]="processing" />
    </div>
  </div>
</form>


