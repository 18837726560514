import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../../../../services/loader.service';
import { Router } from '@angular/router';
import { CasesCardComponent } from '../../../../components/cases-card/cases-card.component';
import { ButtonComponent } from '../../../../components/button/button.component';
import { CommonModule } from '@angular/common';
import { ContactsService } from '../../../../services/contacts.service';
import { DividerModule } from 'primeng/divider';
import { phosphorWarningDuotone } from '@ng-icons/phosphor-icons/duotone';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { CasesService, CaseCreationStatus } from '../../../../services/cases.service';
import SugarCase from '../../../../common/models/sugar/sugar-case';
import { FrontService } from '../../../../services/front.service';
import { SpinnerComponent } from "../../../../components/spinner/spinner.component";
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-cases-current',
  standalone: true,
  imports: [
    CommonModule,
    CasesCardComponent,
    ButtonComponent,
    DividerModule,
    NgIconComponent,
    SpinnerComponent,
    SpinnerComponent,
    ProgressSpinnerModule,
    ButtonModule,
    RippleModule,
  ],
  viewProviders: [provideIcons({
    phosphorWarningDuotone,
  })],
  templateUrl: './cases-current.component.html',
  styleUrl: './cases-current.component.css'
})
export class CasesCurrentComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public creatingCase: boolean = false;
  public caseCreated: boolean = false;

  public get currentCase(): SugarCase | null {
    return this.casesService.currentCase;
  }

  public get otherOpenCases(): number {
    const openCases = this.casesService.caseCountData?.openCases || 0;

    return (openCases > 0 ? openCases - 1 : 0);
  }

  public get contactId(): string | null {
    return this.contactsService.selectedSugarContactId;
  }

  public get conversationId(): string | null {
    return this.frontService.conversation?.id || null;
  }

  public get loading(): boolean {
    return this.loaderService.loading;
  }

  public caseCreationStatus: CaseCreationStatus = CaseCreationStatus.none;

  constructor (
    private contactsService: ContactsService,
    private messageService: MessageService,
    private frontService: FrontService,
    private casesService: CasesService,
    private loaderService: LoaderService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public navigateToCasesList() {
    this.router.navigateByUrl('/cases/list');
  }

  public createSugarCase() {
    if (this.conversationId) {
      this.casesService.createCase(this.conversationId, this.contactId, this.setCaseCreationStatus);
    }
  }

  public setCaseCreationStatus = (status: CaseCreationStatus) => {
    this.caseCreationStatus = status;
  }
}
