<ng-container *ngIf="!loading">
  <ng-container *ngIf="tasksData === null || tasksData.length === 0">
    <div class="absolute w-full text-center text-gray-300 top-[50%] translate-y-[-50%]">
      No open tasks.
    </div>
  </ng-container>

  <ng-container *ngIf="tasksData !== null && tasksData.length > 0">
    <div class="p-5">
      <div *ngFor="let taskData of tasksData">
        <app-task-card [data]="taskData"></app-task-card>
      </div>
    </div>
  </ng-container>
</ng-container>
