export enum FrontContextTypeEnum {
  NO_CONVERSATION = 'noConversation',
  NO_CONVERSATION_POPOVER = 'noConversationPopover',
  SINGLE_CONVERSATION = 'singleConversation',
  SINGLE_CONVERSATION_POPOVER = 'singleConversationPopover',
  MULTI_CONVERSATIONS = 'multiConversations',
  MESSAGE = 'message',
  MESSAGE_COMPOSER = 'messageComposer',
}

export type FrontContextType = `${FrontContextTypeEnum}`;
