import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input, OnDestroy, OnInit, Output, QueryList,
  ViewChild, ViewChildren
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { CdkScrollable, CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { nanoid } from 'nanoid'
import { NavigationBarType } from './types/navigation-bar-type';
import { ButtonModule } from 'primeng/button';
import { phosphorCaretLeftFill, phosphorCaretRightFill } from '@ng-icons/phosphor-icons/fill';
import { NgIconComponent, provideIcons } from '@ng-icons/core';

@Component({
  selector: 'fis-navigation-bar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TabMenuModule,
    ScrollingModule,
    SliderModule,
    CdkVirtualScrollViewport,
    ButtonModule,
    NgIconComponent,
  ],
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({
    phosphorCaretLeftFill,
    phosphorCaretRightFill,
  })],
})
export class NavigationBarComponent implements AfterViewInit, OnDestroy, OnInit {
  private linkObserver!: IntersectionObserver;

  @Input() items: MenuItem[] = [];
  @Input() activeUrl = '';
  @Input() backgroundColor = 'transparent';
  @Input() navigationType: NavigationBarType = 'auto-scroll';

  @Output() onLinkClick = new EventEmitter<string>();

  @ViewChild(CdkScrollable, { static: false }) scrollable!: CdkScrollable;
  @ViewChildren('trackedLink') trackedLinks!: QueryList<ElementRef>;

  leftHiddenLinks: Map<string, boolean> = new Map<string, boolean>();
  rightHiddenLinks: Map<string, boolean> = new Map<string, boolean>();

  get leftHiddenLinksCount(): number {
    return [...this.leftHiddenLinks.values()]
      .reduce((acc, isHidden) => (acc + (isHidden ? 1 : 0)), 0);
  }

  get rightHiddenLinksCount(): number {
    return [...this.rightHiddenLinks.values()]
      .reduce((acc, isHidden) => (acc + (isHidden ? 1 : 0)), 0);
  }

  get backgroundColorCSS(): string {
    return `background-color: ${this.backgroundColor};`;
  }

  get navigationWidth(): number {
    const navigationElement = this.scrollable?.getElementRef().nativeElement.getClientRects()[0] || null;

    return (navigationElement?.right - navigationElement?.left) || 0;
  }

  get navigationScrollWidth(): number {
    return (this.scrollable?.getElementRef().nativeElement.scrollWidth - this.navigationWidth) || 0;
  }

  ngOnInit() {
    this.items = this.items.map((menuItem) => ({...menuItem, id: nanoid()}));
  }

  ngAfterViewInit() {
    this.linkObserver = new IntersectionObserver((navigationLinks) => {
      navigationLinks.forEach((navigationLink) => {
        const {
          target: { id } = {},
          boundingClientRect,
          rootBounds,
        } = navigationLink;
        if (!id || !boundingClientRect || !rootBounds || !this.navigationWidth) return;

        if (!navigationLink.isIntersecting) {
          if (this.isHidden(boundingClientRect) === -1) {
            this.leftHiddenLinks.set(id, true);
          }

          if (this.isHidden(boundingClientRect) === 1) {
            this.rightHiddenLinks.set(id, true);
          }
        } else {
          if (this.leftHiddenLinks.has(id)) this.leftHiddenLinks.set(id, false);
          if (this.rightHiddenLinks.has(id)) this.rightHiddenLinks.set(id, false);
        }
      });
    });

    this.trackedLinks.forEach((link: ElementRef) => {
      this.linkObserver.observe(link.nativeElement);
    });

    this.recalculateHiddenLinks();
  }

  ngOnDestroy() {
    if (this.linkObserver) {
      this.linkObserver.disconnect();
    }
  }

  onMouseMove(event: MouseEvent) {
    if (this.navigationType === 'auto-scroll') {
      if (!this.scrollable) {
        return;
      }

      const { x: mousePositionX = 0 } = event;
      const refinedMousePositionX = mousePositionX;
      const normalizedMousePositionX = refinedMousePositionX / (this.navigationScrollWidth / 2);
      const easedNormalizedMousePositionX = this.expoEaseInOut(normalizedMousePositionX);
      const mousePositionXScrollToOffset = (easedNormalizedMousePositionX * normalizedMousePositionX) * this.navigationScrollWidth;

      this.scrollable.scrollTo({ left: mousePositionXScrollToOffset, behavior: 'smooth' });
    }
  }

  onMouseLeave() {
    if (this.navigationType === 'auto-scroll') {
      this.scrollToCenter();
    }
  }

  onScrollEnd() {
    if (this.navigationType === 'auto-scroll') {
      this.recalculateHiddenLinks();
    }
  }

  scrollLeft() {
    const scrollOffset = this.scrollable.measureScrollOffset('left');
    if (scrollOffset > 0) {
      this.scrollable.scrollTo({ left: (scrollOffset < 150 ? 0 : scrollOffset - 150), behavior: 'smooth' });
    }
  }

  scrollRight() {
    const scrollOffset = this.scrollable.measureScrollOffset('right');
    if (scrollOffset > 0) {
      this.scrollable.scrollTo({ right: (scrollOffset < 150 ? 0 : scrollOffset - 150), behavior: 'smooth' });
    }
  }

  public onItemClick(url: string) {
    this.onLinkClick.emit(url); 
  }

  protected recalculateHiddenLinks() {
    for (const trackedLink of this.trackedLinks) {
      const boundingClientRect = trackedLink.nativeElement.getBoundingClientRect();
      const linkId = trackedLink.nativeElement.getAttribute('id');
      const isHiddenResult = this.isHidden(boundingClientRect);

      if (isHiddenResult === -1) {
        this.leftHiddenLinks.set(linkId, true);
      } else if (isHiddenResult === 1) {
        this.rightHiddenLinks.set(linkId, true);
      } else {
        if (this.leftHiddenLinks.has(linkId)) this.leftHiddenLinks.set(linkId, false);
        if (this.rightHiddenLinks.has(linkId)) this.rightHiddenLinks.set(linkId, false);
      }
    }
  }

  protected isHidden(boundingClientRect: DOMRect): -1 | 0 | 1 {
    if (boundingClientRect.left < 0) {
      return -1;
    }

    if (boundingClientRect.right > this.navigationWidth) {
      return 1;
    }

    return 0;
  }

  protected expoEaseInOut(x: number): number {
    return (x < 0.5 ? 0.5 * (1 - Math.pow(2, -10 * x)) : 0.5 * (Math.pow(2, 10 * (x - 1)) + 1));
  }

  protected scrollToCenter(behavior: ScrollBehavior = 'smooth') {
    this.scrollable.scrollTo({ left: this.navigationScrollWidth / 2, behavior });
  }
}
