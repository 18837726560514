"use strict";

/*
 * Entry point types.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EntryPointDataTypesEnum = exports.EntryPointTypesEnum = void 0;
var EntryPointTypesEnum;
(function (EntryPointTypesEnum) {
  EntryPointTypesEnum["CONVERSATION_LINK_DROPDOWN"] = "CONVERSATION_LINK_DROPDOWN";
  EntryPointTypesEnum["MESSAGE_MORE_DROPDOWN"] = "MESSAGE_MORE_DROPDOWN";
  EntryPointTypesEnum["SIDE_PANEL"] = "SIDE_PANEL";
  EntryPointTypesEnum["COMPOSER_TOOLBAR"] = "COMPOSER_TOOLBAR";
  EntryPointTypesEnum["CONTACT_HANDLE"] = "CONTACT_HANDLE";
})(EntryPointTypesEnum || (exports.EntryPointTypesEnum = EntryPointTypesEnum = {}));
/*
 * Entry point data types.
 */
var EntryPointDataTypesEnum;
(function (EntryPointDataTypesEnum) {
  EntryPointDataTypesEnum["PHONE"] = "phone";
})(EntryPointDataTypesEnum || (exports.EntryPointDataTypesEnum = EntryPointDataTypesEnum = {}));