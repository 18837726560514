import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
export default class ContactDto {
  @Expose()
  @Transform(({value}) => value || null)
  id: string | null = null;

  @Expose()
  @Transform(({value}) => value || null)
  name: string | null = null;

  @Expose()
  @Transform(({value}) => value || null)
  description: string | null = null;

  @Expose()
  @Transform(({value}) => value || [])
  groupIds: Array<any> = [];

  @Expose()
  @Transform(({value}) => value || [])
  handles: Array<any> = [];

  @Expose()
  @Transform(({value}) => value || [])
  customAttributes: Array<any> = [];

  get isBooker(): boolean {
    const bookerAttribute = this.customAttributes
      .filter((attribute: any) => attribute?.name === 'Booker')?.shift() ?? null;

    return (bookerAttribute?.value === true);
  }
}
