import { Component, Input } from '@angular/core';

@Component({
  selector: 'custom-spinner',
  standalone: true,
  imports: [],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.css'
})
export class SpinnerComponent {
  @Input()
  size: number = 3;
}
