<div class="h-screen bg-[#e2e5e8]">
  <div class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
    <img class="flex ml-auto mr-auto border-[1px]" src="/assets/lock.svg" />
    <div class="text-[#898a8c] select-none pointer-events-none text-center mt-6 font-semibold">
      Auth initialization error.<br />
      Please try closing and opening<br>
      plugin again.
    </div>
  </div>
</div>
