import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadComponent } from '../typeahead/typeahead.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'typeahead-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TypeaheadComponent,
  ],
  templateUrl: './typeahead-input.component.html',
  styleUrl: './typeahead-input.component.css'
})
export class TypeaheadInputComponent {
  private _loading: boolean = false;
  private _data: any[] = [];

  @Input()
  public set loading(value: boolean) {
    this._loading = value;
    this.loadingChange.emit(value);
  };

  public get loading(): boolean {
    return this._loading;
  }

  @Output()
  public loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public set data(value: any[]) {
    this._data = (Array.isArray(value) ? value : []);
    this.dataChange.emit(this.data);
    this.showSearchItems = true;
  }

  public get data(): any[] {
    return this._data;
  }

  @Output()
  public dataChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  public searchFunction!: (value: string) => Observable<any>;

  public get searchItemList(): any[] {
    return this.data.map((dataRecord: any) => ({
      id: dataRecord.id,
      name: dataRecord.name,
    }));
  }

  @Input()
  inputClass: string[] = ['block', 'w-full', 'text-normal', 'text-gray-400', 'border', 'border-gray-300', 'rounded-lg', 'bg-gray-50', 'focus:ring-blue-500', 'focus:border-blue-500'];

  @Input()
  public label: string = '';

  @Input()
  public placeholder: string = 'Search here...';

  @Output()
  public selected = new EventEmitter<any>();

  public search = new FormControl('');

  public selectedItem: {id: string, name: string} | null = null;
  public showSearchItems: boolean = false;

  get filteredList() {
    if (this.search.value?.length === 0) {
      return [];
    }

    return this.searchItemList.filter(item => {
      return item.name.toLowerCase().includes(this.search.value?.toLowerCase() ?? '');
    });
  }


  selectSearchItem(item: any) {
    this.selectedItem = item;
    this.showSearchItems = false;
    this.selected.emit(item);
    this.search.setValue(item.name);
  }

  hideMenu() {
    if (this.showSearchItems) {
      this.showSearchItems = false;
    }
  }
}
