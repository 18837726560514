<ng-container *ngIf="!loading">
  <ng-container *ngIf="casesData === null || casesData.length === 0">
    <div class="absolute w-full text-center text-gray-300 top-[50%] translate-y-[-50%]">
      Unable to load cases.
    </div>
  </ng-container>


  <ng-container *ngIf="casesData === null || casesData.length > 0">
    <div class="p-5">
      <div *ngFor="let caseData of casesData">
        <app-cases-card (onAssign)="onCaseReassignHandler($event)" [data]="caseData" [assignProcessing]="(assigningCaseId === caseData.id ? true : false)" [allowAssign]="true"></app-cases-card>
      </div>
    </div>
  </ng-container>
</ng-container>
