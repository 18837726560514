import { Component, Input } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { TableService } from '../../services/table.service';
import TableData from '../../common/interfaces/table-data';
import TableDataMapping from '../../common/interfaces/table-data-mapping';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import Front from '@frontapp/plugin-sdk';
import { PriorityPillComponent } from '../priority-pill/priority-pill.component';
import { TextExpanderComponent } from '../text-expander/text-expander.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-task-card',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    PriorityPillComponent,
    TextExpanderComponent
  ],
  templateUrl: './task-card.component.html',
  styleUrl: './task-card.component.css'
})
export class TaskCardComponent {
  private sugarUrl: string;

  @Input()
  data: any = {};

  public get dataMapping(): Array<TableDataMapping> {
    return [
      {field: 'apartment', label: 'Apartment'},
      {field: 'category_label', label: 'Category'},
      {field: 'complex', label: 'Complex'},
      {field: 'planned_dispatch', label: 'Planned dispatch'},
      {field: 'assigned_to', label: 'Assigned to'},
    ];
  }

  get tableData(): Array<TableData> {
    return this.tableService.transformData(this.data, this.dataMapping);
  }

  constructor (private readonly tableService: TableService) {
    this.sugarUrl = environment.sugarUrl;
  }

  viewInSugarCRM(task_id: string) {
    Front.openUrl(`${this.sugarUrl}/#Tasks/${task_id}`)
  }
}
