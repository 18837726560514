import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import NavbarLink from '../common/interfaces/navbar-link';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { ContactsService } from './contacts.service';
import SugarContact from '../common/models/sugar/sugar-contact';
import { CasesService } from './cases.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _subscription: Subscription;
  private _navbarLinks: Array<NavbarLink> = [];
  private _activeRoute: Array<string> = [];
  private _casesCountSubscription: Subscription;

  public navigationChanged$: EventEmitter<void> = new EventEmitter<void>();
  protected casesCount$: EventEmitter<number> = new EventEmitter<number>();

  public get casesCount(): number {
    return this.casesService.caseCountData?.zendeskCases || 0;
  }

  public get navbarLinks(): Array<NavbarLink> {
    return this._navbarLinks;
  }

  private set activeRoute(url: Array<string>) {
    this._activeRoute = url;
  }

  public get activeRoute(): Array<string> {
    return this._activeRoute;
  }

  constructor(
    private contactsService: ContactsService,
    private casesService: CasesService
  ) {
    this.addNewLink('contact', 'Contact', '/contact');
    this.addNewLink('reservations-list', 'Reservations', '/reservations/list');
    this.addNewLink('cases-current', 'Cases', '/cases/current');
    this.addNewLink('tasks-open', 'Open Tasks', '/tasks/open');
    this.addNewLink('sales', 'Sales', '/sales');
    this.addNewLink('settings', 'Mass Email', '/settings');

    if (!environment.production) {
      this.addNewLink('debug', 'Debug', '/debug');
    }

    // Move it somewhere else in the future
    this.contactsService.selectedSugarContact$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (sugarContact: SugarContact | null) => {
        if (!sugarContact) {
          return;
        }

        this.navbarLinks.forEach((link: NavbarLink) => this.enableDot(link.id, false));
        const contactType = sugarContact.contact_type;
        const isBooker = ((contactType === 'Booker' || contactType === 'Influencer') ? true : false);
        this.setDisabled('cases-current', isBooker);
        this.setDisabled('tasks-open', isBooker);

        this.navigationChanged$.emit();
      });

    this._subscription = this.contactsService.selectedSugarContact$
      .subscribe(async (sugarContact: SugarContact | null) => {
        this.enableDot('cases-current', false);
        this.enableDot('reservations-list', false);

        if (!sugarContact) return;

        const { id, current_reservation_id, contact_type } = sugarContact;

        if (contact_type) {
          const isBooker = ((contact_type === 'Booker' || contact_type === 'Influencer') ? true : false);
          this.setDisabled('cases-current', isBooker);
          this.setDisabled('tasks-open', isBooker);
        }

        const hasReservation = (typeof current_reservation_id === 'string' && current_reservation_id.length > 0);
        this.enableDot('reservations-list', hasReservation);

        this.navigationChanged$.emit();
      });

    this._casesCountSubscription = this.casesCount$
      .subscribe((casesCount: number) => {
        this.enableDot('cases-current', (casesCount > 0));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this._subscription.unsubscribe();
    this._casesCountSubscription.unsubscribe();
  }

  addNewLink(id: string, name: string,  url: string, dot: boolean = false, disabled: boolean = false, active: boolean = false) {
    this._navbarLinks.push({ id, name, url, disabled, active, dot});
  }

  enableDot(id: string, enable: boolean = true) {
    this._navbarLinks = this._navbarLinks
      .map((link: NavbarLink) => {
        if (link.id === id) {
          link.dot = enable;
        }

        return link;
      });
    this.navigationChanged$.emit();
  }

  getLink(id: string) {
    return this.navbarLinks.filter((link: NavbarLink) => link.id === id);
  }

  setDisabled(id: string, disabled: boolean = true) {
    this._navbarLinks = this._navbarLinks.map((link: NavbarLink) => {
      if (link.id === id) {
        link.active = !disabled;
        link.disabled = disabled;
      }

      return link;
    });
  }

  setActive(url: Array<string>) {
    this.activeRoute = url;
  }
}
