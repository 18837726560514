<div class="h-full" *ngIf="!pageLoading">
  <ng-container *ngIf="selectedSugarContact; else missingSugarContact">
    <router-outlet />
  </ng-container>
</div>

<ng-template #missingSugarContact>
    <div class="w-full text-center absolute top-[50%] translate-y-[-50%] text-lg text-gray-300 shadow-2xl">
      (Select or create contact to display more details)
    </div>
</ng-template>