<div class="grid grid-cols-1 gap-2 px-5">
  <div class="inline-flex gap-2 w-full mb-3 items-center justify-between">
    <span class="text-xl font-medium text-gray-700">Account</span>

    <div class="flex flex-row gap-2">
      <button type="button"
        (click)="onUnassignClick()"
        [disabled]="unassignInProgress"
        class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-semibold rounded-full text-nowrap text-normal px-4 py-1.5">
        <custom-spinner class="inline-flex me-2" *ngIf="unassignInProgress" />
        Unassign
      </button>
      <button type="button"
        (click)="onSyncClick()"
        [disabled]="inSync"
        class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-semibold rounded-full text-nowrap text-normal px-4 py-1.5">
        <custom-spinner class="inline-flex me-2" *ngIf="inSync" />
        Sync
      </button>
    </div>
  </div>

  <div class="grid grid-cols-2 text-left text-normal">
    <div *ngFor="let dataRecord of accountDetailsTableData">
      <div class="text-[#ababab] py-1 font-semibold">{{ dataRecord.label }}</div>
      <div class="text-[#4a4a4c] py-1">{{ dataRecord.value }}</div>
    </div>
  </div>

  <div class="w-full mt-3">
    <fis-inline-edit-notes (submit)="onAccountNotesUpdate($event)" [saving]="savingNotes" [label]="accountNoteTableDataField.label" [notes]="accountNoteTableDataField.value"></fis-inline-edit-notes>
  </div>
</div>
