import { Contact } from "../models/fis/contact";
import SugarContact from "../models/sugar/sugar-contact";

const toContactTypeWage = (sugarContact: SugarContact): number => {
  const { contact_type: type = 0, current_reservation_id: reservation_id = '' } = sugarContact;
  let wage = 0;

  switch (type) {
    case 'Guest':
      wage += 2;
      break;

    case 'Booker':
      wage += 1;
      break

      default:
        wage = 0;
  }

  if (reservation_id && reservation_id.length > 0) {
    wage += 0.5;
  }

  return wage;
}

export function sortSugarContacts(sugarContacts: SugarContact[]) {
  return sugarContacts
    .sort((contactA: SugarContact, contactB: SugarContact) => {
      const wageA = toContactTypeWage(contactA);
      const wageB = toContactTypeWage(contactB);

      if (wageA === wageB) return 0;

      return (wageA > wageB ? -1 : 1);
    });
}

export function sortContactsBySugarContact(contacts: Contact[]) {
  return contacts
    .sort((contactA: Contact, contactB: Contact) => {
      if (!contactA.sugarLink.contact || ! contactB.sugarLink.contact) {
        return 0;
      }

      const wageA = toContactTypeWage(contactA.sugarLink.contact);
      const wageB = toContactTypeWage(contactB.sugarLink.contact);

      if (wageA === wageB) return 0;

      return (wageA > wageB ? -1 : 1);
    });
}
