import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { TypeaheadComponent } from '../../../../components/typeahead/typeahead.component';
import { RecordSelectorTableComponent } from '../../../../components/record-selector-table/record-selector-table.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SpinnerComponent } from '../../../../components/spinner/spinner.component';
import { ApiService } from '../../../../services/api.service';
import { concatMap, of, Subject } from 'rxjs';
import { FrontService } from '../../../../services/front.service';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'app-sales-add-to-opportunity',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TypeaheadComponent,
    RecordSelectorTableComponent,
    SpinnerComponent,
  ],
  templateUrl: './sales-add-to-opportunity.component.html',
  styleUrl: './sales-add-to-opportunity.component.css'
})
export class SalesAddToOpportunityComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public processing: boolean = false;
  public loading: boolean = false;
  public selectedRecordId: string | null = null;
  public accountId!: string;
  public data: any[] = [];

  public apiTypeahead = (value: string) => (value.length > 0 ? this.apiService.opportunityTypeaheadByName(value) : of([]));

  public get selectorHeaders(): string[] {
    return ['Account Name', 'Type', 'Account Size'];
  }

  public get selectorData(): {id: string, values: string[]}[] {
    return this.data
      .map((dataRecord: any) => ({
        id: dataRecord.id,
        values: [
          dataRecord.name,
          dataRecord.opportunity_type,
          dataRecord.date_closed,
        ]
      }));
  }

  constructor (
    private apiService: ApiService,
    private frontService: FrontService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.accountId = this.activatedRoute.snapshot.paramMap.get('account_id') || '';
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
  }

  onRecordSelectHandler(id: string | null) {
    this.selectedRecordId = id;
  }

  onAddClickHandler(recordId: string) {
    this.processing = true;
    const { id: conversationId } = this.frontService?.conversation ?? {};

    if (!conversationId) {
      this.toastService.add({
        severity: 'error',
        summary: 'Plugin Error',
        detail: 'Failed to get conversation ID from front service',
      });

      return;
    }

    this.apiService.addAccountToOpportunity(recordId, this.accountId).pipe(
      concatMap(() => this.apiService.addConversationAsEmails(conversationId, 'Opportunities', recordId))
    ).subscribe((response: any) => {
      const {
        success = false,
        data = null,
        info: { subject = 'Unexpected error', message = 'Unexpected error occured' } = {},
      } = response;
      
      const toastType = (success ? ((data?.saved ?? 0) === 0 ? 'info' : 'success') : 'error');
      this.toastService.add({
        severity: toastType,
        summary: (success && (data?.saved ?? 0) === 0 ? 'Info' : subject),
        detail: message,
      });

      this.processing = false;

      if (success) {
        this.router.navigateByUrl('/sales/details');
      }
    });

  }
}
