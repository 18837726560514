import { Component, Input, Output } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { TableService } from '../../services/table.service';
import TableData from '../../common/interfaces/table-data';
import TableDataMapping from '../../common/interfaces/table-data-mapping';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import Front from '@frontapp/plugin-sdk';
import { StatusPillComponent } from '../status-pill/status-pill.component';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-cases-card',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    StatusPillComponent,
    SpinnerComponent,
  ],
  templateUrl: './cases-card.component.html',
  styleUrl: './cases-card.component.css'
})
export class CasesCardComponent {
  private sugarUrl: string;

  @Input()
  data: any = {};

  @Input()
  allowAssign: boolean = false;

  @Input()
  assignProcessing: boolean = false;

  @Output()
  onAssign: Subject<string> = new Subject<string>();

  public get dataMapping(): Array<TableDataMapping> {
    return [
      {field: 'number', label: 'Number'},
      {field: 'category_label', label: 'Case category'},
      {field: 'date_entered', label: 'Case created'},
      {field: 'next_action_date', label: 'Next action required'},
      {field: 'apartment', label: 'Apartament'},
      {field: 'assigned_to', label: 'Assigned to'},
      {field: 'second_assaignee', label: 'Second assignee'}
    ];
  }

  public get tableData(): Array<TableData> {
    return this.tableService.transformData(this.data, this.dataMapping);
  }

  constructor (private readonly tableService: TableService) {
    this.sugarUrl = environment.sugarUrl;
  }

  public onAssignClick() {
    const caseId = this.data?.id ?? null;

    if (caseId !== null) {
      this.onAssign.next(caseId);
    }
  }

  public viewInSugarCRM(case_id: string) {
    Front.openUrl(`${this.sugarUrl}/#Cases/${case_id}`);
  }
}
