import { ErrorHandler, Inject, Injectable, InjectionToken } from "@angular/core";
import Rollbar from "rollbar";

const rollbarConfig = {
  accessToken: '62f611ceeeed4b4c95cf6f545d78f0ba',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
    console.error(err);
  }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
