import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from '../../environments/environment';
import TemplateUpdate from '../common/interfaces/api/template-update';
import { ContactSearchQueryDto } from '../common/dto/request/contact-search-query.dto';
import FrontTeammate from '../common/models/front-teammate';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  private transformError(err: any) {
    const {
      error: { statusCode: status = '' } = '',
      error: { error = '' } = '',
      error: { message = '' } = '',
    } = err;

    return {status, error, message, success: false};
  }

  getContactDetails(contact_id: string) {
    return this.http.get<any>(`${this.apiUrl}/api/contacts/${contact_id}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getEnums() {
    return this.http.get<any>(`${this.apiUrl}/v2/api/enums`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getReservations(contact_id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/contacts/${contact_id}/reservations`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getCases(contact_ids: Array<string>) {
    const data = { contact_ids: contact_ids };

    return this.http.post<any>(`${this.apiUrl}/api/search/cases`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getCase(case_id: string) {
    return this.http.get<any>(`${this.apiUrl}/api/cases/${case_id}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getOpenTasks(contact_id: string) {
    return this.http.get<any>(`${this.apiUrl}/api/contacts/${contact_id}/tasks/open`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  findContactDetails({...params}) {
    const { first_name = '', last_name = '', emails = [], phone_numbers = [] } = params;
    const data = {
      first_name: first_name,
      last_name: last_name,
      emails: emails,
      phone_numbers: phone_numbers,
    };

    return this.http.post<any>(`${this.apiUrl}/api/search/contact`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  findSugarContacts(queryData: ContactSearchQueryDto[]) {
    return this.http.post<any>(`${this.apiUrl}/v2/api/search/contacts`, { queries: queryData })
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  createContact(contactData: any) {
    return this.http.post<any>(`${this.apiUrl}/v2/api/contacts`, contactData)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  updateContact(contact_id: string, data: any) {
    return this.http.put<any>(`${this.apiUrl}/api/contacts/${contact_id}`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getFrontContact(id: string) {
    return this.http.get<any>(`${this.apiUrl}/api/front/contacts/${id}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getContactCreateForm() {
    return this.http.get<any>(`${this.apiUrl}/api/form/contact/create`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  findCaseByNumber(case_number: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/search/cases/number/${case_number}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getCaseCount(contact_id: string, current_reservation_id: string | null) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/contacts/${contact_id}/count/cases/${(!current_reservation_id ? 'null' : current_reservation_id)}`)
      .pipe(
        map((res: any) => {
          const { success = false } = res;

          if (success) {
            return res.data;
          }

          return res;
        })
      )
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  assignCase(case_id: string, contact_id: string, conversation_id: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/cases/${case_id}/assign/${contact_id}/${conversation_id}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getEmailTemplate() {
    return this.http.get<any>(`${this.apiUrl}/v2/api/settings/email-template`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  updateEmailTemplate(data: TemplateUpdate) {
    return this.http.put<any>(`${this.apiUrl}/v2/api/settings/email-template`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getDuplicateData(conversation_id: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/conversations/${conversation_id}/duplicates`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getOpportunityByAccountId(account_id: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/opportunities/${account_id}/by_account_id`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }
  
  opportunityTypeaheadByName(name: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/typeahead/opportunity/${name}`)
    .pipe(catchError((err) => of(this.transformError(err))));
  }

  typeaheadAccountByName(name: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/typeahead/account/${name}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  typeaheadUsersByName(name: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/typeahead/user/${name}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  typeaheadContactsByName(name: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/typeahead/contact/${name}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  typeaheadEmployeesByName(name: string) {
    return this.http.get<any>(`${this.apiUrl}/v2/api/typeahead/employees/${name}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  createOpportunity(data: any) {
    return this.http.post(`${this.apiUrl}/v2/api/opportunities`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  updateOpportunity(opportunityId: string, data: any) {
    return this.http.put(`${this.apiUrl}/v2/api/opportunities/${opportunityId}`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  createAccount(data: any) {
    return this.http.post(`${this.apiUrl}/v2/api/accounts`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  updateAccount(accountId: string, data: any) {
    return this.http.put(`${this.apiUrl}/v2/api/accounts/${accountId}`, data)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  addConversationAsEmails(conversation_id: string, module: 'Accounts' | 'Opportunities', record_id: string) {
    return this.http.get(`${this.apiUrl}/v2/api/conversations/${conversation_id}/add-emails/${module}/${record_id}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  changeAccountPrimaryContact(accountId: string, contactId: string) {
    return this.http.put(`${this.apiUrl}/v2/api/accounts/${accountId}/primary-contact/${contactId}`, {})
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getSugarContacts(contactIds: string[]) {
    const contactIdsParam = contactIds.join(',');

    return this.http.get(`${this.apiUrl}/v2/api/contacts/list/${contactIdsParam}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getSugarAccounts(accountIds: string[]) {
    const accountIdsParam = accountIds.filter((accountId: string) => (accountId || '').length > 0).join(',');

    return this.http.get(`${this.apiUrl}/v2/api/accounts/list/${accountIdsParam}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getSugarOpportunity(opportunityId: string) {
    return this.http.get(`${this.apiUrl}/v2/api/oppportunities/${opportunityId}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getSugarUser(userId: string) {
    return this.http.get(`${this.apiUrl}/v2/api/users/${userId}`)
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  addAccountToOpportunity(opportunity_id: string, account_id: string) {
    return this.http.put(`${this.apiUrl}/v2/api/opportunities/${opportunity_id}/account`, { account_id })
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  setContactAccount(contact_id: string, account_id: string) {
    return this.http.put(`${this.apiUrl}/v2/api/contacts/${contact_id}/account/${account_id}`, {})
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  getSalesResolverData(contact_id: string, contact_ids: string[]) {
    return this.http.post(`${this.apiUrl}/v2/api/resolvers/sales`, { contact_id, contact_ids })
      .pipe(catchError((err) => of(this.transformError(err))));
  }

  createSugarCase(conversation_id: string, contact_id: string | null = null) {
    return this.http.post<any>(`${this.apiUrl}/v2/api/cases`, { conversation_id, contact_id });
  }

  unassignAccount(contact_id: string) {
    return this.http.put<any>(`${this.apiUrl}/v2/api/contacts/${contact_id}/unassign-account`, {});
  }

  unassignOpportunity(opportunity_id: string) {
    return this.http.put<any>(`${this.apiUrl}/v2/api/opportunities/${opportunity_id}/unassign`, {});
  }

  updateAccountSaleNotes(account_id: string, notes: string) {
    return this.http.put<any>(`${this.apiUrl}/v2/api/accounts/${account_id}/sale_notes`, { notes });
  }

  updateOpportunityNotes(opportunity_id: string, notes: string) {
    return this.http.put<any>(`${this.apiUrl}/v2/api/opportunities/${opportunity_id}/notes`, { notes });
  }

  sendAuthInfo(teammate: FrontTeammate) {
    return this.http.post<any>(`${this.apiUrl}/v2/api/bootstrap`, { data: btoa(JSON.stringify(teammate)) })
      .pipe(catchError((err) => of(this.transformError(err))));
  }
}
