import { Exclude, Expose, Type } from "class-transformer";
import FrontContact from "./front-contact";

@Exclude()
export class FrontRecipient {
  @Expose()
  name: string = '';

  @Expose()
  handle: string = '';

  @Expose()
  @Type(() => FrontContact)
  contact?: FrontContact;

  @Expose()
  type: string = '';

  isHandleEmail(): boolean {
    return this.handle.includes('@');
  }

  isHandlePhoneNumber(): boolean {
    return /^\+[0-9\s]+$/.test(this.handle);
  }

  getEmail(): string | null {
    return (this.isHandleEmail() ? this.handle.toLowerCase() : null);
  }

  getPhoneNumber(): string | null {
    return (this.isHandlePhoneNumber() ? this.handle : null);
  }

  getName(): string {
    if (this.name.length > 0) {
      return this.name;
    }

    return (this.isHandleEmail() ? this.handle.split('@')[0] : 'No name');
  }

  /**
   * @deprecated
   */
  getPillData(): {id: string, name: string, type: string} {
    return {id: this.handle, name: this.getName(), type: ''};
  }
}
