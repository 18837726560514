import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { CasesCardComponent } from '../../../../components/cases-card/cases-card.component';
import { LoaderService } from '../../../../services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { ButtonComponent } from '../../../../components/button/button.component';
import { CacheService } from '../../../../services/cache.service';
import { ToastService } from '../../../../services/toast.service';
import { ApiService } from '../../../../services/api.service';
import { ContactsService } from '../../../../services/contacts.service';

@Component({
  selector: 'app-cases-list',
  standalone: true,
  imports: [
    CommonModule,
    CasesCardComponent,
    ButtonComponent
  ],
  templateUrl: './cases-list.component.html',
  styleUrl: './cases-list.component.css'
})
export class CasesListComponent implements OnDestroy {
  private _subscription: Subscription;

  public casesData: Array<any> = [];
  public assigningCaseId: string | null = null;

  public get loading(): boolean {
    return this.loaderService.loading;
  }

  constructor (
    private readonly loaderService: LoaderService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly contactsService: ContactsService,
    private readonly cacheService: CacheService,
    private readonly toastService: ToastService,
    private readonly apiService: ApiService,
  ) {
    this._subscription = this.activatedRoute.data
      .subscribe(({casesList}) => {
        this.casesData = casesList;
      });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onCaseReassignHandler(caseId: string) {
    const conversationId = this.cacheService.conversationId;
    const contactId = this.contactsService.selectedSugarContactId;

    if (conversationId === null || contactId === null || caseId === null) {
      this.toastService.addToast('Something went wrong, one of needed IDs is missing', 'error', 5);
      return;
    }

    this.assigningCaseId = caseId;
    this.apiService.assignCase(caseId, contactId, conversationId)
      .subscribe((res: any) => {
        const { success = false } = res;
        if (success) {
          this.assigningCaseId = null;
        }
      });
  }
}
