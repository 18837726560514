import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public contactTypeEnums: any = {};
  public guestContactEnums: any = {};

  public enumsData: any = {};

  public dataLoading: boolean = false;
  public dataLoadingAttempts: number = 0;

  private _dataLoaded: boolean = false;
  public get dataLoaded(): boolean {
    return this._dataLoaded;
  }

  public set dataLoaded(value: boolean) {
    this._dataLoaded = value;
    this.dataLoaded$.next();
  }

  public dataLoaded$: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
  ) {}

  protected loadEnums() {
    this.apiService.getEnums()
      .subscribe((data: any) => {
        const {
          guest_contact = null,
          contact_type = null,
          account_grade = null,
          sales_stage = null,
          guest_contact_category = null,
          account_type = null,
          opportunity_type = null,
          opportunity_sales_stage = null,
          account_support_team = null,
        } = data ?? {};
        this.dataLoadingAttempts++;

        if ((!guest_contact || !contact_type) && this.dataLoadingAttempts < 5) {
          this.dataLoaded = false;
          this.dataLoading = false;
          this.loadAllData();
          return
        }

        this.enumsData = {
          guest_contact,
          contact_type,
          account_grade,
          sales_stage,
          guest_contact_category,
          account_type,
          opportunity_type,
          opportunity_sales_stage,
          account_support_team,
        };
        
        this.guestContactEnums = guest_contact;
        this.contactTypeEnums = contact_type;
        this.dataLoaded = true;
        this.dataLoading = false;
      });
  }

  public loadAllData() {
    this.dataLoading = true;
    this.loadEnums();
  }

  public getEnums(name: string): Array<{value: string, label: string}> {
    if (typeof this.enumsData[name] !== "object" || this.enumsData[name] === null) {
      return [];
    }

    return Object.entries(this.enumsData[name])
      .map((record: any) => ({value: record[0] ?? '', label: record[1] ?? ''}))
      .filter((record) => record.label.length > 0 || record.value.length > 0);
  }

  /**
   * @deprecated
   */
  public transformToArray(data: any): {value: string, name: string}[] {
    if (!data) {
      return [];
    }

    const transformedData: Array<{value: string, name: string}> = [];
    for (const [key, value] of Object.entries(data)) {
      const label: string = (value ? value.toString() : '');

      if (key.length === 0 || label.length === 0) {
        continue;
      }

      transformedData.push({value: key, name: label})
    }

    return transformedData;
  }
}
