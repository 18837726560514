import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'record-selector-table',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    ButtonModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './record-selector-table.component.html',
  styleUrl: './record-selector-table.component.css'
})
export class RecordSelectorTableComponent {
  private _data: any[] = [];
  private _selectedRecordId: string | null = null;
  public isDirty: boolean = false;

  @Input() public processing: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public headersData: string[] = [];

  @Input() public set data(value: any[]) {
    if (this.data.length > 0 && value.length === 0) {
      this.selectedRecordId = null;
    }

    this._data = (Array.isArray(value) ? value : []);
  }

  public get data(): any[] {
    return this._data;
  }

  @Output() public selected: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() public addClicked: EventEmitter<string> = new EventEmitter<string>();

  public set selectedRecordId(value: string | null) {
    this._selectedRecordId = value;
    this.selected.emit(this.selectedRecordId);
  }

  public get selectedRecordId(): string | null {
    return this._selectedRecordId;
  }

  trackById(index: number, item: any): number {
    return item.id;
  }

  onAddButtonClickHandler(recordId: string) {
    this.addClicked.emit(recordId);
  }
}
