import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from '../spinner/spinner.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'typeahead',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SpinnerComponent,
  ],
  templateUrl: './typeahead.component.html',
  styleUrl: './typeahead.component.css'
})
export class TypeaheadComponent implements OnInit {
  private _loading: boolean = false;
  private _data: any[] = [];
  private _active: boolean = true;

  @Input() public placeholder: string = 'Type here to start search...';

  @Input()
  inputClass: string[] = ['block', 'w-full', 'text-normal', 'text-gray-400', 'border', 'border-gray-300', 'rounded-lg', 'bg-gray-50', 'focus:ring-[#45c525]', 'focus:border-[#45c525]']

  @Input()
  public set loading(value: boolean) {
    this._loading = value;
    this.loadingChange.emit(value);
  };

  public get loading(): boolean {
    return this._loading;
  }

  @Output()
  public loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public set value(value: string) {
    this._active = false;
    this.searchControl.setValue(value);
    this._active = true;
  }

  @Input()
  public set data(value: any[]) {
    this._data = (Array.isArray(value) ? value : []);
    this.dataChange.emit(this.data);
  }

  public get data(): any[] {
    return this._data;
  }

  @Output()
  public dataChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  public label: string = 'Search';

  @Input()
  public debounceTime: number = 300;

  public searchControl = new FormControl('');

  @Input()
  public searchFunction!: (value: string) => Observable<any>;

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      filter(() => this._active),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      switchMap(value => {
        const searchValue = value ?? '';
        this.loading = true;
        return this.searchFunction(searchValue).pipe(
          map((response: any) => response?.results ?? [])
        );
      })
    ).subscribe((results: any) => {
      this.loading = false;
      this.data = results;
    });
  }
}
