import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { AccountFormComponent } from '../account-form/account-form.component';
import { SpinnerComponent } from '../../../../../components/spinner/spinner.component';
import SugarAccount from '../../../../../common/models/sugar/sugar-account';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import toClass from '../../../../../common/helpers/to-class';
import { ApiService } from '../../../../../services/api.service';
import { ToastService } from '../../../../../services/toast.service';

@Component({
  selector: 'sales-account-edit',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AccountFormComponent,
    SpinnerComponent,
  ],
  templateUrl: './account-edit.component.html',
  styleUrl: './account-edit.component.css'
})
export class AccountEditComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public processing: boolean = false;
  public sugarAccount: SugarAccount | null = null;

  @Output() public return: EventEmitter<void> = new EventEmitter<void>();
  public save: EventEmitter<void> = new EventEmitter<void>();

  public get accountData() {
    if (!this.sugarAccount) {
      return {};
    }
    
    return {
      id: this.sugarAccount?.id ?? '',
      name: this.sugarAccount?.name ?? '',
      primary_email: this.sugarAccount?.primary_email ?? '',
      type: this.sugarAccount?.type ?? '',
      account_grade: this.sugarAccount?.account_grade ?? '',
      sales_stage: this.sugarAccount?.sales_stage ?? '',
      phone_office: this.sugarAccount?.phone_office ?? '',
      guest_contact_category: this.sugarAccount?.guest_contact_category ?? '',
      account_note: this.sugarAccount?.account_note ?? '',
      account_support_team: this.sugarAccount?.account_support_team	 ?? '',
      forecast_opportunity_value: this.sugarAccount?.forecast_opportunity_value	 ?? '',
    };
  }

  constructor (
    private apiService: ApiService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(({data = {}}) => {
        const {
          sugarAccount = null,
        } = data || {};

        this.sugarAccount = sugarAccount;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSaveHandler() {
    this.save.emit();
  }

  onSubmitHandler(data: any) {
    this.processing = true;
    const sugarAccount = toClass(SugarAccount, data);

    const { id: sugarAccountId } = sugarAccount;
    if (!sugarAccountId) {
      this.processing = false;
      return;
    }

    this.apiService.updateAccount(sugarAccountId, data)
      .subscribe((response: any) => {
        const {
          success = false,
          info: { subject = 'Unexpected Error', message = 'Unexpected error occured' } = {},
        } = response;
        this.processing = false;

        if (!success) {
          this.toastService.add({
            severity: 'error',
            summary: subject,
            detail: message,
          });

          return;
        }

        this.toastService.add({
          severity: 'success',
          summary: subject,
          detail: message,
        });

        this.router.navigateByUrl('/sales/details');
      });
  }
}
