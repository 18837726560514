import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import SugarAccount from '../../../../../common/models/sugar/sugar-account';
import TableData from '../../../../../common/interfaces/table-data';
import { transformForTable } from '../../../../../common/helpers/transform-for-table';
import { SpinnerComponent } from '../../../../../components/spinner/spinner.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InlineEditNotesComponent } from "../../../../../components/inline-edit-notes/inline-edit-notes.component";
import { ApiService } from '../../../../../services/api.service';
import { ToastService } from '../../../../../services/toast.service';
import { map } from 'rxjs';

@Component({
  selector: 'sales-account-details',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    ButtonModule,
    InlineEditNotesComponent
],
  templateUrl: './account-details.component.html',
  styleUrl: './account-details.component.css'
})
export class AccountDetailsComponent implements OnInit {
  @Input() sugarAccount!: SugarAccount;
  @Input() inSync: boolean = false;
  @Input() unassignInProgress: boolean = false;

  @Output() unassignClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() editClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() syncClick: EventEmitter<void> = new EventEmitter<void>();

  sugarAccountNotes: string = '';
  savingNotes: boolean = false;


  public accountDetailsTableData: Array<TableData> = [];
  public get accountNoteTableDataField(): TableData {
    return { value: this.sugarAccountNotes ?? '', label: 'Notes' };
  }

  get sugarAccountId() {
    return this.sugarAccount?.id ?? null;
  }

  constructor (
    private apiService: ApiService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.accountDetailsTableData = transformForTable(this.sugarAccount, [
      {field: 'name', label: 'Account Name'},
      {field: 'primary_email', label: 'Email'},
      {field: 'type', label: 'Type'},
      {field: 'phone_office', label: 'Office Phone'},
      {field: 'account_grade', label: 'Account Size'},
      {field: 'sales_stage', label: 'Sales Stage'},
    ]);
    this.sugarAccountNotes = this.sugarAccount?.account_note ?? '';
  }

  onAccountNotesUpdate(notes: string) {
    if (!this.sugarAccountId) {
      return;
    }
    
    
    this.savingNotes = true;
    this.apiService.updateAccountNotes(this.sugarAccountId, notes).pipe(
      map((data: any) => {
        const {
          success = false,
          info: {
            subject = 'Unexpected Error',
            message = 'Unexpected error occured',
          } = {},
          data: notes = '',
        } = data;

        this.toastService.add({
          severity: (success ? 'success' : 'error'),
          summary: subject,
          detail: message,
        });
        
        return notes;
      })
    ).subscribe((notes: string) => {
      this.savingNotes = false;
      this.sugarAccountNotes = notes ?? '';
    });
  }

  onUnassignClick() {
    this.unassignClick.emit();
  }

  onSyncClick() {
    this.syncClick.emit();
  }

  onEditClick() {
    this.editClick.emit();
  }
}
