import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CasesService } from '../services/cases.service';
import { catchError, of, take } from 'rxjs';
import SugarCase from '../common/models/sugar/sugar-case';

export const currentCaseResolver: ResolveFn<SugarCase | null> = (route, state) => {
  const casesService = inject(CasesService);

  casesService.clearCurrentCase();
  return casesService.loadCurrentSugarCase()
    .pipe(take(1), catchError((error) => {
      console.error('Error loading sugar case: ', error);
      return of(null);
    }));
};
