import { HttpEvent, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { map } from 'rxjs';

export const responseInfoInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToastService);
  
  return next(req).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const {
          responseInfo: {
            subject = 'Unexpected Error',
            message = 'Unexpected error occured',
            type = 'error',
          } = {}
        } = event.body ?? {};
        
        if (event.body.responseInfo) {
          toastService.add({
            summary: subject,
            detail: message,
            severity: type,
          });
          
          delete event.body.responseInfo;
        }
      }

      return event;
    })
  );
};
