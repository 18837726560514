<div class="navigation-bar" [style]="backgroundColorCSS" (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave()">
  <ng-container [ngSwitch]="navigationType">
    <ng-container *ngSwitchCase="'button-scroll'">
      <ng-container  *ngTemplateOutlet="buttonScroll"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="autoScroll"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #autoScroll>
  <ul class="navigation" (scrollend)="onScrollEnd()" cdkScrollable>
    <li [id]="item.id" *ngFor="let item of items"  #trackedLink>
      <div class="item-label">{{ item.label }}</div>
      <div class="item-underline" [ngClass]="{'active': item['activatedOnUrls'].includes(activeUrl)}"></div>
    </li>
  </ul>
</ng-template>

<ng-template #buttonScroll>
  <div class="scroll-button">
    <p-button [text]="true" class="rounded-full" (onClick)="scrollLeft()">
      <ng-icon [name]="'phosphorCaretLeftFill'"></ng-icon>
    </p-button>

    <div class="side-cover left"></div>
  </div>

  <ul class="navigation" cdkScrollable>
    <li [id]="item.id" *ngFor="let item of items" (click)="onItemClick(item.url!)" #trackedLink>
      <div class="item-label">{{ item.label }}</div>
      <div class="item-underline" [ngClass]="{'active': item['activatedOnUrls'].includes(activeUrl)}"></div>
    </li>
  </ul>

  <div class="scroll-button">
    <p-button [text]="true" class="rounded-full" (onClick)="scrollRight()">
      <ng-icon [name]="'phosphorCaretRightFill'"></ng-icon>
    </p-button>

    <div class="side-cover right"></div>
  </div>
</ng-template>