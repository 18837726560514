import { AbstractControl, ValidationErrors } from "@angular/forms";
import { parsePhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";

export class CustomValidators {
  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value?.length === 0) {
      return null;
    }

    const phoneNumberLengthValidation = validatePhoneNumberLength(control?.value);
    if (typeof phoneNumberLengthValidation !== 'undefined') {
      return { phoneNumber: true };
    }

    if (typeof phoneNumberLengthValidation === 'undefined' && !parsePhoneNumber(control.value).isValid()) {
      return { phoneNumber: true };
    }

    return null;
  }
}
