import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';

interface ServerToClientEvents {
  userList: (data: string[]) => void;
}

interface ClientToServerEvents {
  userJoined: (username: string) => void;
  userLeft: (username: string) => void;
  ping: (username: string) => void;
}

@Component({
  selector: 'active-viewers',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './active-viewers.component.html',
  styleUrl: './active-viewers.component.css'
})
export class ActiveViewersComponent implements OnInit, OnDestroy {
  protected socket: Socket<ServerToClientEvents, ClientToServerEvents> | null = null;

  @Input()
  gateway: string = '';

  public users: string[] = [];


  public get username(): string {
    return this.userService.getName();
  }

  constructor (
    private userService: UserService,
  ) {}

  ngOnDestroy(): void {
    this.socket!.close();
    this.socket = null;
  }

  ngOnInit(): void {
    this.socket = io(`${environment.apiUrl}/settings`);

    this.socket.on('connect', () => {
      this.socket!.emit('userJoined', this.username);
    })

    this.socket.on('userList', (data: string[]) => {
      this.users = data;
      this.sendPing();
    });

    this.socket.on('disconnect', () => {
      setTimeout(() => this.reconnect(), 3000);
    });

    this.socket.on('connect_error', (data: any) => {
      console.error('[FIS] Websocket connection error!', data)
      setTimeout(() => this.socket!.connect(), 3000);
    })
  }

  sendPing() {
    this.socket!.emit('ping', this.username);
  }

  reconnect() {
    if (this.socket) {
      this.socket!.connect();
    }
  }
}
