import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-pill',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './status-pill.component.html',
  styleUrl: './status-pill.component.css'
})
export class StatusPillComponent {
  static CLOSED = 'CLOSED';
  static PENDING = 'PENDING';
  static NEW = 'NEW';

  private _status: string = '';

  @Input()
  public set status(value: string) {
    this._status = value.toUpperCase();
  }

  public get status() {
    return this._status;
  }

  public get colorClass(): string {
    switch (this.status) {
      case 'CLOSED':
        return 'bg-[#e13800]';

      case 'PENDING':
        return 'bg-[#9e9e9e]';

      case 'NEW':
        return 'bg-[#45c525]';

      default:
        return 'bg-[#9e9e9e]';
    }
  }
}
