import shortUUID from "short-uuid";
import FrontLink from "./front-link";
import { SugarLink } from "./sugar-link";

export class Contact {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  primaryEmail?: string;
  secondaryEmail?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  mobileSecondaryPhoneNumber?: string;
  partialMatch?: boolean;

  frontLink: FrontLink = new FrontLink(this);
  sugarLink: SugarLink = new SugarLink(this);

  constructor () {
    this.id = shortUUID().generate();
  }

  updateFromFrontRecipient() {
    if (this.frontLink.recipient) {
      const email = this.frontLink.recipient.getEmail();
      if (email) {
        this.primaryEmail = email;
      }

      const phoneNumber = this.frontLink.recipient.getPhoneNumber();
      if (phoneNumber) {
        this.phoneNumber = phoneNumber;
      }

      const name = this.frontLink.recipient.getName();
      if (name) {
        this.name = name;
      }

      if (name.split(' ').length >= 2) {
        const { [0]: firstName, [1]: lastName } = name.split(' ');
        if (firstName && lastName) {
          this.firstName = firstName;
          this.lastName = lastName;
        }
      }
    }
  }

  updateFromSugarContact() {
    if (this.sugarLink.contact) {
      const {
        name,
        first_name: firstName,
        last_name: lastName,
        primary_email: primaryEmail,
        secondary_email: secondaryEmail,
        phone_work: phoneNumber,
        phone_mobile: mobilePhoneNumber,
        phone_mobile_2: mobileSecondaryPhoneNumber,
      } = this.sugarLink.contact;

      if (name) {
        this.name = name;
      }

      if (firstName) {
        this.firstName = firstName;
      }

      if (lastName) {
        this.lastName = lastName;
      }

      if (primaryEmail) {
        this.primaryEmail = primaryEmail;
      }

      if (secondaryEmail) {
        this.secondaryEmail = secondaryEmail;
      }
      
      if (phoneNumber) {
        this.phoneNumber = phoneNumber;
      }

      if (mobilePhoneNumber) {
        this.mobilePhoneNumber = mobilePhoneNumber;
      }

      if (mobileSecondaryPhoneNumber) {
        this.mobileSecondaryPhoneNumber = mobileSecondaryPhoneNumber;
      }
    }
  }
}