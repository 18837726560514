import { Injectable, OnDestroy } from '@angular/core';
import { FrontService } from './front.service';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import Message from '../common/models/message';
import { FrontRecipient } from '../common/models/front-recipient';
import compareObjects from '../common/helpers/compare-objects';
import RecipientType from '../common/interfaces/recipient-type';
import { ApiService } from './api.service';
import SugarContact from '../common/models/sugar/sugar-contact';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { ContactMatchResult } from '../common/interfaces/contact-match-result';
import { Contact } from '../common/models/fis/contact';
import { ContactSearchResultDto } from '../common/dto/response/contact-search-result.dto';
import toClass from '../common/helpers/to-class';
import{ sortContactsBySugarContact } from '../common/helpers/sort-sugar-contacts';
import { isValidNumber } from "libphonenumber-js";

@Injectable({
  providedIn: 'root'
})
export class ContactsService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  
  private _recipients: FrontRecipient[] = [];
  private _sugarContactsLoaded: boolean = false;
  private _sugarContacts: SugarContact[] = [];

  public contacts: Map<string, Contact> = new Map<string, Contact>();

  protected set sugarContactsLoaded(value: boolean) {
    this._sugarContactsLoaded = value;
    this.sugarContactsLoaded$.next(value);
  }
  public get sugarContactsLoaded(): boolean {
    return this._sugarContactsLoaded;
  }

  public get hasPartialMatches(): boolean {
    return this.getContacts()
      .filter((contact: Contact) => contact.partialMatch)
      .length > 0;
  }

  public set sugarContacts(sugarContacts: SugarContact[]) {
    this._sugarContacts = sugarContacts;
  }
  public get sugarContacts(): SugarContact[] {
    const sugarContacts = [];

    for (let contact of [...this.contacts.values()]) {
      if (contact.sugarLink.contact) {
        sugarContacts.push(contact.sugarLink.contact);
      }
    }

    return sugarContacts;
  }

  public get sugarContactIds(): string[] {
    return this.sugarContacts
      .map((sugarContact: SugarContact) => sugarContact.id);
  }

  public set recipients(recipients: FrontRecipient[]) {
    const pbEmailDomains = environment.pbEmailDomains;
    const filteredRecipients = recipients
      .filter((recipient: FrontRecipient) => recipient.type === RecipientType.email || isValidNumber(recipient.handle))
      .filter((recipient: FrontRecipient) => !pbEmailDomains.includes(recipient.handle.split('@')[1]))
      .reduce((acc: FrontRecipient[], recipient: FrontRecipient) => {
        if (!acc.some(e => e.handle === recipient.handle)) {
          acc.push(recipient);
        }

        return acc;
      }, []);

    if (!compareObjects(this.recipients, filteredRecipients)) {
      this.sugarContactsLoaded = false;
      this.selectedContactId = null;
      this._recipients = filteredRecipients;
      this.recipients$.next(this.recipients);
      (async () => await this.reloadSugarContacts())();
    }
  }

  public get recipients(): FrontRecipient[] {
    return this._recipients;
  }

  private _selectedContactId: string | null = null;
  public set selectedContactId(value: string | null) {
    this._selectedContactId = value;
    this.selectedContactId$.next(value);

    const sugarContact = this.selectedContact?.sugarLink?.contact || null;
    this.selectedSugarContactBehaviourSubject$.next(sugarContact);
    this.selectedContactId$.next(sugarContact?.id || null);
  }

  public get selectedContactId(): string | null {
    return this._selectedContactId;
  }

  public get selectedContact(): Contact | null {
    const selectedContactId = this.selectedContactId;
    if (!selectedContactId) {
      return null;
    }

    return this.contacts.get(selectedContactId) || null;
  }

  public get selectedSugarContact(): SugarContact | null {
    return this.selectedContact?.sugarLink?.contact || null;
  }

  public get selectedSugarContactId(): string | null {
    return this.selectedSugarContact?.id || null;
  }

  private selectedSugarContactBehaviourSubject$: BehaviorSubject<SugarContact | null> = new BehaviorSubject<SugarContact | null>(null);
  private sugarContactsBehaviourSubject$: BehaviorSubject<SugarContact[]> = new BehaviorSubject<SugarContact[]>([]);

  public sugarContacts$: Observable<SugarContact[]> = this.sugarContactsBehaviourSubject$.asObservable();
  public selectedSugarContact$: Observable<SugarContact | null> = this.selectedSugarContactBehaviourSubject$.asObservable();
  public selectedContactId$: Subject<string | null> = new Subject<string | null>();

  public selectedRecipient$: Subject<FrontRecipient | null> = new Subject<FrontRecipient | null>();
  public sugarContactsLoaded$: Subject<boolean> = new Subject<boolean>();
  public sugarContactsLoading$: Subject<boolean> = new Subject<boolean>();
  public recipients$: Subject<FrontRecipient[]> = new Subject<FrontRecipient[]>();

  private _sugarContactsLoading: boolean = false;
  public set sugarContactsLoading(value: boolean) {
    this._sugarContactsLoading = value;
    this.sugarContactsLoading$.next(value);
  }

  public get sugarContactsLoading(): boolean {
    return this._sugarContactsLoading;
  }

  constructor(
    private frontService: FrontService,
    private apiService: ApiService,
    private toastService: ToastService,
  ) {
    this.frontService.messages$
      .pipe(takeUntil(this.destroy$))
      .subscribe((messages: Message[]) => {
        this.recipients = messages.reduce((acc: FrontRecipient[], message: Message) => {
          acc.push(...message.getRecipients());
          return acc;
        }, []);
      });

    this.frontService.contextType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((type: string) => {
        if (type !== 'singleConversation') {
          this.recipients = [];
        }

        this.reloadSugarContacts();
      });

    this.sugarContactsLoaded$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedContactId = this.getDefaultContact()?.id || null;
      });

    this.sugarContacts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((sugarContacts: SugarContact[]) => {
        this.sugarContacts = sugarContacts;
      });
  }

  getSugarContactById(sugarContactId: string) {
    return this.sugarContacts
      .filter((sugarContact: SugarContact) => sugarContact.id === sugarContactId)[0] ?? null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getContacts(): Contact[] {
    return [...this.contacts.values()]
  }

  getSortedContacts() {
    const matched = [];
    const partialMatch = [];
    const notMatched = [];
    for (let contact of this.getContacts()) {
      if (contact.sugarLink?.contact) {
        matched.push(contact);
        continue;
      }

      if (contact.partialMatch) {
        partialMatch.push(contact)
        continue;
      }

      notMatched.push(contact);
    }

    return [...sortContactsBySugarContact(matched), ...partialMatch, ...notMatched];
  }

  getDefaultContact() {
    return this.getSortedContacts()[0] || null;
  }

  clearContacts() {
    this.contacts = new Map<string, Contact>();
    this._sugarContactsLoaded = false;
  }

  public reloadSugarContacts(): Promise<any> {
    this.sugarContactsLoaded = false;
    return this.loadSugarContacts();
  }
  
  protected loadSugarContacts(): Promise<void> {
    return new Promise(async (resolve) => {
      if (!this.sugarContactsLoaded) {
        this.clearContacts();
        
        const queryData = [];
        for (let recipient of this.recipients) {
          const newContact = new Contact();
          newContact.frontLink.recipient = recipient;
          newContact.updateFromFrontRecipient();
          this.contacts.set(newContact.id, newContact);

          if (newContact.phoneNumber || newContact.primaryEmail || newContact.secondaryEmail) {
            const handle = (newContact.phoneNumber ? newContact.phoneNumber : newContact.primaryEmail ? newContact.primaryEmail : newContact.secondaryEmail) || '';

            if (handle.length > 0) {
              queryData.push({ id: newContact.id, handle: handle, name: newContact?.name || '' });
            }
          }

          // recipientContact needs improving - now it's using legacy class
          const { contact: recipientContact } = recipient;
          if (recipientContact && recipientContact.getEmails().length > 0) {
            for (let recipientContactEmail of recipientContact.getEmails()) {
              if (newContact?.primaryEmail != recipientContactEmail && newContact?.secondaryEmail != recipientContactEmail) {
                const altContact = new Contact();
                altContact.name = recipientContact.name;
                altContact.primaryEmail = recipientContactEmail;
                this.contacts.set(altContact.id, altContact);

                queryData.push({ id: altContact.id, handle: recipientContactEmail, name: recipientContact.name });
              }
            }
          }
        }
  
        if (queryData.length > 0) {
          this.sugarContactsLoading = true;
          this.apiService.findSugarContacts(queryData)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: ContactMatchResult[]) => {
              if (Array.isArray(data)) {
                this.applyContactMatchResults(data);
              }
              
              this.sugarContactsLoading = false;
              this.sugarContactsLoaded = true;
              resolve();
            });
        }
      }

      this.sugarContactsLoaded = false;
      resolve();
    });
  }

  protected applyContactMatchResults(data: ContactSearchResultDto[]) {
    const matchedContactIdsAssigned: string[] = [];
    const sugarContacts = [];
    for (let matchResult of data) {
      const { id = '', match = [], name_match = false } = matchResult;
      const contact = this.contacts.get(id);

      if (contact) {
        for (let index = 0; index < match.length; index++) {
          if (!matchedContactIdsAssigned.includes(match[index].id)) {
            const sugarContact = toClass(SugarContact, match[index]);
            sugarContacts.push(sugarContact);

            if (index === 0) {
              contact.sugarLink.contact = sugarContact
              contact.updateFromSugarContact();
              matchedContactIdsAssigned.push(match[index].id);
              continue;
            }
  
            const newContact = new Contact();
            newContact.sugarLink.contact = sugarContact;
            newContact.updateFromSugarContact();
            this.contacts.set(newContact.id, newContact);
            matchedContactIdsAssigned.push(match[index].id);
          }
        }

        if (name_match) {
          contact.partialMatch = true;
        }
      }
    }

    this.sugarContactsBehaviourSubject$.next(sugarContacts);
  }
}
