import { EventEmitter, Injectable } from '@angular/core';
import shortUUID from 'short-uuid';
import Toast from '../common/interfaces/toast';
import { Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _toasts: Array<Toast> = [];
  private _defaultDisplayTime: number = 3;

  public get toasts(): Array<Toast> {
    return this._toasts;
  }
  
  private _messageService!: MessageService;
  set messageService(service: MessageService) {
    if (!this._messageService) {
      this._messageService = service;
    }
  }

  get messageService(): MessageService {
    return this._messageService;
  }

  public toastsChanged$: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  add(message: Message): void {
    this.messageService.add(message);
  }

  addAll(messages: Message[]) {
    this.messageService.addAll(messages);
  }

  clear(key?: string): void {
    this.messageService.clear(key);
  }

  /**
   * @deprecated
   */
  addToast(message: string, type: string = 'success', displayTime: number = this._defaultDisplayTime) {
    const toastId = this.generateRandomID();
    const toast = {
      id: toastId,
      message: message,
      type: type
    };

    this._toasts.push(toast);
    this.toastsChanged$.emit();
    setTimeout(() => this.removeToast(toastId), displayTime * 1000);

    return toastId;
  }


  /**
   * @deprecated
   */
  removeToast(id: string) {
    this._toasts = this._toasts.filter((toast: any) => toast.id !== id);
    this.toastsChanged$.emit();
  }

  /**
   * @deprecated
   */
  private generateRandomID(): string {
    return shortUUID.generate();
  }
}
