<nav
  (mouseleave)="dragEnd()"
  (mousemove)="dragMove($event)"
  (mouseup)="dragEnd()"
  class="bg-[#f9f9f9] border-gray-200 lg:px-6 order-1 border-b"
>

  <div class="row w-full" *ngIf="contactPills.length > 1 && !contactsLoading">
    <div class="col pt-1 px-3">
      <contact-selector (onContactSelect)="onContactSelectHandler($event)" [selectedContactId]="selectedContactId" [contactPills]="contactPills" />
    </div>
  </div>

  <fis-navigation-bar (onLinkClick)="navigate($event)" [items]="navigationData" [activeUrl]="currentUrl" [backgroundColor]="'#f9f9f9'" [navigationType]="'button-scroll'"></fis-navigation-bar>
</nav>
