import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Observable, of } from 'rxjs';
import { ContactsService } from '../services/contacts.service';

export const reservationsListResolver: ResolveFn<boolean> = (route, state): Observable<any> => {
  const apiService = inject(ApiService);
  const contactsService = inject(ContactsService);

  if (!contactsService.selectedSugarContactId) {
    return of(null);
  }

  return apiService.getReservations(contactsService.selectedSugarContactId);
};
