import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderService } from '../../../../services/loader.service';
import { FormContactCreateComponent } from '../forms/form-contact-create/form-contact-create.component';
import ContactFormSchema from '../../../../common/interfaces/contact-form-schema';
import { DataService } from '../../../../services/data.service';
import { Contact } from '../../../../common/models/fis/contact';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-contact-create',
  standalone: true,
  imports: [
    CommonModule,
    FormContactCreateComponent,
  ],
  templateUrl: './contact-create.component.html',
  styleUrl: './contact-create.component.css'
})
export class ContactCreateComponent {
  @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

  @Input() public contact: Contact | null = null;

  public get formData(): ContactFormSchema {
    if (!this.contact) return {};

    const {
      firstName = '',
      lastName = '',
      primaryEmail = '',
      secondaryEmail = '',
      phoneNumber = '',
      mobilePhoneNumber = '',
      mobileSecondaryPhoneNumber = '',
    } = this.contact;

    return { firstName, lastName, primaryEmail, secondaryEmail, workPhoneNumber: phoneNumber, mobilePhoneNumber, mobileSecondaryPhoneNumber };
  }

  public error: boolean = false;
  public errorMessage: string = '';
  public success: boolean = false;
  public successMessage: string = '';

  private _processingData: boolean = false;
  public set processingData(value: boolean) {
    this._processingData = value;
  }

  public get processingData(): boolean {
    return this._processingData;
  }

  public get loading(): boolean {
    return this.loaderService.loading;
  }

  public get contactTypes() {
    const contactTypes = this.dataService.contactTypeEnums;
    if (!contactTypes) {
      return [];
    }

    return Object.keys(contactTypes)
      .map((key: string) => ({ value: key, label: (contactTypes[key].length > 0 ? contactTypes[key] : 'Choose contact type') }));
  }

  constructor (
    private dataService: DataService,
    private loaderService: LoaderService,
    private apiService: ApiService,
  ) {}

  reformatNumber(phoneNumber: string): string {
    return (phoneNumber.length > 0 ? parsePhoneNumber(phoneNumber)?.formatInternational() : '')
  }

  public onSubmit(data: any) {
    this.processingData = true;
    this.apiService.createContact({
      first_name: data?.firstName || undefined,
      last_name: data?.lastName || undefined,
      primary_email: data?.primaryEmail || undefined,
      secondary_email: data?.secondaryEmail || undefined,
      phone_work: this.reformatNumber(data?.mobilePhoneNumber || '') || undefined,
      phone_mobile: this.reformatNumber(data?.mobilePhoneNumber || '') || undefined,
      phone_mobile_2: this.reformatNumber(data?.mobileSecondaryPhoneNumber || '') || undefined,
      contact_type: data?.contactType?.value || undefined,
      account_id: data?.account?.id || undefined,
    }).subscribe(() => {
      this.processingData = false;
      this.onSave.emit();
    });
  }
}
