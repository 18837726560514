import { Exclude, Expose } from "class-transformer";

@Exclude()
export default class Content {
  @Expose()
  body!: string;

  @Expose()
  type!: string;

  @Expose()
  attachments!: any[];
}
