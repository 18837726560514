<div *ngIf="!loading">
  <div class="p-5">
    <div class="text-2xl font-bold text-[#4ec72f] pb-5 w-full text-center">
      In-house Reservation
    </div>

    <div class="text-center py-3 text-gray-300" *ngIf="currentReservationsData.length === 0">
      There is no current reservation.
    </div>

    <div *ngFor="let reservationData of currentReservationsData">
      <app-reservation-card [id]="reservationData.id" [data]="reservationData" [active]="true"></app-reservation-card>
    </div>
  </div>

  <div class="w-full p-5 bg-[#f2f2f2] border-t-[1px] border-[#e2e5e8]">
    <div class="text-2xl font-bold text-[#579ece] pb-5 w-full text-center">
      Future Reservations
    </div>

    <div class="text-center py-3 text-gray-300" *ngIf="futureReservationsData.length === 0">
      There is no future reservations.
    </div>

    <div *ngFor="let reservationData of futureReservationsData">
      <app-reservation-card [id]="reservationData.id" [data]="reservationData"></app-reservation-card>
    </div>
  </div>

  <div class="w-full px-5 pt-5 pb-10 bg-[#f2f2f2] border-t-[1px] border-[#e2e5e8]">
    <div class="text-2xl font-bold text-[#ababab] pb-5 w-full text-center">
      Previous Reservations
    </div>

    <div class="text-center py-3 text-gray-300" *ngIf="previousReservationsData.length === 0">
      There is no previous reservations.
    </div>

    <div *ngFor="let reservationData of previousReservationsData">
      <app-reservation-card [id]="reservationData.id" [data]="reservationData"></app-reservation-card>
    </div>
  </div>
</div>
