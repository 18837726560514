import { Injectable, OnDestroy } from '@angular/core';
import SugarCase from '../common/models/sugar/sugar-case';
import { ApiService } from './api.service';
import { catchError, Observable, Observer, of, Subject, take, takeUntil } from 'rxjs';
import { FrontService } from './front.service';
import toClass from '../common/helpers/to-class';
import { ContactsService } from './contacts.service';
import SugarContact from '../common/models/sugar/sugar-contact';
import compareObjects from '../common/helpers/compare-objects';
import { MessageService } from 'primeng/api';

export enum CaseCreationStatus {
  none = 0,
  error = 1,
  creating = 2,
  created = 3,
}

@Injectable({
  providedIn: 'root'
})
export class CasesService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  /* Getters and setters */
  private _caseNumber: string | null = null;
  set caseNumber(value: string | null) {
    if (this.caseNumber !== value) {
      this.clearAll();
      this._caseNumber = value; 
      this.caseNumberSubject.next(this.caseNumber);
    }
  }
  get caseNumber(): string | null {
    return this._caseNumber;
  }

  private _currentCase: SugarCase | null = null;
  set currentCase(value: SugarCase | null) {
    if (
      (!this.currentCase && value) ||
      (this.currentCase && !value) ||
      (this.currentCase && value && !compareObjects(this.currentCase, value))
    ) {
      this._currentCase = value;
      this.currentCaseSubject.next(this.currentCase);
    }
  }
  get currentCase(): SugarCase | null {
    return this._currentCase;
  }

  private _currentCaseId: string | null = null;
  set currentCaseId(value: string | null) {
    if (this._currentCaseId !== value) {
      this._currentCaseId = value;
      this.currentCaseIdSubject.next(this.currentCaseId);
    }
  }
  get currentCaseId(): string | null {
    return this._currentCaseId;
  }

  private _caseCountData: any = null;
  set caseCountData(value: any) {
    if (!compareObjects(this.caseCountData, value)) {
      this._caseCountData = value;
      this.caseCountDataSubject.next(this.caseCountData);
    }
  }
  get caseCountData(): any {
    return this._caseCountData;
  }

  /* Subjects and observables */
  private caseNumberSubject = new Subject<string | null>();
  private currentCaseSubject = new Subject<SugarCase | null>();
  private currentCaseIdSubject = new Subject<string | null>();
  private caseCountDataSubject = new Subject<any>();

  public caseNumber$ = this.caseNumberSubject.asObservable();
  public currentCase$ = this.currentCaseSubject.asObservable();
  public currentCaseId$ = this.currentCaseIdSubject.asObservable();
  public caseCountData$ = this.caseCountDataSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private frontService: FrontService,
    private contactsService: ContactsService,
    private messageService: MessageService,
  ) {
    this.frontService.frontConversation$
      .pipe(takeUntil(this.destroy$))
      .subscribe((frontConversationData: any) => {
        const { id = null, links = [] } = frontConversationData;

        const caseNumber = links.filter((linkData: any) => /^CASE-[0-9]+$/g.test(linkData.name))
          .map((linkData: any) => linkData.name.split('-')[1])[0] || null;
        this.caseNumber = caseNumber;
      });

    this.caseNumber$
      .pipe(takeUntil(this.destroy$))
      .subscribe((caseNumber: string | null) => {
        if (caseNumber) {
          this.loadCurrentSugarCase();
        }
      });

    this.contactsService.selectedSugarContact$
      .pipe(takeUntil(this.destroy$))
      .subscribe((sugarContact: SugarContact | null) => {
        if (sugarContact) {
          const {
            id: sugarContactId,
            current_reservation_id: sugarCurrentReservationId = null
          } = sugarContact;

          this.clearCaseCountData();
          this.loadCaseCountData(sugarContactId, sugarCurrentReservationId)
            .pipe(take(1))
            .subscribe();
        } else {
          this.clearAll();
        }
      });
  }
  
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  createCase(conversationId: string, contactId: string | null, statusCallback: (status: CaseCreationStatus) => void) {
    statusCallback(CaseCreationStatus.creating);
    this.apiService.createSugarCase(conversationId, contactId)
    .pipe(take(1))
    .pipe(catchError((err: any) => {
      this.messageService.add({ severity: 'error', summary: 'Unexpected Error', detail: 'Unexpected error occured.' });
      statusCallback(CaseCreationStatus.error);

      return of(null);
    })).subscribe((sugarCase: any) => {
      if (sugarCase) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Case has been created.' });
        statusCallback(CaseCreationStatus.created);
      }
    });
  }

  loadCurrentSugarCase(): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      if (!this.currentCase && this.caseNumber) {
        this.apiService.findCaseByNumber(this.caseNumber)
          .pipe(take(1))
          .subscribe({
            next: (response: any) => {
              const { data = null } = response;
              this.currentCase = toClass(SugarCase, data);
              observer.next(this.currentCase);
            },
            error: (error) => {
              this.currentCase = null;
              observer.error(error);
            }
          });
      } else {
        observer.next(this.currentCase);
        observer.complete();
      }
    });
  }

  loadCaseCountData(sugarContactId: string, sugarCurrentReservationId: string | null): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      if (!this.caseCountData) {
        this.apiService.getCaseCount(sugarContactId, sugarCurrentReservationId)
          .pipe(take(1))
          .subscribe({
            next: (data: any) => {
              this.caseCountData = data;
              observer.next(this.caseCountData);
            },
            error: (error) => {
              observer.error(error);
            }
          });
      } else {
        observer.next(this.caseCountData);
        observer.complete();
      }
    });
  }

  reloadCaseCountData() {
    const {
      id: sugarContactId,
      current_reservation_id: sugarCurrentReservationId = null,
    } = this.contactsService.selectedSugarContact || {};

    this.clearCaseCountData();
    if (sugarContactId) {
      this.loadCaseCountData(sugarContactId, sugarCurrentReservationId)
        .pipe(take(1))
        .subscribe();
    }
  }

  clearCaseCountData() {
    this.caseCountData = null;
  }

  clearCurrentCase() {
    this.currentCase = null;
  }

  clearAll() {
    this.clearCurrentCase();
    this.clearCaseCountData();
  }
}
