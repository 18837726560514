
<table class="w-full" [cellPadding]="4">
  <tr>
    <th>
      <label for="name" class="block w-40 text-sm font-medium text-[#ababab]">Name:</label>
    </th>
    <td>
      <div class="text-red-600 text-sm">
        <div *ngIf="nameValue.errors?.['required']">
          This field is required.
        </div>
        <div *ngIf="nameValue.errors?.['minlength']">
          Name must have at least 3 characters
        </div>
      </div>
      <input class="w-full" type="text" pInputText [(ngModel)]="name" name="name" variant="filled" minlength="3" required #nameValue="ngModel" />
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Type:</label>
    </th>
    <td>
      <div class="text-red-600 text-sm">
        <div *ngIf="opportunityTypeValue.errors?.['required']">
          This field is required.
        </div>
      </div>
      <p-dropdown
        required
        styleClass="w-full"
        variant="filled"
        [options]="accountTypeEnums"
        [(ngModel)]="opportunity_type"
        name="opportunity_type"
        optionLabel="label"
        dataKey="value"
        placeholder="Select opportunity type"
        #opportunityTypeValue="ngModel" />
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Next Action Due:</label>
    </th>
    <td>
      <div class="text-red-600 text-sm">
        <div *ngIf="nextActionDueValue.errors?.['required']">
          This field is required.
        </div>
      </div>
      <p-calendar
        required
        styleClass="w-full"
        variant="filled"
        [showIcon]="true"
        iconDisplay="input"
        [(ngModel)]="date_next_action_due"
        name="date_next_action_due" 
        dateFormat="yy-mm-dd"
        #nextActionDueValue="ngModel"/>
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Expected Close Date:</label>
    </th>
    <td>
      <div class="text-red-600 text-sm">
        <div *ngIf="dateClosedValue.errors?.['required']">
          This field is required.
        </div>
      </div>
      <p-calendar
        required
        styleClass="w-full"
        variant="filled"
        [showIcon]="true"
        iconDisplay="input"
        [(ngModel)]="date_closed" 
        name="date_closed"
        dateFormat="yy-mm-dd"
        #dateClosedValue="ngModel" />
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Sales Stage:</label>
    </th>
    <td>
      <div class="text-red-600 text-sm">
        <div *ngIf="salesStageValue.errors?.['required']">
          This field is required.
        </div>
      </div>
      <p-dropdown
        required
        styleClass="w-full"
        variant="filled"
        [options]="salesStageEnums"
        [(ngModel)]="sales_stage"
        name="sales_stage" 
        optionLabel="label"
        dataKey="value"
        placeholder="Select sales stage"
        #salesStageValue="ngModel" />
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Portland Opportunity Value:</label>
    </th>
    <td>
      <div class="text-red-600 text-sm">
        <div *ngIf="amountValue.errors?.['required']">
          This field is required.
        </div>
        <div *ngIf="amountValue.errors?.['min']">
          Value must be higher than 0.
        </div>
      </div>
      <p-inputNumber
        required
        min="1"
        class="w-full"
        styleClass="w-full"
        [(ngModel)]="amount"
        name="amount"
        variant="filled"
        inputId="currency-gb"
        mode="currency"
        currency="GBP"
        locale="en-GB"
        #amountValue="ngModel" />
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Global Opportunity Value:</label>
    </th>
    <td>
      <p-inputNumber
        class="w-full"
        styleClass="w-full"
        name="globalopportunityvalue"
        [(ngModel)]="globalopportunityvalue"
        variant="filled"
        inputId="currency-gb" 
        mode="currency" 
        currency="GBP" 
        locale="en-GB" /> 
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Probability:</label>
    </th>
    <td>
      <p-inputNumber
        class="w-full"
        styleClass="w-full"
        variant="filled"
        [(ngModel)]="probability" 
        inputId="percent"
        suffix="%" /> 
    </td>
  </tr>

  <tr>
    <th class="w-40">
      <label class="block w-40 text-sm font-medium text-[#ababab]">Assigned to:</label>
    </th>
    <td>
      <p-autoComplete
        class="w-full"
        styleClass="w-full"
        [(ngModel)]="inputAssignedUser.selected" 
        [suggestions]="inputAssignedUser.list" 
        (completeMethod)="inputAssignedUser.search($event)"
        variant="filled"
        field="name">
        <ng-template let-user pTemplate="item">
          <div class="flex align-items-center gap-2">
            <span>{{ user.name }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div *ngIf="inputAssignedUser.hasMore" class="flex flex-col align-items-center px-3 pb-4 pt-2 text-center text-xs text-gray-400">
            <div>There is more records than could be displayed...</div>
          </div>
        </ng-template>
      </p-autoComplete>
    </td>
  </tr>

  <tr>
    <th class="w-40 items-center">
      <label class="w-40 text-sm font-medium text-[#ababab]">Decision Maker:</label>
    </th>
    <td class="block align-middle">
      <p-autoComplete
        class="w-full"
        styleClass="w-full"
        [(ngModel)]="inputDecisionMaker.selected"
        [suggestions]="inputDecisionMaker.list"
        (completeMethod)="inputDecisionMaker.search($event)"
        variant="filled"
        field="name">
        <ng-template let-user pTemplate="item">
          <div class="flex align-items-center gap-2">
            <span>{{ user.name }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div *ngIf="inputDecisionMaker.hasMore" class="flex flex-col align-items-center px-3 pb-4 pt-2 text-center text-xs text-gray-400">
            <div>There is more records than could be displayed...</div>
          </div>
        </ng-template>
      </p-autoComplete>
    </td>
  </tr>
</table>
