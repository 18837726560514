import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
export default class SugarOpportunity {
  @Expose()
  id?: string;

  @Expose()
  name?: string;

  @Expose()
  modified_user_id?: string;

  @Expose()
  modified_by_name?: string;

  @Expose()
  description?: string;

  @Expose()
  opportunity_type?: string;

  @Expose()
  account_name?: string;

  @Expose()
  lead_source?: string;

  @Expose()
  @Transform(({value}) => parseFloat(parseFloat(`${value}`).toFixed(2)))
  amount?: number;

  @Expose()
  amount_usdollar?: string;

  @Expose()
  date_closed?: string;

  @Expose()
  date_closed_timestamp?: number;

  @Expose()
  next_step?: string;

  @Expose()
  sales_stage?: string;

  @Expose()
  sales_status?: string;

  @Expose()
  @Transform(({value}) => value || '')
  probability?: string;

  @Expose()
  assigned_user_id?: string;

  @Expose()
  date_next_action_due?: string;

  @Expose()
  assigned_user_name?: string;

  @Expose({ name: 'globalopportunityvalue_c' })
  @Transform(({value}) => (typeof value === "number" ? parseFloat(`${value}`).toFixed(2) : value))
  globalopportunityvalue?: string;

  @Expose({ name: 'decisionmaker' })
  decisionmaker?: string;

  @Expose()
  decisionmaker_id?: string;
  
  @Expose()
  decisionmaker_name?: string;
}
