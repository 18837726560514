<custom-card [active]="active">
  <table class="w-full text-left text-normal">
    <tr *ngFor="let rowData of tableData">
      <th class="text-[#ababab] align-text-top w-[35%] font-semibold">{{rowData.label}}:</th>
      <td class="text-[#3e3e40]">{{rowData.value}}</td>
    </tr>
    <tr>
      <th class="text-[#ababab] align-text-top w-[35%] font-semibold">Guest Arrival Notes:</th>
      <td class="text-[#3e3e40]">
        <app-text-expander [text]="data?.guest_arrival_notes ?? ''"></app-text-expander>
      </td>
    </tr>
  </table>

  <div class="w-full pt-6">
    <custom-button (click)="viewInSugarCRM(id)" extraClasses="font-semibold">
      View in SugarCRM
    </custom-button>
  </div>
</custom-card>
