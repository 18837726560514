import { Injectable, OnDestroy } from '@angular/core';
import { FrontService } from './front.service';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
/**
 * @deprecated
 */
export class CacheService implements OnDestroy {
  private _caseNumberSubscription: Subscription;
  private _conversationSubscription: Subscription;

  private _sugarCaseNumber: string | null = null;
  private _sugarCaseId: string | null = null;
  private _conversationId: string | null = null;
  private _zendeskCasesCount: number = 0;
  private _conversation: any = null;

  public sugarCaseNumber$: Subject<string | null> = new Subject<string | null>();
  public sugarCaseId$: Subject<string | null> = new Subject<string | null>();
  public zendeskCasesCount$: Subject<number> = new Subject<number>();
  public sugarContact$: Subject<any> = new Subject<any>();
  public sugarContacts$: Subject<any> = new Subject<any>();

  public set conversation(conversation: any) {
    this._conversation = conversation;
  }

  public get conversation(): any {
    return this._conversation;
  }

  public set conversationId(conversationId: string | null) {
    if (conversationId !== this._conversationId) {
      this._conversationId = conversationId;
    }
  }

  public set zendeskCasesCount(casesCount: number) {
    if (casesCount !== this._zendeskCasesCount) {
      this._zendeskCasesCount = casesCount;
      this.zendeskCasesCount$.next(casesCount);
    }
  }

  public get zendeskCasesCount(): number {
    return this._zendeskCasesCount;
  }

  public get conversationId(): string | null {
    return this._conversationId;
  }

  public set sugarCaseNumber(caseNumber: string | null) {
    if (caseNumber != this._sugarCaseNumber) {
      this._sugarCaseNumber = caseNumber;
      this.sugarCaseNumber$.next(caseNumber);
    }
  }

  public get sugarCaseNumber(): string | null {
    return this._sugarCaseNumber;
  }

  public set sugarCaseId(caseId: string | null) {
    if (caseId !== this._sugarCaseId) {
      this._sugarCaseId = caseId;
      this.sugarCaseId$.next(caseId);
    }
  }

  public get sugarCaseId(): string | null {
    return this._sugarCaseId;
  }

  constructor(
    private readonly frontService: FrontService,
    private readonly apiService: ApiService,
  ) {
    this._caseNumberSubscription = this.sugarCaseNumber$
      .subscribe(async (caseNumber: string | null) => {
        if (caseNumber !== null) {
          this.apiService.findCaseByNumber(caseNumber)
            .subscribe((response: any) => {
              const { id: caseId = null } = response;
              this.sugarCaseId = caseId;
              this.sugarCaseId$.next(caseId);
            });
        } else {
          this.sugarCaseId = null;
        }
      });

    this._conversationSubscription = this.frontService.conversation$
      .subscribe((conversation: any) => {
        this.conversation = conversation;
        this.conversationId = conversation?.id ?? null;

        const links = conversation?.links ?? [];
        const caseNumber = links.reduce((acc: Array<string>, link: any) => {
          if (link.name.startsWith('CASE-')) {
            const caseNumber = link.name.replace('CASE-', '');
            acc.push(caseNumber);
          }

          return acc;
        }, []).shift() ?? null;

        this.sugarCaseNumber = caseNumber;
      });
  }

  ngOnDestroy(): void {
    this._caseNumberSubscription.unsubscribe();
    this._conversationSubscription.unsubscribe();
  }
}
