export enum Role {
  DEVELOPER = 'DEVELOPER',
  ADMIN = 'ADMIN',
  CLIENT_RELATIONS = 'CLIENT_RELATIONS',
  FINANCE = 'FINANCE',
  GUEST_RELATIONS = 'GUEST_RELATIONS',
  HOUSEKEEPING = 'HOUSEKEEPING',
  KNOWLEDGE_BASE_ADMIN = 'KNOWLEDGE_BASE_ADMIN',
  PARTNER_RELATIONS = 'PARTNER_RELATIONS',
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  RESERVATIONS = 'RESERVATIONS',
  SALES = 'SALES',
}