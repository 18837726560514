import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ContactsService } from '../services/contacts.service';
import { ApiService } from '../services/api.service';
import { map, of } from 'rxjs';

export const salesResolver: ResolveFn<any> = (route, state) => {
  const contactsService = inject(ContactsService);
  const apiService = inject(ApiService);

  const selectedSugarContactId = contactsService.selectedSugarContactId;
  const sugarContactIds = contactsService.sugarContactIds;

  if (selectedSugarContactId) {
    return apiService.getSalesResolverData(selectedSugarContactId, sugarContactIds).pipe(
      map((response: any) => (response?.data ?? {}))
    );
  }

  return of({});
};
