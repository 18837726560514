import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from '../navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { LoaderService } from '../../services/loader.service';
import { LoaderComponent } from '../../components/loader/loader.component';
import { ToastComponent } from '../../components/toast/toast.component';
import { ToastService } from '../../services/toast.service';
import Toast from '../../common/interfaces/toast';
import { Subject, takeUntil } from 'rxjs';
import { DuplicateWarningComponent } from '../../components/duplicate-warning/duplicate-warning.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NavbarComponent,
    LoaderComponent,
    ToastComponent,
    DuplicateWarningComponent,
    ToastModule,
  ],
  providers: [
    MessageService,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public get loading(): boolean {
    return this.loaderService.loading;
  }

  public get toasts(): Array<Toast> {
    return this.toastService.toasts;
  }

  constructor (
    private loaderService: LoaderService,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
  ) {
    this.toastService.messageService = messageService;

    this.toastService.toastsChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });

    this.loaderService.loading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
