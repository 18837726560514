import { Exclude, Expose, Transform, Type } from "class-transformer";
import { FrontContactHandle } from "./front-contact-handle";

@Exclude()
export default class FrontContact {
  @Expose()
  id!: string;

  @Expose()
  name!: string;

  @Expose()
  @Type(() => FrontContactHandle)
  handles: FrontContactHandle[] = [];

  getEmails(): string[] {
    return this.handles
      .filter((handle: FrontContactHandle) => handle.isEmail())
      .map((handle: FrontContactHandle) => handle.handle);
  }
}
