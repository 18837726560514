import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-priority-pill',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './priority-pill.component.html',
  styleUrl: './priority-pill.component.css'
})
export class PriorityPillComponent {
  static HIGH = 'HIGH';
  static MEDIUM = 'MEDIUM';
  static LOW = 'LOW';

  private _priority: string = '';

  @Input()
  public set priority(value: string) {
    this._priority = value.toUpperCase();
  }

  public get priority() {
    return this._priority;
  }

  public get colorClass(): string {
    switch (this.priority) {
      case 'HIGH':
        return 'bg-[#e13800]';

      case 'MEDIUM':
        return 'bg-[#f37736]';

      case 'LOW':
        return 'bg-[#45c525]';

      default:
        return 'bg-[#e5e6eb]';
    }
  }

  constructor () { }
}
