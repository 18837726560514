import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { FrontContextType, FrontContextTypeEnum } from '../common/enums/front-context-type';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _indexPage = '/contact';
  private _noContextPage = '/no-context';
  private _authFailurePage = '/auth-failure';
  private _authFailure = false;

  public get currentPage(): string {
    return this.router.url;
  }

  public get indexPage(): string {
    return this._indexPage;
  }

  public set indexPage(value: string) {
    this._indexPage = value;
  }

  public get noContextPage(): string {
    return this._noContextPage;
  }

  public get authFailurePage(): string {
    return this._authFailurePage;
  }

  public get authFailure(): boolean {
    return this._authFailure;
  }

  public set authFailure(value: boolean) {
    this._authFailure = value
  }

  constructor(
    private ngZone: NgZone,
    private router: Router,
  ) { }

  public onNavigationStart(options: {loaderService?: LoaderService} = {}) {
    const { loaderService } = options;

    if (loaderService) {
      loaderService.loadingStart();
    }
  }

  public onNavigationEnd(options: {loaderService?: LoaderService} = {}) {
    const { loaderService } = options;
    
    if (loaderService) {
      loaderService.loadingEnd();
    }

    if (this.authFailure) {
      this.navigate(this.authFailurePage);
    }
  }

  public onContextTypeChange(contextType: FrontContextType) {
    if (contextType != FrontContextTypeEnum.SINGLE_CONVERSATION) {
      this.navigate(this.noContextPage);
    }

    if (contextType == FrontContextTypeEnum.SINGLE_CONVERSATION && this.currentPage == this.noContextPage) {
      this.navigate(this.indexPage);
    }
  }

  protected navigate(url: string) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  public navigateToIndex(force: boolean = false) {
    if (this.indexPage != this.currentPage || force) {
      this.navigate(this.indexPage);
    }
  }

  public setIndexPage(value: string) {
    this.indexPage = value;
  }
}
