import { Injectable, OnDestroy } from '@angular/core';
import Front from '@frontapp/plugin-sdk';
import { WebViewBridge, WebViewContext } from '@frontapp/plugin-sdk/dist/webViewSdkTypes';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { plainToClass } from 'class-transformer';
import ContactDto from '../core/dto/contact.dto';
import Message from '../common/models/message';
import compareObjects from '../common/helpers/compare-objects';
import toClass from '../common/helpers/to-class';
import Conversation from '../common/models/conversation';

@Injectable({
  providedIn: 'root'
})
export class FrontService implements OnDestroy {
  private _contextSubscription: any;
  private pbEmailDomains: Array<string>;
  private _contact: any = null;
  private _messages: Array<Message> = [];

  public front: WebViewBridge | null = null;
  public context: WebViewContext | null = null;

  public context$: Subject<WebViewContext | null> = new Subject<WebViewContext | null>();
  public contextChange$: Subject<WebViewContext | null> = new Subject<WebViewContext | null>();
  public contact$: Subject<any> = new Subject<any>();
  public contextType: string = 'noContext';

  private _conversation: Conversation | null = null;
  public set conversation(conversation: Conversation | null) {
    this._conversation = conversation;
  }

  public get conversation(): Conversation | null {
    return this._conversation;
  }

  /* Refactor later */
  public conversation$: Subject<any> = new Subject<any>();
  public messages$: Subject<Message[]> = new Subject<Message[]>();
  public contextType$: Subject<string> = new Subject<string>();

  /* Observables */
  private frontConversation = new Subject<any>();

  public frontConversation$ = this.frontConversation.asObservable();

  /* Misc */

  public set contact(contact: any) {
    if (this._contact !== contact) {
      this._contact = contact ?? null;
      this.contact$.next(this.contact);
    }
  }

  public get contact(): any {
    return this._contact;
  }

  public set messages(messages: Array<any>) {
    if (!compareObjects(this.messages, messages)) {
      this._messages = toClass(Message, [...messages]);
      this.messages$.next(this.messages);
    }
  }

  public get messages() {
    return this._messages;
  }

  constructor() {
    this.front = Front;
    this.pbEmailDomains = environment.pbEmailDomains;

    this._contextSubscription = this.front.contextUpdates
      .subscribe((context: WebViewContext) => {
        this.context = context;
        if (this.contextType !== context.type) {
          this.contextType = context.type;
          this.contextType$.next(context.type);
        }

        if (this.context?.id !== context?.id) {
          this.contextChange$.next(context);
        }
        this.context$.next(context);

        switch (context.type) {
          case 'singleConversation':
            this.handleSingleConversationContext(context);
            break;

          default:
            this.handleNoConversationContext(context);
        }

        return context;
      });
  }

  async handleSingleConversationContext(context: any) {
    const { conversation = null } = context;

    if (conversation) {
      const { results: messages = [] } = await context.listMessages();
      this.messages = messages;
    }

    (() => new Promise<{messages: any, contact: any}>(async (resolve) => {
      const messages = await context.listMessages()
        .then((response: any) => response?.results?? [])
        .catch(() => []);

      const contactList = messages
        .reduce((acc: Array<any>, messageData: any) => {
          if (messageData?.to) {
            const to = messageData?.to.map((contactData: any) => {
              if (contactData?.type !== 'email') {
                return null;
              }

              const contact = plainToClass(ContactDto, contactData?.contact);
              return {
                id: contactData?.contact?.id ?? null,
                name: contactData?.name ?? null,
                email: contactData?.handle ?? null,
                isBooker: contact?.isBooker ?? false
              };
            }).filter((contact: any) => contact !== null);

            to.forEach((contact: any) => {
              if (!acc.includes(contact)) {
                acc.push(contact);
              }
            });
          }

          if (messageData?.replyTo) {
            const replyToContact = plainToClass(ContactDto, messageData?.replyTo?.contact);
            const replyTo = {
              id: messageData?.replyTo?.contact?.id ?? null,
              name: messageData?.replyTo?.name ?? null,
              email: messageData?.replyTo?.handle ?? null,
              isBooker: replyToContact?.isBooker ?? false
            };

            if (replyTo.email !== null) {
              acc.push(replyTo);
            }
          }

          if (messageData?.from) {
            const fromContact = plainToClass(ContactDto, messageData?.from?.contact);
            const from = {
              id: messageData?.from?.contact?.id ?? null,
              name: messageData?.from?.name ?? null,
              email: messageData?.from?.handle ?? null,
              isBooker: fromContact?.isBooker ?? false
            };

            if (from.email !== null) {
              acc.push(from);
            }
          }

          return acc;
        }, []).filter((contact: any, index: number, array: Array<any>) => array.findIndex(contactB => (contactB.id === contact.id)) === index)
        .filter((contact: any) => !this.pbEmailDomains.includes(contact.email.split('@').pop()));

        if (contactList.length > 1) {
          const guestContact = contactList.filter((contact: ContactDto) => !contact.isBooker);

          resolve({
            messages: messages,
            contact: guestContact.length > 0 ? guestContact.shift() : contactList.shift(),
          });
        } else {
          resolve({
            messages: messages,
            contact: contactList?.shift() ?? null,
          });
        }
    }))().then((payload: any) => {
      const { messages = null, contact = null } = payload;
      this.contact = contact;
    });

    this.conversation = conversation;
    this.conversation$.next(conversation);
    this.frontConversation.next(conversation);
  }

  handleNoConversationContext(context: any) {
    this.contact = null;
  }

  ngOnDestroy(): void {
    this._contextSubscription.unsubscribe();
  }
}
