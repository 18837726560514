import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CasesService } from '../services/cases.service';
import { ContactsService } from '../services/contacts.service';
import { of } from 'rxjs';

export const casesDataResolver: ResolveFn<boolean> = (route, state) => {
  const casesService = inject(CasesService);
  const contactService = inject(ContactsService);
  
  casesService.clearCaseCountData();

  const { id: contactId, current_reservation_id: reservationId = null } = contactService.selectedSugarContact || {};
  if (!contactId) {
    return of(null);
  }

  return casesService.loadCaseCountData(contactId, reservationId);
};
