import { Component, OnDestroy } from '@angular/core';
import { FrontService } from '../../../../services/front.service';
import { CacheService } from '../../../../services/cache.service';
import { CommonModule } from '@angular/common';
import { ReservationCardComponent } from '../../../../components/reservation-card/reservation-card.component';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../../services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { ContactsService } from '../../../../services/contacts.service';

@Component({
  selector: 'app-reservations-list',
  standalone: true,
  imports: [
    CommonModule,
    ReservationCardComponent
  ],
  templateUrl: './reservations-list.component.html',
  styleUrl: './reservations-list.component.css'
})
export class ReservationsListComponent implements OnDestroy {
  private _subscription: Subscription;

  private _reservationsData: any;
  private set reservationsData(data: any) {
    this._reservationsData = data;
  }

  public get reservationsData(): any {
    return this._reservationsData;
  }

  public get contactId(): string | null {
    return this.contactsService.selectedSugarContactId;
  }

  public get futureReservationsData(): Array<any> {
    return this.reservationsData?.future ?? [];
  }

  public get currentReservationsData(): Array<any> {
    return this.reservationsData?.inhouse ?? [];
  }

  public get previousReservationsData(): Array<any> {
    return this.reservationsData?.previous ?? [];
  };

  public get loading(): boolean {
    return this.loaderService.loading;
  }

  public debug: any = null;
  constructor (
    public readonly frontService: FrontService,
    private readonly contactsService: ContactsService,
    private readonly loaderService: LoaderService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this._subscription = this.activatedRoute.data.subscribe(({data}) => {
      this.reservationsData = data;
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
