import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
  RouterOutlet,
} from '@angular/router';
import { initFlowbite } from 'flowbite';
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { CacheService } from './services/cache.service';
import { FrontService } from './services/front.service';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { ContactsService } from './services/contacts.service';
import { ToastModule } from 'primeng/toast';
import { PermissionsService } from './services/permissions.service';
import { NavigationService } from './services/navigation.service';
import { FrontContextType } from './common/enums/front-context-type';
import { WebViewContext } from '@frontapp/plugin-sdk/dist/webViewSdkTypes';
import toClass from './common/helpers/to-class';
import FrontTeammate from './common/models/front-teammate';
import Message from './common/models/message';
import { PrimeNGConfig } from 'primeng/api';
import { Role } from './common/enums/role';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  title = 'fis-plugin';

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private cacheService: CacheService,
    private frontService: FrontService,
    private authService: AuthService,
    private dataService: DataService,
    private contactsService: ContactsService,
    private permissionsService: PermissionsService,
    private navigationService: NavigationService,
    private primengConfig: PrimeNGConfig,
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: any) => this.handleRouterEvent(event));
    this.contactsService.selectedContactId$.pipe(takeUntil(this.destroy$)).subscribe((id: null|string) => this.handleSelectedContactIdChange(id));
    this.frontService.contextChange$.pipe(takeUntil(this.destroy$)).subscribe((context: null|WebViewContext) => this.handleContextChange(context));
    this.frontService.contextType$.pipe(takeUntil(this.destroy$)).subscribe((contextType: FrontContextType) => this.handleContextTypeChange(contextType));
    this.frontService.context$.pipe(takeUntil(this.destroy$)).subscribe((context) => this.handleContextEvent(context));
    this.cacheService.sugarCaseId$.pipe(takeUntil(this.destroy$)).subscribe((id: null|string) => this.handleSugarCaseIdChange(id));
    this.frontService.messages$.pipe(takeUntil(this.destroy$)).subscribe((messages: Message[]) => this.handleFrontMessageListChange(messages));
    this.permissionsService.initialised$.pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => this.handlePermissionsInitialised(value))
  }

  // OnInit & OnDestroy
  ngOnInit(): void {
    initFlowbite();
    this.dataService.loadAllData();
    this.primengConfig.ripple = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // Handlers
  protected handleRouterEvent(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      this.navigationService.onNavigationStart({loaderService: this.loaderService});
    }

    if (event instanceof NavigationEnd) {
      if (!this.authService.secret) {
        this.navigationService.authFailure = true;
      }

      this.navigationService.onNavigationEnd({loaderService: this.loaderService});
    }
  }

  protected handleContextChange(context: null|WebViewContext) {
    if (!this.dataService.dataLoaded && !this.dataService.dataLoading) {
      this.dataService.loadAllData();
    }
  }

  protected handleContextEvent(context: null|WebViewContext) {
    if (!this.permissionsService.isInitialised && context?.teammate) {
      const teammate = toClass(FrontTeammate, context.teammate);
      this.permissionsService.init(teammate);
    }
  }

  protected handleSelectedContactIdChange(id: null|string) {
    this.navigationService.navigateToIndex();
  }

  protected handleSugarCaseIdChange(id: null|string) {
    if (this.frontService.contextType == 'singleConversation') {
      this.navigationService.navigateToIndex();
    }
  }

  protected handleContextTypeChange(contextType: FrontContextType) {
    this.navigationService.onContextTypeChange(contextType);
  }

  protected handleFrontMessageListChange(messages: Message[]) {
    if (this.frontService.contextType == 'singleConversation') {
      this.navigationService.navigateToIndex();
    }
  }

  protected handlePermissionsInitialised(initialised: boolean) {
    if (initialised && this.permissionsService.roles.includes(Role.SALES)) {
      this.navigationService.setIndexPage('/sales');
    }
  }
}
