<div class="space-x-2.5 overflow-x-auto py-1.5">
  <div class="inline-flex gap-3">
    <contact-select-pill
      class="cursor-pointer"
      *ngFor="let contactPill of contactPills"
      [selected]="contactPill.id === selectedContactId"
      [type]="contactPill.type"
      (onSelect)="onSelectHandler(contactPill.id)"
      (mousedown)="dragStart($event)"
    >
      {{ contactPill.name }}
    </contact-select-pill>
  </div>
</div>
