import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { DataService } from '../../../../../services/data.service';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { Subject, takeUntil } from 'rxjs';
import EnumData from '../../../../../common/interfaces/enum-data';
import FormDropdown from '../../../../../common/interfaces/form-dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';

@Component({
  selector: 'account-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    FloatLabelModule,
    InputTextareaModule,
    AutoCompleteModule,
  ],
  templateUrl: './account-form.component.html',
  styleUrl: './account-form.component.css'
})
export class AccountFormComponent implements OnInit, AfterViewInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() data: any = {};
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Input() submitForm!: EventEmitter<void>;

  @ViewChild('nameValue') nameValue!: NgModel;
  @ViewChild('primaryEmailValue') primaryEmailValue!: NgModel;

  @Output() public invalidForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get isFormValid(): boolean {
    return (
      !!this.nameValue.valid &&
      !!this.primaryEmailValue.valid
    );
  }

  name: string = '';
  type!: FormDropdown;
  account_grade!: FormDropdown;
  sales_stage!: FormDropdown;
  primary_email: string = '';
  phone_office: string = '';
  guest_contact_category!: FormDropdown;
  account_support_team!: FormDropdown;
  account_note: string = '';
  annual_spend: string = '';
  
  public get accountTypeEnums(): Array<EnumData> {
    return this.dataService.getEnums('account_type');
  }

  get accountSizeEnums(): Array<EnumData> {
    return this.dataService.getEnums('account_grade');
  }

  public get salesStageEnums(): Array<EnumData> {
    return this.dataService.getEnums('sales_stage');
  }

  public get guestContactCategoryEnums(): Array<EnumData> {
    return this.dataService.getEnums('guest_contact_category');
  }

  public get supportTeamEnums(): Array<EnumData> {
    return this.dataService.getEnums('account_support_team');
  }

  public get formData(): any {
    return {
      id: this.data?.id || null,
      name: this.name,
      primary_email: this.primary_email,
      phone_office: this.phone_office,
      account_note: this.account_note || '',
      annual_spend: this.annual_spend || '',
      account_support_team: this.account_support_team || '',
    };
  }

  constructor (
    private dataService: DataService,
  ) {}

  ngOnInit(): void {
    const {
      name = '',
      primary_email = '',
      phone_office = '',
      account_note = '',
      annual_spend = '',
      account_support_team = '',
    } = this.data;

    this.name = name;
    this.primary_email = primary_email;
    this.phone_office = phone_office;
    this.account_note = account_note;
    this.annual_spend = annual_spend;
    this.account_support_team = account_support_team;

    this.submitForm
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.submit.emit(this.formData);
      });
  }

  ngAfterViewInit(): void {
    [
      this.nameValue,
      this.primaryEmailValue,
    ].forEach((control: NgModel) => {
      control.statusChanges?.pipe(takeUntil(this.destroy$)).subscribe(() => this.validateForm());
    });
  }

  validateForm() {
    this.invalidForm.emit(!this.isFormValid);
  }

  protected formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }
}
