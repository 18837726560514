<custom-card>
  <div class="py-3 text-[#3e3e40] font-semibold">
    {{data.name}}
  </div>

  <table class="w-full text-left text-normal">
    <tr>
      <th class="text-[#ababab] align-text-top w-[30%] py-1 font-semibold">Priority:</th>
      <td class="text-[#3e3e40] py-1">
        <app-priority-pill [priority]="data.priority"></app-priority-pill>
      </td>
    </tr>
    <tr *ngFor="let rowData of tableData">
      <th class="text-[#ababab] align-text-top w-[30%] py-1 font-semibold">{{rowData.label}}:</th>
      <td class="text-[#3e3e40] py-1">{{rowData.value}}</td>
    </tr>
    <tr>
      <th class="text-[#ababab] align-text-top w-[30%] py-1 font-semibold">Description:</th>
      <td class="text-[#3e3e40] py-1">
        <app-text-expander [text]="data?.description"></app-text-expander>
      </td>
    </tr>
  </table>

  <div class="w-full pt-6">
    <custom-button (click)="viewInSugarCRM(data.id)" extraClasses="font-semibold">
      View in SugarCRM
    </custom-button>
  </div>
</custom-card>
