import { ClassConstructor, ClassTransformOptions, plainToClass } from "class-transformer";

export default function toClass<T, V>(cls: ClassConstructor<T>, plain: V[], options?: ClassTransformOptions): T[];
export default function toClass<T, V>(cls: ClassConstructor<T>, plain: V, options?: ClassTransformOptions): T;
export default function toClass<T, V>(cls: ClassConstructor<T>, plain: V | V[], options?: ClassTransformOptions): T | T[] {
  const defaultOptions: ClassTransformOptions = {
    exposeDefaultValues: true,
    exposeUnsetFields: false,
  };

  if (typeof plain !== "object" && !Array.isArray(plain)) {
    throw new Error("Input must be an object or an array");
  }

  const transformedObject = plainToClass(cls, Array.isArray(plain) ? [...plain] : plain, {...defaultOptions, ...options});

  if (Array.isArray(plain)) {
    return transformedObject as T[];
  } else {
    return transformedObject as T;
  }
}
