<div class="flex">
  <span
    (mousedown)="onClickHandler()"
    [ngClass]="{
        'hover:cursor-pointer': !selected,
        'bg-[#F0F0F0] text-[#ABABAB]': !selected,
        'bg-white text-[#3E3E40]': selected
      }"
    class="border-color-[#E2E5E8] border-[1px] rounded-full py-2 px-2.5 font-semibold text-sm align-text-bottom select-none text-nowrap">
    <ng-content></ng-content>
    <span
      *ngIf="type"
      [ngClass]="{
          'bg-[#ABABAB]': (type !== PARTIAL_MATCH && type !== NOT_MATCHED && !selected),
          'bg-[#3E3E40]': (type !== PARTIAL_MATCH && type !== NOT_MATCHED && selected),
          'bg-[#ffc97d]': (type === PARTIAL_MATCH && !selected),
          'bg-[#ffa930]': (type === PARTIAL_MATCH && selected),
          'bg-[#ff6767]': (type === NOT_MATCHED && !selected),
          'bg-[#ff4343]': (type === NOT_MATCHED && selected)
        }"
      class="rounded-full ms-2 py-0.5 px-1.5 text-white font-semibold text-[0.6rem] uppercase">{{ type }}</span>
  </span>
</div>
